/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { Global, css } from "@emotion/react";

import { Viewport } from "./components/layout";
import ThemeContextProvider from "./theme/ThemeContextProvider";
import DeprecatedChatSessionManager from "./components/providers/DeprecatedChatSessionManager";
import ChatSessionManager from "./components/providers/ChatSessionManager";
import { NotificationOverlay } from "./components/ui";

import { ChatContainer, DeprecatedChatContainer, Home } from "./pages";
import AnalyticsProvider from "./components/providers/AnalyticsProvider";
import ErrorBoundary from "./components/ErrorBoundary";

type AppProps = any;

const globalCss = css`
  body {
    overflow: hidden;
  }
`;
class App extends React.Component<AppProps, any> {
  render() {
    const { locationName, organizationName, secondaryColour } = this.props;
    const metaTitle = `${locationName || organizationName || "Mikata Health"}`;
    const metaDescription = "Your clinic uses Mikata Health to enhance your care.";
    const metaImage = "https://mikatahealth.com/favicon-32x32.png";

    return (
      <ThemeContextProvider>
        <Global styles={globalCss} />
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="theme-color" content={secondaryColour || "#000000"} />
          <meta name="description" content={metaDescription} />
          <meta property="og:locale" content="en_CA" />
          <meta property="og:site_name" content={metaTitle} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={metaDescription} />
          <meta property="og:image" content={metaImage} />
          <meta property="og:image:secure_url" content={metaImage} />
        </Helmet>
        <Viewport>
          <Router>
            <Switch>
              <Route
                path="/chat/:token/messages"
                render={(props) => (
                  <DeprecatedChatSessionManager>
                    <AnalyticsProvider>
                      <ErrorBoundary>
                        <DeprecatedChatContainer {...props} />
                      </ErrorBoundary>
                    </AnalyticsProvider>
                  </DeprecatedChatSessionManager>
                )}
              />
              <Route
                path="/:shortCode"
                render={(props) => (
                  <>
                    <NotificationOverlay />
                    <ChatSessionManager>
                      <AnalyticsProvider>
                        <ErrorBoundary>
                          <ChatContainer {...props} />
                        </ErrorBoundary>
                      </AnalyticsProvider>
                    </ChatSessionManager>
                  </>
                )}
              />
              <Route path="/" render={() => <Home />} />
            </Switch>
          </Router>
        </Viewport>
      </ThemeContextProvider>
    );
  }
}

const mapStateToProps = ({ chatInfo }: ReduxState) => {
  return {
    locationName: chatInfo.locations.length > 0 ? chatInfo.locations[0].locationName : "",
    organizationName: chatInfo.organizationName || "",
    logo: chatInfo.logo,
    secondaryColour: chatInfo.secondaryColour
  };
};

const connected = connect(mapStateToProps, null)(App);

export default withRouter(connected);
