/* Summary Component
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";
import { Text } from "../../ui";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

/* Style
--------------------------------------- */

const style = (theme: any) => css`
  padding: 20px 0;
  border-bottom: 2px solid ${theme.colors.grey[2]};

  .summary-top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 3px;

    button {
      color: ${theme.colors.primary.blue};
    }
  }
`;

/* Props
--------------------------------------- */

interface Props {
  label?: string;
  editClick?: (event: React.MouseEvent) => void;
  editable?: boolean;
}

/* Component
--------------------------------------- */

const Summary: React.FC<Props & StyledProps> = (props) => {
  const theme = useTheme();

  return (
    <StyledComponent css={style(theme)} {...props}>
      <div className="summary-top">
        <Text variant="meta" className="summary-label">
          {props.label}
        </Text>
        {props.editable && (
          <Text variant="xsmall" className="summary-meta">
            {props.editClick && (
              <button type="button" onClick={props.editClick}>
                Edit
              </button>
            )}
          </Text>
        )}
      </div>

      <Text className="summary-text">{props.children}</Text>
    </StyledComponent>
  );
};

Summary.displayName = "Summary";

export default Summary;
