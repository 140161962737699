/* Important
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  compose,
  space,
  layout,
  color,
  border,
  SpaceProps,
  LayoutProps,
  BackgroundColorProps,
  BorderRadiusProps
} from "styled-system";
import { Icon, Text } from "../../ui";
import { Box } from "../../layout";

type StyledProps = SpaceProps & LayoutProps & BackgroundColorProps & BorderRadiusProps;

const StyledComponent = styled("article")<StyledProps>(compose(space, layout, color, border));

interface Props {
  title?: string;
}

const Important: React.FC<Props & StyledProps> = ({
  bg = "error.background",
  borderRadius = "normal",
  ...otherProps
}) => {
  const props = {
    bg,
    borderRadius,
    ...otherProps
  };
  const style = css`
    display: flex;
    padding: 13px 20px 13px 14px;
    overflow: hidden;
    word-break: break-word;
    margin-bottom: 10px;

    :last-of-type {
      margin-bottom: 32px;
    }
  `;

  return (
    <StyledComponent css={style} {...props}>
      <Icon name="important" mr="3px" mt="-1px" error />
      <Box pt="2px">
        {props.title && (
          <Text variant="small" mb="3px" bold uppercase error>
            {props.title}
          </Text>
        )}
        <Text variant="small" error>
          {props.children}
        </Text>
      </Box>
    </StyledComponent>
  );
};

Important.displayName = "Important";

export default Important;
