/** @jsxImportSource @emotion/react */
import React from "react";
import cx from "classnames";

import { connect } from "react-redux";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import styles from "./index.module.scss";

import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

import { Box } from "../../../components/layout";
import { Card, Text, Button, Icon } from "../../../components/ui";
import { CircleCheck, Circle, Star } from "../../../components/ui/Icons";
import JsonContentRenderEngine from "../../../components/JsonContentRenderEngine";

import {
  startConversation as startConversationAction,
  fetchNextMessage as fetchNextMessageAction
} from "../../../actions";

import getChatType from "../../../utils/getChatType";
import { ChatFlowTags, StartCardVariants } from "../../../constants";

import { getDefaultStartMessage } from "./defaultStartMessages";
import ProgressBar from "../../../components/ui/ProgressBar";
import {
  getSortedConversations,
  isConversationComplete,
  isConversationStartable
} from "../../../utils/conversationHelper";

const { APPOINTMENT_DETAILS, SECURE_DIRECT_MESSAGE } = ChatFlowTags;

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

const style = css`
  .Task {
    &:not(:last-of-type) {
      border-bottom: 1px solid #f2f2f3;
    }
    padding: 16px 0 16px 0;
  }

  .TaskBlock {
    padding: 0;
    width: 100%;
    &Start {
      cursor: pointer;
    }
    &:focus {
      outline-style: none;
    }
  }

  .TaskRow {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 24px 1fr 24px;
  }

  .CircleIcon {
    display: flex;
    align-items: center;
    &Complete {
      color: #208454;
    }
  }

  .TaskHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
  }

  .PriorityTaskHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
  }

  .TaskSummaryGroup {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
  }

  .TaskSummaryTitle {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .PriorityTaskStar {
    display: inline-flex;
    transform: translate(0, 4px);
  }

  .TaskChevron {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .TaskCount {
    min-width: 24px;
    height: 24px;
    margin-left: 8px;
    margin-right: auto;
    padding-left: 6px;
    padding-right: 6px;
    text-align: center;
    font-size: 14px;
    border-radius: 12px;
    border: none;
    background-color: #1d2029;
    color: #ffffff;
  }
`;

interface Props {
  chatInfo?: ChatInfo;
  startConversation: (conversationId: number, messageTemplateId: number) => void;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
}
const getStartChatLabel = (isConversationComplete: boolean, chatType?: string) => {
  if (isConversationComplete) {
    return "Completed";
  }

  return chatType === SECURE_DIRECT_MESSAGE ? "View Message" : "Get started";
};

const InfoTasksCard: React.FC<Props & StyledProps> = ({
  chatInfo,
  startConversation,
  fetchNextMessage
}: Props) => {
  const { organizationName, conversations } = chatInfo as ChatInfo;
  let { cardConversations, taskConversations } = getSortedConversations(conversations || []);

  const numberOfTasks = taskConversations.length;
  const incompleteTasks = taskConversations?.filter(
    (conversation) => !isConversationComplete(conversation)
  ).length;
  const numberOfCompleteTasks = numberOfTasks - incompleteTasks;

  const generateCompletionLabel = () => {
    if (incompleteTasks === 0) {
      return "Completed!";
    }
    if (incompleteTasks === 1) {
      return `${incompleteTasks} step to go`;
    }
    return `${incompleteTasks} steps to go`;
  };

  // Deprecated system chats
  const deprecatedSystem = !!chatInfo?.conversationId;
  if (deprecatedSystem) {
    const variant =
      chatInfo.landingMessage &&
      chatInfo.landingMessage.payloadOptions &&
      (chatInfo.landingMessage?.payloadOptions as BasePayloadOptions).variant
        ? (chatInfo.landingMessage?.payloadOptions as BasePayloadOptions).variant
        : StartCardVariants.CARD;

    switch (variant) {
      case StartCardVariants.TASK:
        taskConversations = [
          {
            conversationId: chatInfo.conversationId,
            chatFlowTags: chatInfo.chatFlowTags,
            chatFlowTitle: chatInfo.chatFlowTitle,
            startMessage: chatInfo.landingMessage ?? null,
            isConversationComplete: chatInfo.isConversationComplete
          }
        ];
        break;
      default:
        cardConversations = [
          {
            conversationId: chatInfo.conversationId,
            chatFlowTags: chatInfo.chatFlowTags,
            chatFlowTitle: chatInfo.chatFlowTitle,
            startMessage: chatInfo.landingMessage ?? null,
            isConversationComplete: chatInfo.isConversationComplete
          }
        ];
    }
  }

  return (
    <StyledComponent css={style}>
      {taskConversations && taskConversations.length > 0 && (
        <Card p="16px 16px 4px" mb="24px" boxShadow>
          <div className="TaskHeader">
            <Text variant="titleSmall" bold>
              Your Next Steps
            </Text>
            <Text variant="small" bold>
              {generateCompletionLabel()}
            </Text>
          </div>
          <ProgressBar
            numberOfTasks={numberOfTasks}
            numberOfCompleteTasks={numberOfCompleteTasks}
          />
          {taskConversations.map((conversation, idx) => {
            const { startMessage } = conversation;
            const { variant } = startMessage?.payloadOptions as BasePayloadOptions;
            const taskContent =
              startMessage && (startMessage.payloadOptions as BasePayloadOptions).taskContent
                ? (startMessage.payloadOptions as BasePayloadOptions).taskContent
                : null;

            return (
              <Box
                key={`conversation-${conversation.conversationId}`}
                width="100%"
                className="Task"
              >
                <button
                  type="button"
                  className={cx("TaskBlock", {
                    TaskBlockStart: !isConversationComplete
                  })}
                  id={`taskBlock-${idx}`}
                  disabled={!isConversationStartable(conversation)}
                  onClick={() => {
                    if (
                      isConversationStartable(conversation) &&
                      conversation.conversationId &&
                      conversation.startMessage?.id
                    ) {
                      startConversation(conversation.conversationId, conversation.startMessage?.id);
                    }
                  }}
                >
                  <div className="TaskRow">
                    <div
                      className={cx("CircleIcon", {
                        CircleIconComplete: isConversationComplete(conversation)
                      })}
                    >
                      {isConversationComplete(conversation) ? (
                        <CircleCheck size={24} />
                      ) : (
                        <Circle size={24} />
                      )}
                    </div>
                    <div className="TaskSummaryGroup">
                      <div className="TaskSummaryTitle">
                        <Text variant="medium" bold textAlign="start">
                          {taskContent?.title}
                          {(variant === StartCardVariants.PRIORITY_TASK ||
                            conversation.chatFlowTags.includes(
                              ChatFlowTags.SECURE_DIRECT_MESSAGE
                            )) &&
                            !isConversationComplete(conversation) && (
                              <div className="PriorityTaskStar">
                                <Star />
                              </div>
                            )}
                        </Text>
                      </div>

                      <Text variant="medium" textAlign="start">
                        {taskContent?.description}
                      </Text>
                    </div>
                    <div className="TaskChevron">
                      {isConversationStartable(conversation) && <Icon name="chevronRight" />}
                    </div>
                  </div>
                </button>
              </Box>
            );
          })}
        </Card>
      )}

      {cardConversations && cardConversations.length > 0 && (
        <Card p="16px 16px 4px" mb="24px" boxShadow>
          {cardConversations?.map((conversation) => {
            const chatType = getChatType(conversation.chatFlowTags) || undefined;

            const startMessage =
              conversation.startMessage ||
              (chatType && (getDefaultStartMessage(organizationName, chatType) as Message));

            const contentJSON =
              startMessage &&
              startMessage.payloadOptions &&
              (startMessage.payloadOptions as BasePayloadOptions).contentJson
                ? (startMessage.payloadOptions as BasePayloadOptions).contentJson
                : null;

            let header;
            let paragraphs: string[] = [];

            if (!contentJSON) {
              const content =
                startMessage && typeof startMessage.payloadContent === "string"
                  ? startMessage.payloadContent
                  : "";

              if (content) {
                try {
                  const parsedContent = JSON.parse(content);
                  header = parsedContent.header || header;
                  paragraphs = parsedContent.paragraphs || paragraphs;
                } catch (error) {
                  console.error("Failed to parse landing card content", error);
                }
              }
            }

            const showDeprecatedContent = Boolean(header || (paragraphs && paragraphs.length));
            const showContentJSON = Boolean(contentJSON);
            const showCallToAction = !conversation.chatFlowTags.includes(APPOINTMENT_DETAILS);

            if (!showDeprecatedContent && !showContentJSON && !showCallToAction) return null;

            return (
              <div key={`conversation-${conversation.conversationId}`}>
                {showDeprecatedContent && (
                  <>
                    {/* DEPRECATED Heading */}
                    {header && (
                      <Text variant="titleSmall" bold pb="16px">
                        {header}
                      </Text>
                    )}
                    {/* DEPRECATED Paragraphs */}
                    {paragraphs && paragraphs.length > 0 && (
                      <Box width="100%" pb="16px">
                        <Text variant="small">
                          {paragraphs.map((paragraph, i) => {
                            // eslint-disable-next-line react/no-array-index-key
                            return <p key={i}>{paragraph}</p>;
                          })}
                        </Text>
                      </Box>
                    )}
                  </>
                )}
                {showContentJSON && (
                  <JsonContentRenderEngine
                    content={contentJSON}
                    contentBoxShadow={false}
                    contentPadding="0px"
                    contentMargin="0px"
                  />
                )}
                {showCallToAction && (
                  <Box width="100%" p="16px 0px">
                    <Button
                      id="landingPageBtn"
                      variant={!isConversationStartable(conversation) ? "success" : undefined}
                      disabled={!isConversationStartable(conversation)}
                      onClick={() => {
                        if (
                          !deprecatedSystem &&
                          conversation.conversationId &&
                          conversation.startMessage?.id
                        ) {
                          startConversation(
                            conversation.conversationId,
                            conversation.startMessage?.id
                          );
                        }
                        if (deprecatedSystem) {
                          fetchNextMessage({
                            messageTemplateId: conversation.startMessage?.id,
                            userResponse: null,
                            sessionId: chatInfo?.sessionId,
                            lastReceivedMessageId: null, // TODO: can we remove this from required FetchNextMessageParams?
                            chatFlowId: null // TODO: can we remove this from required FetchNextMessageParams?
                          });
                        }
                      }}
                    >
                      {getStartChatLabel(!isConversationStartable(conversation), chatType)}
                    </Button>
                  </Box>
                )}
              </div>
            );
          })}
        </Card>
      )}
    </StyledComponent>
  );
};

export default connect(null, {
  startConversation: startConversationAction,
  fetchNextMessage: fetchNextMessageAction
})(InfoTasksCard);
