/* Image
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

/* Style
--------------------------------------- */

const style = (theme: any) => css`
  img {
    display: block;
    width: 100%;
    border-radius: 3px;
  }
`;

/* Props
--------------------------------------- */

interface Props {
  src: string;
}

/* Component
--------------------------------------- */

const Image: React.FC<Props & StyledProps> = (props) => {
  const theme = useTheme();

  return (
    <StyledComponent css={style(theme)} className="image-ui" {...props}>
      <img src={props.src} alt="" />
    </StyledComponent>
  );
};

Image.displayName = "Image";

export default Image;
