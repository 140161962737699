/* Input Field
======================================= */

/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import { jsx, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";
import TextareaAutosize from "react-textarea-autosize";

import { Icon, Text } from "../../../ui";
import { AllThemeIconTypes } from "../../../../utils/getThemeIcons";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

/* Style
--------------------------------------- */

const style = (theme: any, isOnCard: boolean) => css`
  .field {
    background: ${isOnCard ? "#F2F2F3" : "#fff"};
    box-shadow: ${isOnCard ? "none" : theme.shadows.small};
    border: 2px solid ${isOnCard ? "#F2F2F3" : "#fff"};
    transition: all 200ms ease;
    cursor: text;
    min-height: 56px;

    border-radius: ${theme.radii.normal};
    padding: 6px;
    position: relative;
    z-index: 2;
  }

  label {
    display: inline-block;
  }

  textarea,
  input {
    width: 100%;
    font-family: ${theme.fonts.heading};
    color: ${theme.colors.grey[4]};
    background: ${isOnCard ? "#F2F2F3" : "#fff"};

    display: block;
    box-sizing: border-box;

    padding: 10px 12px;
    line-height: 20px;

    -webkit-appearance: none;

    ::-webkit-input-placeholder {
      color: ${theme.colors.grey[4]};
    }

    &:focus {
      outline: none;
    }
  }

  .errorMessage {
    font-size: 16px;
    line-height: 20px;
    min-height: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #a31d18;
  }

  textarea {
    resize: none;

    -webkit-overflow-scrolling: touch;

    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-button {
      width: 3px;
      height: 3px;
    }
    ::-webkit-scrollbar-thumb {
      background: #1a4b74;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }
  }

  &.isFocused {
    .field {
      border: 2px solid ${theme.colors.primary.blue};
      box-shadow: none;
    }
  }

  &.hasIcon {
    .field {
      display: flex;
      align-items: center;
      padding-left: 10px;
    }

    input,
    textarea {
      padding-left: 8px;
    }
  }
`;

/* Props
--------------------------------------- */

interface Props {
  id?: string;
  name?: string;
  type?: string;
  placeholder?: string;
  icon?: AllThemeIconTypes | null;
  minRows?: number;
  maxRows?: number;
  value?: string;
  label?: string;
  pattern?: string;
  inputMode?: "numeric";
  maxLength?: number;
  inputRef?: any; // React.RefObject<HTMLInputElement | HTMLTextAreaElement>
  autoFocus?: boolean;
  isOnCard?: boolean;
  updateResponse?: (userResponse: string) => void;
  validate?: (value: string) => string | undefined;
}

/* Component
--------------------------------------- */

const Input: React.FC<Props & StyledProps> = ({
  id,
  value,
  pattern,
  inputMode,
  isOnCard = false,
  validate,
  placeholder = "",
  name = "",
  type = "text",
  minRows = 1,
  maxRows = 5,
  ...otherProps
}: Props) => {
  const theme = useTheme();
  const props = {
    placeholder,
    name,
    type,
    minRows,
    maxRows,
    ...otherProps
  };
  const [isFocused, setFocus] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const ref = props.inputRef ? props.inputRef : React.createRef();

  useEffect(() => {
    if (props.autoFocus) {
      if (ref && ref.current) {
        ref.current.focus();
      }
    }
  }, [props.autoFocus, ref]);

  const triggerFocus = (event: React.SyntheticEvent) => {
    if (ref && ref.current) {
      ref.current.focus();
    }
  };

  const onFocus = () => setFocus(true);
  const onBlur = () => setFocus(false);

  const htmlIcon = props.icon ? <Icon name={props.icon} /> : null;

  const htmlLabel = props.label ? (
    <Text variant="meta" as="label" mb="6px" pl="6px">
      {props.label}
    </Text>
  ) : null;

  const classNames = [isFocused ? "isFocused" : "", props.icon ? "hasIcon" : ""].join(" ");

  // decide which type of field to render

  let htmlField;

  if (props.maxRows && props.maxRows === 1)
    htmlField = (
      <input
        id={id}
        ref={ref}
        name={props.name}
        placeholder={props.placeholder}
        value={value}
        type={props.type}
        pattern={pattern}
        inputMode={inputMode}
        maxLength={props.maxLength}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={({ target }) => {
          if (validate) {
            const error = validate(target.value);
            setErrorMessage(error || null);
          }
          return props.updateResponse ? props.updateResponse(target.value) : () => null;
        }}
      />
    );
  else if (props.maxRows && props.maxRows > 1)
    htmlField = (
      <TextareaAutosize
        id={id}
        inputRef={ref}
        name={props.name}
        placeholder={props.placeholder}
        minRows={props.minRows}
        maxRows={props.maxRows}
        value={value}
        maxLength={props.maxLength}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={({ target }) => {
          if (validate) {
            const error = validate(target.value);
            setErrorMessage(error || null);
          }
          return props.updateResponse ? props.updateResponse(target.value) : () => null;
        }}
      />
    );

  return (
    <StyledComponent
      css={style(theme, isOnCard)}
      {...props}
      className={classNames}
      onClick={triggerFocus}
    >
      {htmlLabel}
      <div className="field">
        {htmlIcon}
        {htmlField}
      </div>
      {errorMessage && <div className="errorMessage">{errorMessage}</div>}
    </StyledComponent>
  );
};

Input.displayName = "Input";

export default Input;
