import React, { Component, ErrorInfo } from "react";

import { saveAppMetrics, AppMetricType } from "../hooks/useAppAnalytics";
import Text from "../components/ui/Text/Text";
import { Drawer, Card, Avatar, Icon } from "../components/ui";
import { Grid, Scroll } from "../components/layout";
import ExceptionGraphic from "./ui/NotificationOverlay/ExceptionGraphic";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PropsType = any;
type StateType = { hasError: boolean; error?: Error };

class ErrorBoundary extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service like AppSignal
    // logErrorToMyService(error, errorInfo);
    saveAppMetrics([
      {
        type: AppMetricType.APPLICATION_ERROR,
        errorMessage: error.message || "Unknown Application Error",
        errorInfo: errorInfo ? JSON.stringify(errorInfo) : "",
        userAgent: window?.navigator?.userAgent || "",
        timestamp: new Date().toISOString()
      }
    ]);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Drawer backgroundColor="#F2F2F3">
          <Scroll>
            <Grid p="40px">
              <Card p="24px 0px 24px 0px">
                <Grid p="24px 34px 24px 34px" flexDirection="column">
                  <Avatar size={40}>
                    <Icon name="logo" iconColor="reset" size="18px" />
                  </Avatar>
                  <Text variant="titleSmall" bold pt="12px" pb="12px">
                    Oh dear! Something went wrong.
                  </Text>
                  <Text variant="small" pb="12px">
                    Our team is working to fix the issue. Apologies for the inconvenience{" "}
                  </Text>
                  <ExceptionGraphic />
                </Grid>
              </Card>
            </Grid>
          </Scroll>
        </Drawer>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
