/* Root index
======================================= */

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Route } from "react-router-dom";

import smoothScroll from "smoothscroll-polyfill";

import ReduxProvider from "./ReduxProvider";
import App from "./App";

import "./assets/css/reset.css";
import "./assets/css/fonts.css";

// kick off the polyfill!
smoothScroll.polyfill();

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <ReduxProvider>
    <Router>
      <Route path="/" component={App} />
    </Router>
  </ReduxProvider>
);
