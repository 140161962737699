/* eslint-disable no-shadow */
export enum ChatFlowResponseType {
  "BOOKING" = "booking",
  "PATIENT_VERIFICATION_ATTEMPT" = "patientVerification",
  "PATIENT_VERIFICATION_RESULT" = "patientVerificationResult"
}

// Booking Card Types
export enum BookingMode {
  REQUEST = "request",
  AVAILABLE_TIMES = "availableTimes",
  AUTO_BOOK = "autoBook"
}

export enum ChatFlowControlFlags {
  SKIP = "Skip",
  SCRIBE_VISIT_COMPLETED = "scribeVisitCompleted",
  SCRIBE_VISIT_CONSENT_DENIED = "scribeVisitConsentDenied"
}

export enum AutomationSettingAction {
  CHECK_IN = "checkin",
  CONFIRM = "confirm",
  RESCHEDULE = "reschedule"
}

export enum CheckedInStatus {
  NOTICE_SENT = "notice_sent",
  NONE = "none",
  NO_CONTACT_INFO = "no_contact_info",
  CHECKED_IN = "checked_in",
  STAFF_CONTACTED = "staff_contacted",
  UNCLEAR = "unclear"
}

export enum TextInputFormat {
  TEXT = "text",
  POSTAL_CODE = "postalCode"
}

export enum NumberInputFormat {
  NUMBER = "number",
  PERSONAL_HEALTH_NUMBER = "personalHealthNumber"
}
