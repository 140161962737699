/* Checkbox
======================================= */
import React from "react";
import cx from "classnames";

import { Text, Icon } from "..";

import styles from "./index.module.scss";

interface Props {
  isSelected?: boolean;
  label?: string;
  value?: string;
  onClick?: (value?: string) => void;
}

const Checkbox = ({ isSelected, label, value, onClick }: Props) => {
  return (
    <button
      type="button"
      className={cx(styles.Checkbox, { [styles.CheckboxSelected]: isSelected })}
      onClick={() => {
        if (onClick) {
          onClick(value);
        }
      }}
    >
      <div className={cx(styles.Icon, { [styles.IconSelected]: isSelected })}>
        <Icon name={isSelected ? "checked" : "circle"} size="16px" iconColor="currentColor" />
      </div>
      <div className={cx(styles.Label, { [styles.LabelSelected]: isSelected })}>
        <Text variant="small" textColor="inherit">
          {label}
        </Text>
      </div>
    </button>
  );
};

export default Checkbox;
