/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */

/* Image Select Card
Does not support editMode for SummaryCard at the moment
======================================= */

/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";
import TextCard from "./TextCard";
import { Button, Icon, Text, Card, SelectButton, Modal, SkipButton } from "..";
import moment from "moment";
import { ChatCardTypes } from "../../../constants";
import ImageSelect from "../ImageSelect/ImageSelect";
import { Flex } from "../../layout";

import images from "../../../assets/img";

const getImage = (logoToCheck: string) => {
  return images[logoToCheck] ? images[logoToCheck] : "";
};

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

/* Props
--------------------------------------- */

interface ImageSelectProps {
  msg: Message;
  index: number;
  isCurrentMessage: boolean;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
}

/* Component
--------------------------------------- */
const ImageSelectCard: React.FC<ImageSelectProps> = (props: ImageSelectProps) => {
  const [modalShowing, setModalShowing] = useState(false);
  const [selections, setSelections] = useState<ImageSelection[]>([]);
  const [modalSelections, setModalSelections] = useState<ImageSelection[]>([]);
  const [selectionsText, setSelectionsText] = useState("Select areas");

  const { msg, index, isCurrentMessage } = props;
  // TODO: Make custom card PayloadOptions type
  const payloadOptions = msg?.payloadOptions as BasePayloadOptions;
  const skippable = payloadOptions?.skippable || false;

  const zones = payloadOptions?.zones || [];
  const debug = payloadOptions?.debug || false;
  const imageFileName = payloadOptions?.imageFileName || "";
  let imageURL = payloadOptions?.imageURL || "";

  const localImage = getImage(imageFileName);

  if (localImage) {
    imageURL = localImage;
  }

  const submit = () => {
    // Note: chatflowsFind does not currently handle a an imageSelect interface.
    // instead we are sending a simulated multi-select userResponse
    const selectedImageOptions = selections.map((selection) => selection.zoneLabel);

    props.fetchNextMessage({
      lastReceivedMessageId: null,
      userResponse: selectedImageOptions, // { imageFileName, imageURL, imageSelections: selections },
      userResponseType: ChatCardTypes.IMAGE_SELECT,
      chatFlowId: null,
      showUserResponse: true,
      createdAt: moment().toDate()
    });
  };

  const handleConfirm = () => {
    setSelections(modalSelections);
    setModalShowing(false);
  };

  const handleReset = () => {
    setModalSelections([]);
  };

  const handleTouch = ({ x, y, zone }: ImageTouch) => {
    setModalSelections([...modalSelections, { x, y, zoneId: zone.id, zoneLabel: zone.label }]);
  };

  const openModal = () => {
    setModalSelections(selections);
    setModalShowing(true);
  };

  useEffect(() => {
    if (selections.length) {
      const textArray = [
        ...selections.map((selection) => `${selection.zoneId}. ${selection.zoneLabel}`)
      ];
      setSelectionsText(textArray.join(", "));
    } else {
      setSelectionsText("Select areas");
    }
  }, [selections]);

  return (
    <StyledComponent>
      <TextCard
        msg={msg}
        index={index}
        avatar={<Icon name="logo" iconColor="reset" size="18px" />}
        mb="16px"
        editMode={false}
      />
      {isCurrentMessage && !debug && (
        <div>
          <Card mb="16px">
            <ImageSelect background={imageURL} zones={zones} selections={selections} />
          </Card>

          <SelectButton text={selectionsText} onClick={() => openModal()} mb="16px" />

          <Modal showing={modalShowing} setShowing={setModalShowing}>
            <ImageSelect
              background={imageURL}
              zones={zones}
              selections={modalSelections}
              handleTouch={handleTouch}
              mb="18px"
            />

            <Text variant="xsmall" mt="20px" mb="10px" textAlign="center">
              Make your selection by tapping the diagram above
            </Text>

            <Flex pb="10px">
              <Button variant="textDanger" onClick={handleReset}>
                Reset
              </Button>
              <Button variant="text" onClick={handleConfirm}>
                Confirm
              </Button>
            </Flex>
          </Modal>
        </div>
      )}
      {isCurrentMessage && debug && (
        <div>
          <Card mb="16px">
            <ImageSelect
              debug
              background={imageURL}
              zones={zones}
              selections={modalSelections}
              handleTouch={handleTouch}
            />
          </Card>
          <Flex pb="10px">
            <Button variant="textDanger" onClick={handleReset}>
              Reset
            </Button>
            <Button variant="text" onClick={() => setSelections(modalSelections)}>
              Confirm
            </Button>
          </Flex>
          <pre>{JSON.stringify(modalSelections, null, 2)}</pre>
          <br />
        </div>
      )}
      {isCurrentMessage && (
        <Button onClick={submit} disabled={selections.length === 0} disableOnClick>
          Send
        </Button>
      )}
      {isCurrentMessage && skippable && <SkipButton index={index} message={msg} />}
    </StyledComponent>
  );
};

ImageSelectCard.displayName = "ImageSelectCard";

export default ImageSelectCard;
