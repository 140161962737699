import React from "react";
import theme from "../../../theme/default";

type PropsType = {
  size?: number;
};

const CircleCheck = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M20 12.006v-.93a1 1 0 112 0v.93a11 11 0 11-6.523-10.053 1 1 0 01-.814 1.827A9 9 0 1020 12.006zM8.707 10.3L11 12.592 21.293 2.3a1 1 0 111.414 1.414l-11 11a1 1 0 01-1.414 0l-3-3A1 1 0 118.707 10.3z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CircleCheck;
