/* Icon
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css, withTheme, useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import {
  compose,
  style,
  ResponsiveValue,
  space,
  layout,
  SpaceProps,
  LayoutProps
} from "styled-system";
import CSS from "csstype";

import { getThemeIcons, AllThemeIconTypes, get } from "../../../utils/getThemeIcons";

const iconColor = style({
  prop: "iconColor",
  key: "colors"
});

type IconColorProps = {
  iconColor?: ResponsiveValue<CSS.Property.Color>;
};

type StyledProps = SpaceProps & LayoutProps & IconColorProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout, iconColor));

interface Props {
  name: AllThemeIconTypes;
  size?: string;
  as?: any;
  error?: boolean;
}

const IconBase: React.FC<Props & StyledProps> = ({ size = "24px", ...otherProps }) => {
  const theme: any = useTheme();
  const icons = getThemeIcons(theme.themeName);
  const props = { size, ...otherProps };

  let color = props.iconColor;

  if (
    props.iconColor &&
    typeof props.iconColor === "string" &&
    props.iconColor.indexOf(".") !== -1
  ) {
    color = get(theme.colors, props.iconColor) || theme.colors.icon.primary;
  }

  if (props.error) {
    color = theme.colors.error.text;
  }
  const style = css`
    svg {
      width: ${props.size};
      height: ${props.size};
    }

    svg * {
      fill: ${color || "currentColor"};
    }
  `;

  return (
    <StyledComponent id="iconBase" css={style} {...props}>
      {icons[props.name]}
    </StyledComponent>
  );
};

const Icon = withTheme(IconBase);

Icon.displayName = "Icon";

export default Icon;
