/* Box
======================================= */

import React from "react";
import styled from "@emotion/styled";
import {
  compose,
  space,
  layout,
  flexbox,
  SpaceProps,
  LayoutProps,
  FlexboxProps
} from "styled-system";
import { useTheme } from "@emotion/react";
import { motion, useReducedMotion } from "framer-motion";

type StyledProps = SpaceProps & LayoutProps & FlexboxProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout, flexbox));

interface Props {
  id?: string;
  animate?: object;
  className?: string;
}

const Box: React.FC<Props & StyledProps> = (props) => {
  const theme: any = useTheme();
  const shouldReduceMotion = useReducedMotion();

  if (props.animate) {
    const animation = !shouldReduceMotion ? props.animate : theme.animation.reduced;

    return (
      <motion.div id={props.id} {...animation}>
        <StyledComponent {...props}>{props.children}</StyledComponent>
      </motion.div>
    );
  }
  return <StyledComponent {...props}>{props.children}</StyledComponent>;
};

Box.displayName = "Box";

export default Box;
