/** @jsxImportSource @emotion/react */
import React from "react";
import { connect } from "react-redux";

import { Text, Button } from "../..";
import { Box } from "../../../layout";
import styles from "./index.module.scss";

import { ChatCardTypes } from "../../../../constants";

import { CheckedInStatus } from "../../../../types";

import {
  updateAppointmentStatus as updateAppointmentStatusAction,
  UpdateAppointmentStatusActionData,
  UpdateAppointmentStatusActionOptions
} from "../../../../actions/chat-actions";
import AnimationPlayer, { AnimationVariant } from "../../AnimationPlayer";

interface PropsType {
  chatInfo?: ChatInfo;
  token: string;
  updateAppointmentStatus: (
    token: string,
    data: UpdateAppointmentStatusActionData,
    options?: UpdateAppointmentStatusActionOptions
  ) => void;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
  msg: Message;
}

const CheckInCard = ({
  chatInfo,
  token,
  updateAppointmentStatus,
  fetchNextMessage,
  msg
}: PropsType) => {
  const { appointments, checkedInLoading } = chatInfo as ChatInfo;
  const checkedIn = appointments && appointments[0].checkedIn === CheckedInStatus.CHECKED_IN;

  let content;
  let headerContent;
  try {
    content = typeof msg.payloadContent === "string" ? msg.payloadContent : "";
    content = JSON.parse(content);
    headerContent = content.headerContent;
  } catch (err) {
    headerContent = msg.payloadContent;
  }

  const checkIn = () => {
    if (token) {
      updateAppointmentStatus(
        token,
        {
          id: appointments[0].id,
          checkedIn: true
        },
        {
          onSuccess: () => {
            fetchNextMessage({
              userResponseType: ChatCardTypes.CHECK_IN,
              userResponse: null,
              chatFlowId: null,
              lastReceivedMessageId: null
            });
          }
        }
      );
    }
  };

  return (
    <Text className={styles.checkInCard}>
      <Box mb="20px" className="icon">
        <AnimationPlayer
          variant={AnimationVariant.CHECK_IN}
          configurationOptions={{
            loop: false,
            autoplay: checkedIn,
            isPaused: !checkedIn
          }}
        />
      </Box>
      <Text variant="large" bold mb="16px">
        {!checkedIn ? headerContent : "You are checked-in!"}
      </Text>
      {!checkedIn && (
        <Button
          variant={checkedIn ? "secondary" : "primary"}
          id="endBtn"
          onClick={checkIn}
          loading={checkedInLoading}
        >
          Check-In
        </Button>
      )}
    </Text>
  );
};

CheckInCard.displayName = "CheckInCard";

function mapStateToProps(state: ReduxState) {
  return {
    chatInfo: state.chatInfo
  };
}

export default connect(mapStateToProps, {
  updateAppointmentStatus: updateAppointmentStatusAction
})(CheckInCard);
