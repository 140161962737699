/* Button
======================================= */

/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { jsx, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";
import { Text, Loader } from "../../ui";
import { Check } from "../Icons";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("button")<StyledProps>(compose(space, layout));

/* Props
--------------------------------------- */

interface Props {
  id?: string;
  type?: "submit" | "button";
  variant?: "primary" | "secondary" | "success" | "text" | "inline" | "textDanger" | "small";
  loading?: boolean;
  disabled?: boolean;
  disableOnClick?: boolean;
  onClick?: (event: React.MouseEvent) => void;
}

/* Style
--------------------------------------- */

const style = (theme: any, variant: string | undefined) => {
  const commonStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${theme.buttons.primary.rounded};
    line-height: 24px;
    height: ${theme.buttons.primary.height};
    transition: all 200ms ease;
    padding: 0px 24px;
    user-select: none;

    &:focus {
      outline: none;
    }

    .icon {
      padding-right: 12px;
    }
  `;

  if (variant && variant === "secondary") {
    return css`
      ${commonStyle};
      background-color: ${theme.buttons.secondary.bg};
      border: solid 2px ${theme.buttons.secondary.color};

      span {
        color: ${theme.colors.black};
        font-family: ${theme.fonts.bold};
        letter-spacing: 0.5px;
      }

      // https://medium.com/@mezoistvan/finally-a-css-only-solution-to-hover-on-touchscreens-c498af39c31c
      @media (hover: hover) {
        &:hover {
          border-color: ${theme.buttons.secondary.hover};
        }
      }

      &:active {
        border-color: ${theme.buttons.secondary.hover};
      }

      &:focus {
        border-color: ${theme.buttons.secondary.hover};
      }

      &.isDisabled {
        background-color: ${theme.colors.grey[2]};
        border-color: ${theme.colors.grey[2]};
        pointer-events: none;

        span {
          color: #fff;
          font-family: ${theme.fonts.bold};
          letter-spacing: 0.5px;
        }
      }
    `;
  }

  if (variant && variant === "success") {
    return css`
      ${commonStyle};
      color: #385a51;
      background-color: #deede8;
      border: solid 2px #deede8;

      span {
        color: #385a51;
        font-family: ${theme.fonts.bold};
      }

      // https://medium.com/@mezoistvan/finally-a-css-only-solution-to-hover-on-touchscreens-c498af39c31c
      @media (hover: hover) {
        &:hover {
          border-color: #385a51;
        }
      }

      &:active {
        border-color: #385a51;
      }

      &:focus {
        border-color: #385a51;
      }

      &.isDisabled {
        color: #385a51;
        background-color: #deede8;
        border-color: #deede8;
        pointer-events: none;

        span {
          color: #385a51;
          font-family: ${theme.fonts.bold};
          letter-spacing: 0.5px;
        }
      }
    `;
  }

  if (variant && variant === "text") {
    return css`
      ${commonStyle};

      span {
        color: ${theme.colors.primary.blue};
      }

      &.isDisabled {
        pointer-events: none;

        span {
          color: ${theme.colors.grey[3]};
        }
      }
    `;
  }

  if (variant && variant === "inline") {
    return css`
      ${commonStyle};
      padding: 0;
      width: auto;

      span {
        color: ${theme.colors.primary.blue};
      }

      &.isDisabled {
        pointer-events: none;

        span {
          color: ${theme.colors.grey[3]};
        }
      }
    `;
  }

  if (variant && variant === "textDanger") {
    return css`
      ${commonStyle};

      span {
        color: ${theme.colors.error.text};
      }

      &.isDisabled {
        pointer-events: none;

        span {
          color: ${theme.colors.grey[3]};
        }
      }
    `;
  }

  if (variant && variant === "small") {
    return css`
      ${commonStyle};

      height: ${theme.buttons.small.height};

      background-color: ${theme.buttons.primary.bg};

      span {
        color: #fff;
        font-family: ${theme.fonts.bold};
      }

      &:active {
        background-color: ${theme.buttons.primary.hover};
      }

      &.isDisabled {
        pointer-events: none;
        background-color: ${theme.colors.grey[2]};
      }
    `;
  }

  return css`
    ${commonStyle};

    background-color: ${theme.buttons.primary.bg};

    span {
      color: #fff;
      font-family: ${theme.fonts.bold};
    }

    &:active {
      background-color: ${theme.buttons.primary.hover};
    }

    // https://medium.com/@mezoistvan/finally-a-css-only-solution-to-hover-on-touchscreens-c498af39c31c
    @media (hover: hover) {
      &:hover {
        background-color: ${theme.buttons.primary.hover};
      }
    }

    &:focus {
      background-color: ${theme.buttons.primary.hover};
    }

    &.isDisabled {
      pointer-events: none;
      background-color: ${theme.colors.grey[2]};
    }
  `;
};

/* Component
--------------------------------------- */

const Button: React.FC<Props & StyledProps> = ({
  loading,
  variant = "primary",
  width = "100%",
  disabled = false,
  disableOnClick = false,
  ...otherProps
}) => {
  const theme = useTheme();
  const props = {
    variant,
    loading,
    width,
    disabled,
    disableOnClick,
    ...otherProps
  };

  const [isDisabled, setIsDisabled] = useState(false);

  const className = isDisabled || loading || props.disabled ? "isDisabled" : undefined;

  const onClick = (e: React.MouseEvent) => {
    if (props.disableOnClick) setIsDisabled(true);

    if (props.onClick) props.onClick(e);
  };

  return (
    <StyledComponent
      css={style(theme, variant)}
      className={className}
      {...props}
      onClick={(e) => onClick(e)}
    >
      {variant === "success" && (
        <div className="icon">
          <Check size={18} />
        </div>
      )}
      {loading && (
        <div className="icon">
          <Loader size={24} />
        </div>
      )}
      <Text as="span">{props.children}</Text>
    </StyledComponent>
  );
};

Button.displayName = "Button";

export default Button;
