/* PHOTO_UPLOAD Card
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";
import {
  TextCard,
  Card,
  ImageContain,
  Button,
  Icon,
  UploadInput,
  FieldError,
  Loader,
  Text,
  SkipButton
} from "..";
import moment from "moment";
import { BucketNameSuffixes, ChatCardTypes } from "../../../constants";
import { getSignedUrl, uploadFiles, createPatientUpload, destroyPatientUpload } from "../../../lib";

const acceptedFileTypes = ["image/jpeg", "image/png", "application/pdf"];

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

interface PhotoUploadProps {
  msg: Message;
  index: number;
  token: string;
  isCurrentMessage: boolean;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
  conversationId: number;
}

interface PhotoUploadState {
  uploading: boolean;
  files: FileWithDataURL[];
  error: boolean;
  errorMsg: string;
}

interface FileWithDataURL {
  file: File | null;
  dataURL: string | ArrayBuffer | null;
  name: string | null;
  expanded?: boolean;
  secureMessageItemId?: number;
}

const style = () => css`
  .previewHeader {
    display: grid;
    grid-template-columns: 1fr 24px;
    grid-gap: 12px;
  }

  .previewTitle {
    display: flex;
    flex-flow: row;
    justify-content: start;
    align-items: center;

    > :first-of-type {
      margin-right: 12px;
    }
  }

  .previewTrash {
  }
`;

class PhotoUploadCard extends React.Component<PhotoUploadProps, PhotoUploadState> {
  constructor(props: PhotoUploadProps) {
    super(props);

    this.state = {
      uploading: false,
      files: [],
      error: false,
      errorMsg: ""
    };
  }

  onDrop = async (e: React.FormEvent<Element>) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    // https://stackoverflow.com/questions/57002671/javascript-input-event-fire-twice
    if (!e.target) return;
    e.target = null as unknown as EventTarget;

    const { files } = this.state;
    const { token, conversationId } = this.props;
    this.setState({ uploading: true });
    const file: File = (target.files as FileList)[0];

    if (!acceptedFileTypes.includes(file.type)) {
      this.setState({
        uploading: false,
        error: true,
        errorMsg: "File must be type jpeg, png or pdf."
      });
    } else {
      let dataURL;
      const reader = new FileReader();

      reader.onload = async () => {
        try {
          dataURL = reader.result;
          const {
            url,
            error,
            filename: fileName
          } = await getSignedUrl({
            bucketName: BucketNameSuffixes.PATIENT_UPLOADS,
            fileExt: file.type.split("/")[1],
            fileNamePrefix: "to-convert-",
            token,
            conversationId
          });
          if (error) {
            this.setState({
              uploading: false,
              error: true,
              errorMsg: "Failed to upload file. Please try again."
            });
          }

          await uploadFiles(url, dataURL);
          const { secureMessageItemId } = await createPatientUpload(fileName, conversationId);
          this.setState({
            error: false,
            uploading: false,
            files: [
              ...files,
              { file, dataURL, name: file.name, secureMessageItemId, expanded: true }
            ]
          });
        } catch (error) {
          this.setState({
            uploading: false,
            error: true,
            errorMsg: "Failed to upload file. Please try again."
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  submit = async () => {
    const { fetchNextMessage } = this.props;
    const { files } = this.state;
    await fetchNextMessage({
      lastReceivedMessageId: null,
      userResponse: String(files.length),
      userResponseType: ChatCardTypes.PHOTO_UPLOAD,
      chatFlowId: null,
      showUserResponse: true,
      createdAt: moment().toDate()
    });
    this.setState({ uploading: false, error: false, errorMsg: "" });
  };

  renderFileDrawer = (file: FileWithDataURL, previewIndex: number, uploading: boolean) => {
    const src = typeof file.dataURL === "string" ? file.dataURL : "";
    const { expanded, name } = file;
    let fileNameToDisplay = name;
    if (!fileNameToDisplay) fileNameToDisplay = `File ${previewIndex + 1}`;
    // else if (fileNameToDisplay.length > 20)
    //   fileNameToDisplay = `${fileNameToDisplay.substring(0, 20)}...`;

    return (
      <Card mb="16px" key={`file-upload-preview-${file.name}`}>
        <div className="previewHeader">
          <button
            type="button"
            className="previewTitle"
            onClick={() => this.toggleExpand(previewIndex)}
          >
            <Icon name={expanded ? "chevronDown" : "chevronRight"} />
            <Text variant="medium" textAlign="left">
              {fileNameToDisplay}
            </Text>
          </button>

          <button
            type="button"
            className="previewTrash"
            onClick={() => this.destroyUpload(previewIndex)}
            disabled={uploading}
          >
            <Icon name="trash" />
          </button>
        </div>
        {expanded && <ImageContain src={src} size="contain" mt="16px" />}
      </Card>
    );
  };

  toggleExpand = (previewIndex: number) => {
    const { files } = this.state;
    const newFiles = files;
    newFiles[previewIndex].expanded = !files[previewIndex].expanded;
    this.setState({ files: newFiles });
  };

  destroyUpload = async (previewIndex: number) => {
    this.setState({ uploading: true });
    const { files } = this.state;
    const { conversationId } = this.props;

    const { secureMessageItemId } = files[previewIndex];
    if (secureMessageItemId) {
      try {
        await destroyPatientUpload(secureMessageItemId, conversationId);
      } catch (error) {
        this.setState({
          uploading: false,
          error: true,
          errorMsg: "Failed to delete file. Please try again."
        });
        return;
      }
    }
    const newFiles = files;
    newFiles.splice(previewIndex, 1);
    this.setState({ uploading: false, files: newFiles, error: false, errorMsg: "" });
  };

  render() {
    const { msg, index, isCurrentMessage } = this.props;
    const { files, error, errorMsg, uploading } = this.state;
    const { payloadContent } = msg;
    const payloadOptions = msg?.payloadOptions as PhotoUploadCardPayloadOptions;
    const skippable = payloadOptions?.skippable || false;
    const maxUploads = payloadOptions?.maxUploads || 10;

    let promptText = payloadOptions?.uploadText || "Choose file";
    if (files.length >= maxUploads) promptText = "Maximum uploads reached";
    else if (files.length > 1) promptText = "Add another file";

    return (
      <StyledComponent css={style} {...this.props}>
        <TextCard
          msg={msg}
          index={index}
          avatar={<Icon name="logo" iconColor="reset" size="18px" />}
          mb="16px"
        >
          {payloadContent}
        </TextCard>
        {isCurrentMessage && (
          <>
            {files.map((file, idx) => this.renderFileDrawer(file, idx, uploading))}
            {!uploading && (
              <>
                <UploadInput
                  id={`uploadInput-${index}`}
                  mb="16px"
                  onChange={this.onDrop}
                  disabled={files.length >= maxUploads}
                  promptText={promptText}
                />
                {error && <FieldError mb="20px">{errorMsg}</FieldError>}
                <Button
                  id="photoSubmitBtn"
                  disableOnClick
                  onClick={this.submit}
                  disabled={files.length < 1}
                >
                  Upload
                </Button>
                {skippable && files.length === 0 && <SkipButton index={index} message={msg} />}
              </>
            )}
            {uploading && <Loader center />}
          </>
        )}
      </StyledComponent>
    );
  }
}

export default PhotoUploadCard;
