/* Address Input
======================================= */

/** @jsxImportSource @emotion/react */
import React, { useEffect, useCallback, useRef } from "react";
import { jsx, useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

import { Input } from "../../../ui";
import { loadScript } from "../../../../utils/externalScripts";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

/* Props
--------------------------------------- */

interface Props {
  name?: string;
  placeholder?: string;
  label?: string;
  updateResponse?: (userResponse: string) => void;
}

/* Component
--------------------------------------- */

const AddressInput: React.FC<Props & StyledProps> = (props: Props) => {
  const theme: any = useTheme();

  const ref = React.createRef<HTMLInputElement>();

  const autocomplete = useRef<google.maps.places.Autocomplete>();

  const { name = "address", placeholder = "", label = "", updateResponse = undefined } = props;

  const initAutocomplete = useCallback(() => {
    autocomplete.current = new window.google.maps.places.Autocomplete(
      ref.current as HTMLInputElement,
      {
        types: ["geocode", "establishment"],
        componentRestrictions: { country: "ca" },
        fields: ["formatted_address", "name"]
      }
    );
    google.maps.event.addListener(autocomplete.current, "place_changed", () => {
      if (autocomplete.current && updateResponse) {
        const placeResult = autocomplete.current.getPlace();
        const address = placeResult.formatted_address;
        const placeName = placeResult.name || "";

        if (address?.includes(placeName)) {
          updateResponse(address); // Prevent overlap in name and address
        } else {
          updateResponse(`${placeName}, ${address}`);
        }
      }
    });
  }, [ref, updateResponse]);

  const overrideGoogleStyles = useCallback(() => {
    const css = `
    .pac-container {
      font-family: ${theme.fonts.heading};
      border-top: 0;
      z-index: 1;
      margin-top: 9px;
    }
    .pac-matched, .pac-item-query, .pac-item {
      color: ${theme.colors.grey[5]};
    }
    .pac-item {
      white-space: normal;
    }
  `;
    const overrideStyle = document.createElement("style");
    overrideStyle.setAttribute("id", "overrideGoogleStyle");
    overrideStyle.innerHTML = css;
    const body = document.querySelector("body");
    if (body) {
      body.insertAdjacentElement("afterend", overrideStyle);
    }
  }, [theme]);

  useEffect(() => {
    if (!autocomplete.current) {
      if (!window.google) {
        const GOOGLE_MAP_KEY =
          process.env.REACT_APP_GOOGLE_MAP_API_KEY || process.env.STORYBOOK_GOOGLE_MAP_API_KEY;

        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_KEY}&libraries=places`,
          () => {
            initAutocomplete();
            overrideGoogleStyles();
          }
        );
      } else {
        initAutocomplete();
      }
    }
  }, [ref, name, initAutocomplete, overrideGoogleStyles]);

  return (
    <StyledComponent {...props}>
      <Input
        name={name}
        placeholder={placeholder}
        label={label}
        updateResponse={updateResponse}
        maxRows={1}
        icon="pin"
        inputRef={ref}
      />
    </StyledComponent>
  );
};

AddressInput.displayName = "AddressInput";

export default AddressInput;
