/** @jsxImportSource @emotion/react */
import React from "react";
import moment from "moment";
import { css, jsx, useTheme } from "@emotion/react";
import { SpaceProps, LayoutProps } from "styled-system";

import { Text } from "../..";
import { BookingMode, ThemeTypes } from "../../../../types";

type StyledProps = SpaceProps & LayoutProps;

type PropsType = { userResponse: BookingUserResponse };

const style = (theme: ThemeTypes) => css`
  .card {
    width: 100%;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px ${theme.colors.grey[2]};
    padding-bottom: 4px;
    margin-bottom: 8px;
  }
  .card:last-child {
    border-style: none;
  }

  .cardInfo {
    padding-right: 10px;
  }
`;

const BookingSummary = ({ userResponse }: PropsType) => {
  const { bookingMode, bookingReason, selectedAppointmentSlots } = userResponse;
  const theme = useTheme() as ThemeTypes;

  if (bookingMode === BookingMode.REQUEST) return null;

  const isAvailableTimesMode = bookingMode === BookingMode.AVAILABLE_TIMES;
  const isAutoBookMode = bookingMode === BookingMode.AUTO_BOOK;

  return (
    <div css={style(theme)}>
      {selectedAppointmentSlots && selectedAppointmentSlots.length > 0 ? (
        <>
          {isAvailableTimesMode && (
            <>
              <Text variant="titleMedium" mb="24px">
                Appointment request summary
              </Text>
              <Text mb="16px">
                These are the dates and times selected for your appointment booking. The clinic will
                review your request and get in contact with you to confirm the date and time.
              </Text>
            </>
          )}
          {isAutoBookMode && (
            <>
              <Text variant="titleMedium" mb="24px">
                Booking summary
              </Text>
              <Text mb="16px">Success! Your appointment has been booked.</Text>
            </>
          )}

          <Text variant="meta" mb="8px">
            Reason
          </Text>
          <Text mb="24px">{bookingReason.label}</Text>
          <Text variant="meta" mb="12px">
            Date &#38; Time
          </Text>
          {selectedAppointmentSlots.map((selectedSlot: AvailableSlot) => {
            const appointmentTime = moment(selectedSlot.startTime).format(
              "MMMM Do, YYYY - hh:mm A"
            );
            return (
              <div key={`${selectedSlot.slotIds.join(",")}`} className="card">
                <div className="cardInfo">
                  <Text variant="small" mb="4px">
                    {appointmentTime}
                  </Text>
                  <Text variant="small" mb="4px">
                    {selectedSlot.locationName}
                  </Text>
                  <Text variant="small" mb="4px">
                    {selectedSlot.practitionerName}
                  </Text>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <Text variant="small" mb="8px">
          No appointment times selected
        </Text>
      )}
    </div>
  );
};

export default BookingSummary;
