/* Step
======================================= */

/** @jsxImportSource @emotion/react */
import React, { useContext } from "react";
import { jsx, css, useTheme } from "@emotion/react";

import { VariantContext, VariantTypes } from "./Steps";

interface Props {
  number: number | string;
}

const Step: React.FC<Props> = (props) => {
  const variant = useContext<VariantTypes>(VariantContext);

  const theme: any = useTheme();

  let variantStyle = css``;

  if (variant === "vertical") {
    variantStyle = css`
      .line {
        position: absolute;
        top: 0;
        left: 15px;
        z-index: 0;
        width: 2px;
        height: 100%;
        background-color: #e0e1e4;
      }
    `;
  }

  if (variant === "horizontal") {
    variantStyle = css`
      border-top: 1px solid ${theme.colors.grey[1]};
      padding-top: 24px;
    `;
  }

  if (variant === "bullet") {
    variantStyle = css`
      padding-bottom: 0px;

      :last-child {
        padding-bottom: 8px;
      }
    `;
  }

  if (variant === "numbered") {
    variantStyle = css`
      padding-bottom: 0px;

      :last-child {
        padding-bottom: 8px;
      }
    `;
  }

  const style = css`
    display: flex;
    position: relative;
    padding-bottom: 30px;

    .number {
      width: 32px;
      height: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      flex-shrink: 0;

      color: #fff;
      font-size: 16px;
      font-weight: 600;

      background-color: ${theme.colors.grey[4]};
      border-radius: 50px;

      margin-right: 12px;

      position: relative;
      z-index: 1;
    }

    &:last-child {
      padding-bottom: 0px;
      .line {
        display: none;
      }
    }

    .content {
      padding-top: 5px;
    }

    .step-horizontal > .line {
      display: none;
    }

    .step-bullet > .number {
      position: absolute;
      top: 11px;
      left: 0;
      width: 5px;
      height: 5px;
      border-radius: 50%;
    }

    .step-bullet > .line {
      display: none;
    }

    .step-bullet > .content {
      padding-top: 0;
      padding-left: 10px;
    }

    .step-numbered > .number {
      position: absolute;
      top: 0px;
      left: 0;
      width: auto;
      max-width: 20px;
      overflow: hidden;
      color: black;
      background-color: white;
      align-items: start;
      justify-content: start;
      font-size: 16px;
      font-weight: 300;
      margin-right: 0px;
      text-align: left;

      :after {
        content: ". ";
      }
    }

    .step-numbered > .line {
      display: none;
    }

    .step-numbered > .content {
      padding-top: 0;
      padding-left: 20px;
    }

    ${variantStyle}
  `;

  return (
    <div className={`step-${variant}`} css={style}>
      <div className="number">{variant !== "bullet" ? props.number : ""}</div>
      <div className="line" />
      <div className="content">{props.children}</div>
    </div>
  );
};

Step.displayName = "Step";

export default Step;
