/* Text
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  compose,
  style,
  variant,
  ResponsiveValue,
  space,
  layout,
  typography,
  flexbox,
  SpaceProps,
  LayoutProps,
  TypographyProps,
  FlexboxProps
} from "styled-system";
import CSS from "csstype";
import filterProps from "../../../utils/filterProps";

export const textVariants = {
  xsmall: {
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.25px",
    color: "grey.4"
  },
  small: {
    fontSize: "14px",
    lineHeight: "16px",
    color: "grey.4"
  },
  medium: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "grey.4"
  },
  large: {
    fontSize: "18px",
    lineHeight: "24px",
    color: "grey.4"
  },
  titleSmall: {
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "grey.4"
  },
  titleMedium: {
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "0px",
    color: "grey.4"
  },
  titleLarge: {
    fontSize: "28px",
    lineHeight: "32px",
    letterSpacing: "-0.75px",
    color: "grey.4"
  },
  titleXLarge: {
    fontSize: "32px",
    lineHeight: "40px",
    letterSpacing: "-1px",
    color: "grey.4"
  },
  meta: {
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.25px",
    textTransform: "uppercase",
    color: "grey.4"
  }
};

export type VariantTypes = keyof typeof textVariants;

const textColor = style({
  prop: "textColor",
  cssProperty: "color",
  key: "colors"
});

type Props = {
  id?: string;
  textColor?: ResponsiveValue<CSS.Property.Color>;
  variant?: VariantTypes;
  as?: string;
  className?: string;
  bold?: boolean;
  uppercase?: boolean;
  error?: boolean;
  textAlign?: string;
};

type StyledProps = SpaceProps & LayoutProps & TypographyProps & FlexboxProps;

const StyledComponent = styled("div", { shouldForwardProp: (prop) => filterProps(prop as string) })<
  Props & StyledProps
>(
  variant({
    variants: textVariants
  }),
  compose(space, layout, typography, flexbox, textColor)
);

const Text: React.FC<Props & StyledProps> = ({
  variant = "medium",
  fontFamily = "body",
  ...otherProps
}) => {
  const theme: any = useTheme();
  const props = { variant, fontFamily, ...otherProps };
  const boldFromProp = props.bold
    ? css`
        font-family: ${theme.fonts.bold};
      `
    : css``;
  const uppercaseFromProp = props.uppercase
    ? css`
        text-transform: uppercase;
      `
    : css``;
  const errorFromProp = props.error
    ? css`
        color: ${theme.colors.error.text};
      `
    : css``;
  const textAlignFromProp = props.textAlign
    ? css`
        text-align: ${props.textAlign};
      `
    : css``;

  const style = css`
    ${boldFromProp};
    ${uppercaseFromProp};
    ${errorFromProp};
    ${textAlignFromProp};

    p {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    ul,
    ol {
      margin: 0;
      padding-left: 18px;

      li {
        margin-bottom: 5px;
      }
    }
  `;

  return (
    <StyledComponent className="text-ui" {...(props as any)} css={style}>
      {props.children}
    </StyledComponent>
  );
};

Text.displayName = "Text";

export default Text;
