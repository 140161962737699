/** @jsxImportSource @emotion/react */
import React from "react";

import { ChatCardTypes } from "../../../constants";
import { SelectCard } from "../../../components/ui";

type PropsType = {
  msg: Message;
  index: number;
  isCurrentMessage: boolean;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
};

type PayloadOptions = {
  answers: [{ text: string; value: string }];
  isMulti: boolean;
  multiAppointmentMessage?: string;
};

const InstructionReasonsCard = ({ msg, index, isCurrentMessage, fetchNextMessage }: PropsType) => {
  const handleNextMessage = (params: FetchNextMessageParams) => {
    const userResponseArray = Array.isArray(params.userResponse)
      ? params.userResponse
      : ([params.userResponse] as FetchNextMessageParams["userResponse"]);
    fetchNextMessage({
      ...params,
      userResponseType: ChatCardTypes.INSTRUCTION_REASONS,
      userResponse: userResponseArray
    });
  };
  const payloadOptions = msg?.payloadOptions as PayloadOptions;

  if (payloadOptions.isMulti) {
    msg.payloadContent = payloadOptions.multiAppointmentMessage || msg.payloadContent;
  }

  return (
    <SelectCard
      msg={msg}
      index={index}
      allowMulti={payloadOptions.isMulti}
      fetchNextMessage={handleNextMessage}
      isCurrentMessage={isCurrentMessage}
    />
  );
};

export default InstructionReasonsCard;
