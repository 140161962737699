/* Instructions Card
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { connect } from "react-redux";
import { jsx, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

import { Text, Avatar } from "..";
import JsonContentRenderEngine from "../../JsonContentRenderEngine";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

/* Style
--------------------------------------- */

const style = (theme: any) => css`
  display: flex;
  flex-grow: 1;

  .CardContent {
    width: 100%;
  }
`;

/* Props
--------------------------------------- */

interface Props {
  index: number;
  msg?: Message;
  chatInfo?: ChatInfo;
  avatar?: any;
}

/* Component
--------------------------------------- */

const InstructionsCard: React.FC<Props & StyledProps> = (props) => {
  const theme = useTheme();
  const { msg, avatar, chatInfo } = props;

  const { index } = props;
  // TODO: Make custom card PayloadOptions type
  const payloadOptions = msg?.payloadOptions as BasePayloadOptions;
  const jsonContent = payloadOptions.contentJson;
  const phoneNumber = chatInfo?.locations?.[0]?.phoneNumber || null;

  return (
    <StyledComponent css={style(theme)} {...props}>
      {avatar && <Avatar>{avatar}</Avatar>}
      <div className="CardContent">
        {msg?.payloadContent && (
          <Text id={`text-${index}`} variant="large" mt="5px" mb="32px">
            {msg.payloadContent}
          </Text>
        )}
        {jsonContent && (
          <JsonContentRenderEngine
            content={jsonContent}
            placeholderContext={{ locationNumber: phoneNumber || "" }}
            contentBoxShadow
          />
        )}
      </div>
    </StyledComponent>
  );
};

InstructionsCard.displayName = "InstructionsCard";

function mapStateToProps(state: ReduxState) {
  return {
    chatInfo: state.chatInfo
  };
}

export default connect(mapStateToProps, null)(InstructionsCard);
