/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { css, jsx, useTheme } from "@emotion/react";
import { connect } from "react-redux";
import { SpaceProps, LayoutProps } from "styled-system";

import { Box } from "../../../layout";
import { Button, Drawer, Text, Icon, Modal } from "../..";

import { ThemeTypes, BookingMode } from "../../../../types";
import { ManStanding } from "../../Icons";

type StyledProps = SpaceProps & LayoutProps;

interface PropsType {
  bookingMode: BookingMode;
  bookingReasonLabel: string;
  selectedAppointmentSlots?: Array<AvailableSlot>;
  removeAvailableSlotSelection: (slot: AvailableSlot) => void;
  setSlotSelection: (slots: AvailableSlot[]) => void;
  submitSelectedSlots: (submitAsBookingMode?: BookingMode) => void;
  reviewOpen: boolean;
  setReviewOpen: (isOpen: boolean) => void;
  autoBook: () => void;
  autoBookLoading: boolean;
  failedAttempts: number;
}

const style = ({ theme, reviewOpen }: { theme: ThemeTypes; reviewOpen: boolean }) => css`
  position: fixed;
  width: 100%;
  max-width: 600px;
  bottom: 0;
  z-index: 101;

  .reviewBackground {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    display: ${reviewOpen ? "visible" : "none"};
    z-index: 111;
    transition: opacity 0.2s ease-in-out;
    opacity: ${reviewOpen ? "0.1" : "0"};
  }

  .reviewContainer {
    width: 100%;
    z-index: 112;
    background-color: #4072de;
    border-radius: 20px 20px 0 0;
    box-shadow: 0 10px 30px #d7d8db;
    overflow: hidden;
    transition: max-height background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
    height: auto;
    max-height: 72px;
  }

  .reviewContainer.reviewContainerOpen {
    max-height: calc(100vh - 72px);
    background-color: white;
  }

  .reviewHeader {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    width: 100%;
    margin: 0;
    padding: 12px 24px 12px 24px;
  }

  .reviewContent {
    position: relative;
    height: auto;
    max-height: calc(100vh - 72px - 72px);
    overflow-y: auto;
  }

  .card {
    width: 100%;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px ${theme.colors.grey[2]};
    padding-bottom: 4px;
    margin-bottom: 8px;
  }

  .cardInfo {
    padding-right: 10px;
  }

  .failureModalContent {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
`;

const ReviewDrawer = ({
  bookingMode,
  bookingReasonLabel,
  selectedAppointmentSlots,
  submitSelectedSlots,
  removeAvailableSlotSelection,
  setSlotSelection,
  reviewOpen,
  setReviewOpen,
  autoBook,
  autoBookLoading,
  failedAttempts
}: PropsType) => {
  const theme = useTheme() as ThemeTypes;

  const [initialFailedAttempts, setInitialFailedAttempts] = useState(failedAttempts);
  const [failureModalShowing, setFailureModalShowing] = useState(false);

  const sortedSelectedSlots = selectedAppointmentSlots?.sort((a, b) => {
    if (a.startTime > b.startTime) return 1;
    if (a.startTime < b.startTime) return -1;
    return 0;
  });
  const hasSelectedTimes = selectedAppointmentSlots && selectedAppointmentSlots.length > 0;

  useEffect(() => {
    if (failedAttempts >= 3) {
      setFailureModalShowing(true);
    }
  }, [failedAttempts]);

  if (bookingMode === BookingMode.AUTO_BOOK)
    return (
      <Drawer css={style({ theme, reviewOpen })}>
        {initialFailedAttempts === failedAttempts ? (
          <>
            <div className="reviewBackground" onClick={() => setReviewOpen(false)} />
            {hasSelectedTimes ? (
              <div
                className={reviewOpen ? "reviewContainer reviewContainerOpen" : "reviewContainer"}
              >
                <div className="reviewHeader" onClick={() => setReviewOpen(!reviewOpen)}>
                  {reviewOpen ? (
                    <Text variant="titleMedium" mr="12px">
                      Review and book
                    </Text>
                  ) : (
                    <Text mr="12px" textColor={reviewOpen ? "default" : "white"} bold={!reviewOpen}>
                      Review and book
                    </Text>
                  )}
                  <Icon
                    name={reviewOpen ? "chevronDown" : "chevronUp"}
                    size="18px"
                    iconColor={reviewOpen ? "grey.4" : "white"}
                  />
                </div>

                <Box p="12px 24px 12px 24px" className="reviewContent">
                  {sortedSelectedSlots && sortedSelectedSlots.length > 0 && (
                    <>
                      <Text variant="meta" mb="12px">
                        Reason
                      </Text>
                      <Text mb="16px">{bookingReasonLabel}</Text>
                      <Text variant="meta" mb="12px">
                        Date &#38; Time Selected
                      </Text>
                      {sortedSelectedSlots.map((selectedSlot: AvailableSlot) => {
                        const appointmentTime = moment
                          .tz(selectedSlot.startTime, selectedSlot.locationTimezone)
                          .format("MMMM Do, YYYY - hh:mm A");
                        return (
                          <div key={`${selectedSlot.slotIds.join(",")}`} className="card">
                            <div className="cardInfo">
                              <Text variant="small" mb="4px">
                                {appointmentTime}
                              </Text>
                              <Text variant="small" mb="4px">
                                {selectedSlot.locationName}
                              </Text>
                              <Text variant="small" mb="4px">
                                {selectedSlot.practitionerName}
                              </Text>
                            </div>

                            <button
                              type="button"
                              onClick={() => {
                                removeAvailableSlotSelection(selectedSlot);
                                setReviewOpen(false);
                              }}
                            >
                              <Icon name="trash" size="24px" iconColor="icon.primary" />
                            </button>
                          </div>
                        );
                      })}
                    </>
                  )}
                  <Button
                    id="bookingSubmit"
                    disabled={autoBookLoading}
                    onClick={autoBook}
                    mb="16px"
                    mt="16px"
                  >
                    Book now
                  </Button>
                </Box>
              </div>
            ) : (
              <div className={reviewOpen ? "reviewContainer reviewContainerOpen" : "hidden"}>
                {reviewOpen && (
                  <>
                    <div className="reviewHeader" onClick={() => setReviewOpen(!reviewOpen)}>
                      <Text variant="titleMedium" mr="12px">
                        No times work
                      </Text>
                    </div>
                    <Box p="12px 24px 12px 24px" className="reviewContent">
                      <Text mb="32px">
                        If you can’t find a time that works you can still submit an appointment
                        request and the clinic will call you
                      </Text>
                      <Button
                        id="bookingSubmit"
                        disabled={autoBookLoading}
                        onClick={() => {
                          submitSelectedSlots(BookingMode.REQUEST);
                        }}
                        mb="16px"
                        mt="16px"
                      >
                        Create a request
                      </Button>
                    </Box>
                  </>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <div className="reviewBackground" />
            <div className={reviewOpen ? "reviewContainer reviewContainerOpen" : "reviewContainer"}>
              <div className="reviewHeader">
                <Text variant="titleMedium" mr="12px">
                  Oops! Unable to book
                </Text>
              </div>
              <Box p="12px 24px 12px 24px" className="reviewContent">
                <Text mb="16px">Please try again or select another time.</Text>

                <Button
                  id="bookingRetry"
                  onClick={() => {
                    setReviewOpen(false);
                    setInitialFailedAttempts(failedAttempts);
                  }}
                  variant={hasSelectedTimes ? undefined : "secondary"}
                  mb="16px"
                  mt="16px"
                >
                  Try again
                </Button>
              </Box>
            </div>
            <Modal showing={failureModalShowing} setShowing={setFailureModalShowing}>
              <div className="failureModalContent">
                <ManStanding />
                <Text variant="titleMedium" mb="16px">
                  Something&#39;s not working
                </Text>
                <Text mb="48px">
                  You can still create an appointment request for the clinic to review.
                </Text>
              </div>

              <Button
                onClick={() => {
                  setSlotSelection([]);
                  submitSelectedSlots(BookingMode.REQUEST);
                }}
                mb="64px"
              >
                Create a request
              </Button>
            </Modal>
          </>
        )}
      </Drawer>
    );

  return (
    <Drawer css={style({ theme, reviewOpen })}>
      <div className="reviewBackground" onClick={() => setReviewOpen(false)} />
      <div className={reviewOpen ? "reviewContainer reviewContainerOpen" : "reviewContainer"}>
        <div className="reviewHeader" onClick={() => setReviewOpen(!reviewOpen)}>
          {reviewOpen ? (
            <Text variant="titleMedium" mr="12px">
              {hasSelectedTimes ? "Review selected times" : "No times selected"}
            </Text>
          ) : (
            <Text mr="12px" textColor={reviewOpen ? "default" : "white"} bold={!reviewOpen}>
              {hasSelectedTimes
                ? `Review ${sortedSelectedSlots?.length || "0"} selected`
                : "No times selected"}
            </Text>
          )}
          <Icon
            name={reviewOpen ? "chevronDown" : "chevronUp"}
            size="18px"
            iconColor={reviewOpen ? "grey.4" : "white"}
          />
        </div>
        <Box p="12px 24px 12px 24px" className="reviewContent">
          {hasSelectedTimes && sortedSelectedSlots ? (
            <>
              <Text mb="16px">
                These are the dates and times selected for your appointment booking. The clinic will
                review your request and get in contact with you to confirm the date and time.
              </Text>
              <Text variant="meta" mb="12px">
                Reason
              </Text>
              <Text mb="16px">{bookingReasonLabel}</Text>
              <Text variant="meta" mb="12px">
                Date &#38; Time Selected
              </Text>
              {sortedSelectedSlots.length === 0 && (
                <div className="card">
                  <div className="cardInfo">
                    <Text variant="small" mb="4px">
                      No appointment times selected
                    </Text>
                  </div>
                </div>
              )}
              {sortedSelectedSlots.map((selectedSlot: AvailableSlot) => {
                const appointmentTime = moment
                  .tz(selectedSlot.startTime, selectedSlot.locationTimezone)
                  .format("MMMM Do, YYYY - hh:mm A");
                return (
                  <div key={`${selectedSlot.slotIds.join(",")}`} className="card">
                    <div className="cardInfo">
                      <Text variant="small" mb="4px">
                        {appointmentTime}
                      </Text>
                      <Text variant="small" mb="4px">
                        {selectedSlot.locationName}
                      </Text>
                      <Text variant="small" mb="4px">
                        {selectedSlot.practitionerName}
                      </Text>
                    </div>

                    <button
                      type="button"
                      onClick={() => {
                        removeAvailableSlotSelection(selectedSlot);
                      }}
                    >
                      <Icon name="trash" size="24px" iconColor="icon.primary" />
                    </button>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <Text mb="16px">The times you select will show up here.</Text>
              <Text mb="32px">
                If you can’t find a time that works you can still submit an appointment request and
                the clinic will call you
              </Text>
            </>
          )}
          <Button
            id="bookingSubmit"
            onClick={() => {
              const submitMode = hasSelectedTimes
                ? BookingMode.AVAILABLE_TIMES
                : BookingMode.REQUEST;
              submitSelectedSlots(submitMode);
            }}
            variant={hasSelectedTimes ? undefined : "secondary"}
            mb="16px"
            mt="16px"
          >
            {hasSelectedTimes ? "Send Request" : "Submit request with no times"}
          </Button>
        </Box>
      </div>
    </Drawer>
  );
};

function mapStateToProps({ booking }: ReduxState) {
  return {
    failedAttempts: booking.failedAttempts,
    autoBookLoading: booking.autoBookLoading
  };
}

export default connect(mapStateToProps)(ReviewDrawer);
