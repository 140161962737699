/* Text Card
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";
import TextCard from "./TextCard";
import { AddressInput, Button, Icon, SkipButton } from "..";
import moment from "moment";
import { ChatCardTypes } from "../../../constants";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("form")<StyledProps>(compose(space, layout));

/* Props
--------------------------------------- */

interface AddressInputProps {
  msg: Message;
  index: number;
  isCurrentMessage: boolean;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
  editMode?: boolean;
  save?: (userResponse: string) => Promise<void>;
}

interface TextInputState {
  userResponse: string;
}

/* Component
--------------------------------------- */

class AddressInputCard extends React.Component<AddressInputProps, TextInputState> {
  constructor(props: AddressInputProps) {
    super(props);

    this.state = {
      userResponse: ""
    };
  }

  updateResponse = (userResponse: string): void => {
    this.setState({ userResponse });
  };

  submit = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    const { userResponse } = this.state;
    const { fetchNextMessage, save } = this.props;

    if (save) {
      return save(userResponse);
    }

    return fetchNextMessage({
      lastReceivedMessageId: null,
      userResponse,
      userResponseType: ChatCardTypes.ADDRESS_INPUT,
      chatFlowId: null,
      showUserResponse: true,
      createdAt: moment().toDate()
    });
  };

  render(): JSX.Element {
    const { msg, index, isCurrentMessage, editMode } = this.props;
    const { userResponse } = this.state;
    // TODO: Make custom card PayloadOptions type
    const payloadOptions = msg?.payloadOptions as BasePayloadOptions;
    const skippable = payloadOptions?.skippable || false;

    return (
      <StyledComponent {...this.props}>
        <TextCard
          msg={msg}
          index={index}
          avatar={<Icon name="logo" iconColor="reset" size="18px" />}
          mb="16px"
          editMode={editMode}
        />
        {isCurrentMessage && (
          <AddressInput
            name="address"
            placeholder="Type response"
            label="Enter address"
            mb="20px"
            updateResponse={this.updateResponse}
          />
        )}
        {isCurrentMessage && (
          <Button type="submit" onClick={this.submit} disabled={!userResponse} disableOnClick>
            Send
          </Button>
        )}
        {isCurrentMessage && !editMode && skippable && <SkipButton index={index} message={msg} />}
      </StyledComponent>
    );
  }
}

export default AddressInputCard;
