import React, { useContext } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

import { DeprecatedChatSessionManagerContext } from "../../../providers/DeprecatedChatSessionManager";
import { ChatSessionManagerContext } from "../../../providers/ChatSessionManager";
import useCountdown from "../../../../hooks/useCountdown";
import SecureMessageFileItem from "./SecureMessageFileItem";
import TextCard from "../TextCard";
import Icon from "../../Icon/Icon";

import { deleteSecureMessageItem as deleteSecureMessageItemAction } from "../../../../actions";

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

interface Props {
  msg: Message;
  deleteSecureMessageItem: (messageTemplateId: number, secureMessageFileId: number) => void;
}

const SESSION_EXPIRY = 900000; // 15 minutes

const SecureMessageCard: React.FC<Props> = (props) => {
  const { endChatSession: deprecatedEndChatSession } = useContext(
    DeprecatedChatSessionManagerContext
  );
  const { endChatSession } = useContext(ChatSessionManagerContext);

  useCountdown({
    onTimeout: () => {
      if (deprecatedEndChatSession) {
        deprecatedEndChatSession();
      } else {
        endChatSession();
      }
    },
    timeoutMilliseconds: SESSION_EXPIRY
  });

  const { msg, deleteSecureMessageItem } = props;

  const { secureMessageItems = [] } = msg.payloadOptions as SecureMessagePayloadOptions;

  const sortedItems = React.useMemo(
    () => secureMessageItems.sort((a) => (a.payloadContent !== undefined ? -1 : 1)),
    [secureMessageItems]
  );

  const onDelete = (itemId: number) => {
    if (msg?.id) {
      deleteSecureMessageItem(msg.id, itemId);
    }
  };

  return (
    <StyledComponent>
      {msg.payloadContent && (
        <TextCard
          msg={msg}
          index={0}
          avatar={<Icon name="logo" iconColor="reset" size="18px" />}
          mb="16px"
        />
      )}
      {sortedItems.map((item) => (
        <SecureMessageFileItem key={item.id} onDelete={onDelete} item={item} />
      ))}
    </StyledComponent>
  );
};

export default connect(null, { deleteSecureMessageItem: deleteSecureMessageItemAction })(
  SecureMessageCard
);
