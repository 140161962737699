/* Modal Overlay
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

import { Icon } from "../../ui";
import { motion } from "framer-motion";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledWrapper = styled("div")<StyledProps>();
const StyledComponent = styled(motion.div)<StyledProps>(compose(space, layout));

/* Style
--------------------------------------- */

const wrapperStyle = (showing: boolean) => css`
  position: fixed; // Allows for modal use inside relative containers that are smaller than viewport
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${showing ? "999" : "-1"};
`;

const style = (theme: any) => css`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(148, 151, 158, 0.65);
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  .overlay-content {
    background: #fff;
    box-shadow: ${theme.shadows.small};
    border-radius: ${theme.radii.normal};
    padding: 40px 24px;
    width: 100%;
    max-width: calc(600px - 48px);
    max-height: calc(100vh - 48px);
    overflow-x: auto;
  }

  .overlay-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 18px 6px 18px;
  }
`;

/* Props
--------------------------------------- */

interface Props {
  as?: any;
  showing: boolean;
  setShowing?: (showing: boolean) => void;
  disableCloseButton?: boolean;
}

/* Component
--------------------------------------- */

const Modal: React.FC<Props & StyledProps> = (props) => {
  const theme = useTheme();
  const { showing = false, setShowing, children, disableCloseButton } = props;

  const animateMainIn = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { type: "spring", stiffness: 300, damping: 18 }
  };

  const animateContentIn = {
    initial: { opacity: 0, y: 100 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 100 },
    transition: { type: "spring", stiffness: 300, damping: 18 }
  };

  return (
    <StyledWrapper css={wrapperStyle(showing)}>
      {showing && (
        <StyledComponent css={style(theme)} {...props} {...animateMainIn}>
          <motion.div {...animateContentIn} className="overlay-content">
            {setShowing && (
              <div className="overlay-header">
                <button
                  type="button"
                  className="btn-close"
                  disabled={disableCloseButton}
                  onClick={() => setShowing && setShowing(false)}
                >
                  <Icon name="close" iconColor={disableCloseButton ? "grey.2" : "grey.4"} />
                </button>
              </div>
            )}

            <div className="overlay-body">{children}</div>
          </motion.div>
        </StyledComponent>
      )}
    </StyledWrapper>
  );
};

Modal.displayName = "Modal";

export default Modal;
