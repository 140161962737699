import React from "react";
import "add-to-calendar-button";
import moment from "moment";

import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";
import { Box, Grid } from "../../layout";

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

interface Props {
  title?: string;
  description?: string;
  location?: string;
  startTime?: string;
  endTime?: string;
}

const AddToCalendarButton: React.FC<Props & StyledProps> = (props) => {
  const { title = "", description = "", location = "", startTime = "", endTime = "" } = props;

  const startDate = moment(startTime).format("YYYY-MM-DD");
  const atcbStartTime = moment(startTime).format("HH:MM");
  const atcbEndTime = moment(endTime).format("HH:MM");

  return (
    <StyledComponent {...props}>
      <Grid>
        <Box>
          <add-to-calendar-button
            name={title}
            description={description}
            label="Add to calendar"
            startDate={startDate}
            startTime={atcbStartTime}
            endTime={atcbEndTime}
            location={location}
            customCss="/atcb.css"
            buttonStyle="custom"
            trigger="click"
            iCalFileName={location}
            hideIconButton
            hideBranding
            hideIconList
            hideIconModal
            hideCheckmark
          />
        </Box>
      </Grid>
    </StyledComponent>
  );
};

AddToCalendarButton.displayName = "AddToCalendarButton";

export default AddToCalendarButton;
