/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  compose,
  space,
  layout,
  border,
  SpaceProps,
  LayoutProps,
  BorderRadiusProps
} from "styled-system";

type StyledProps = SpaceProps & LayoutProps & BorderRadiusProps & { boxShadow?: boolean };

const StyledComponent = styled("div")<StyledProps>(compose(space, layout, border));

const Card: React.FC<StyledProps> = ({ p = "16px", borderRadius = "normal", ...otherProps }) => {
  const theme: any = useTheme();
  const props = { p, borderRadius, ...otherProps };

  const style = css`
    background: ${theme.colors.white};
    box-shadow: ${props.boxShadow ? theme.shadows.small : ""};
  `;

  return (
    <StyledComponent id="card" className="card-ui" css={style} {...props}>
      {props.children}
    </StyledComponent>
  );
};

Card.displayName = "Card";

export default Card;
