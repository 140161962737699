/* Text Card
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";
import { Text, Avatar, Link } from "..";
import JsonContentRenderEngine from "../../JsonContentRenderEngine";

import { VariantTypes } from "../Text/Text";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

/* Style
--------------------------------------- */

const style = (theme: any) => css`
  display: flex;

  a {
    word-break: break-all;
  }
`;

/* Props
--------------------------------------- */

interface Props {
  index: number;
  msg?: Message;
  avatar?: any;
  editMode?: boolean;
  variant?: VariantTypes;
  centerLink?: boolean;
}

/* Component
--------------------------------------- */

const TextCard: React.FC<Props & StyledProps> = (props) => {
  const theme = useTheme();
  const { msg, children, editMode, avatar, variant: propsVariant, centerLink } = props;

  const variant = propsVariant || "large";
  const { index } = props;

  let contentBeforeLink = msg ? msg.payloadContent : children;
  let link;
  let contentAfterLink;
  let linkText;
  // TODO: Make custom card PayloadOptions type
  const payloadOptions = msg?.payloadOptions as BasePayloadOptions;

  const jsonContent = payloadOptions?.contentJson;

  if (msg && payloadOptions) {
    link = payloadOptions.link ? payloadOptions.link : "";
    linkText = payloadOptions.linkText ? payloadOptions.linkText : "";

    if (contentBeforeLink && typeof contentBeforeLink === "string") {
      if (link && contentBeforeLink.includes("[link]")) {
        const splitContent = contentBeforeLink.split("[link]");
        [contentBeforeLink, contentAfterLink] = splitContent;
      }
    }
  }

  const linkStyle = (theme: any) => css`
    ${centerLink ? "display: flex; align-items: center; justify-content: center;" : ""}
  `;

  return (
    <StyledComponent css={style(theme)} {...props}>
      {!editMode && avatar && <Avatar>{avatar}</Avatar>}
      <StyledComponent {...props}>
        <Text id={`text-${index}`} variant={variant}>
          {contentBeforeLink}
        </Text>
        {link && (
          <StyledComponent css={linkStyle}>
            <Link id={`link-${index}`} icon={null} textColor="secondary.blue">
              <a href={link} target="_blank" rel="noopener noreferrer" tabIndex={-9999}>
                {linkText || link}
              </a>
            </Link>
          </StyledComponent>
        )}
        {contentAfterLink && (
          <Text id={`textContent-${index}`} variant={variant}>
            {contentAfterLink}
          </Text>
        )}

        {jsonContent && <JsonContentRenderEngine content={jsonContent} />}
      </StyledComponent>
    </StyledComponent>
  );
};

TextCard.displayName = "TextCard";

export default TextCard;
