import * as React from "react";
import Text from "../../components/ui/Text/Text";
import { Drawer, Card, Avatar, Icon, Button } from "../../components/ui";
import { Grid, Scroll } from "../../components/layout";
import { useTheme } from "@emotion/react";

const Home: React.FC = () => {
  const theme: any = useTheme();

  return (
    <Drawer backgroundColor={theme.colors.grey[1]}>
      <Scroll>
        <Grid p="40px">
          <Card p="24px 0px 24px 0px">
            <Grid p="34px" flexDirection="column">
              <Avatar size={40}>
                <Icon name="logo" iconColor="reset" size="18px" />
              </Avatar>
              <Text variant="titleSmall" bold pt="12px" pb="12px">
                Hi there!
              </Text>
              <Text variant="small" pb="12px">
                We’re Mikata Health, a health tech startup based in Calgary, Alberta, Canada.
              </Text>
              <Text variant="small" pb="24px">
                We help clinics deliver outstanding care with tools that make patient communication
                proactive, personal, and convenient.
              </Text>
              <Grid flexDirection="column" alignContent="center">
                <Button width="67%" variant="small">
                  <a href="https://www.mikatahealth.com" target="_blank" rel="noopener noreferrer">
                    Learn More
                  </a>
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Scroll>
    </Drawer>
  );
};

export default Home;
