/* eslint-disable no-case-declarations */

import { DELETE_APPOINTMENT_RECORDING, GET_APPOINTMENT_RECORDINGS } from "../constants";
import { ActionStatus } from "../types";
import {
  AppointmentRecordingsActions,
  DeleteAppointmentRecordingAction,
  GetAppointmentRecordingsAction
} from "../types/actions";

const initialState: AppointmentRecordingsStore = {
  error: false,
  loading: false,
  recordings: []
};

export const appointmentRecordingsReducer = (
  state = initialState,
  action: AppointmentRecordingsActions
): AppointmentRecordingsStore => {
  switch (action.type) {
    case GET_APPOINTMENT_RECORDINGS: {
      const { payload, status } = action as GetAppointmentRecordingsAction;

      return {
        ...state,
        loading: status === ActionStatus.loading,
        error: status === ActionStatus.error,
        ...(status === ActionStatus.success ? { recordings: payload } : {})
      };
    }
    case DELETE_APPOINTMENT_RECORDING: {
      const { payload, status } = action as DeleteAppointmentRecordingAction;

      return {
        ...state,
        loading: status === ActionStatus.loading,
        error: status === ActionStatus.error,
        recordings: state.recordings.filter((r) => r.id !== payload?.recordingFileId)
      };
    }
    default:
      return state;
  }
};
