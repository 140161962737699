/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";
import TextCard from "./TextCard";
import { Choice, Icon, SkipButton } from "..";
import Button from "../Button/Button";
import moment from "moment";
import { ChatCardTypes } from "../../../constants";

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

interface SelectCardProps {
  msg: Message;
  index: number;
  allowMulti: boolean;
  isCurrentMessage: boolean;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
  editMode?: boolean;
  save?: (userResponse: string) => Promise<void>;
}
type Option = string | { text: string; value: string };

// TS union type error https://github.com/microsoft/TypeScript/issues/33591#issuecomment-764469590
type MultiOptions = any; // Array<Option>

interface SelectCardState {
  userResponse:
    | string
    | string[]
    | {
        text: string;
        value: string;
      }
    | {
        text: string;
        value: string;
      }[];
  userResponseToDisplay: string | string[];
}

const optionsRestrictedToOne = [
  "none of the above.",
  "none of the above",
  "none.",
  "none",
  `i'm not sure`
];
const extractOptionValue = (
  option:
    | string
    | {
        text: string;
        value: string;
      }
) => {
  return typeof option === "string" ? option : option.value;
};
export default class SelectCard extends React.Component<SelectCardProps, SelectCardState> {
  constructor(props: SelectCardProps) {
    super(props);
    this.state = {
      userResponse: this.props.allowMulti ? [] : "",
      userResponseToDisplay: this.props.allowMulti ? [] : ""
    };
    this.selectOption = this.selectOption.bind(this);
    this.submit = this.submit.bind(this);
  }

  selectOption(
    optionSelectedToDisplay: string,
    optionSelected: string | { text: string; value: string }
  ): void {
    const { allowMulti } = this.props;

    if (allowMulti) {
      return this.selectMulti(optionSelected);
    }
    return this.selectSingle(optionSelected);
  }

  selectSingle(optionSelected: Option): void {
    const selectedValue: string = extractOptionValue(optionSelected);

    this.setState({
      userResponseToDisplay: [selectedValue],
      userResponse: optionSelected
    });
  }

  selectMulti(optionSelected: Option): void {
    const selectedValue: string = extractOptionValue(optionSelected);

    this.setState((state): SelectCardState => {
      const { userResponse } = state;
      if (Array.isArray(userResponse)) {
        if (selectedValue && optionsRestrictedToOne.includes(selectedValue.toLowerCase())) {
          return {
            ...state,
            userResponseToDisplay: [selectedValue],
            userResponse: [optionSelected] as MultiOptions
          };
        }

        const userResponseContainsSelection = (userResponse as MultiOptions).find(
          (option: Option) => {
            const optionValue = extractOptionValue(option);
            return optionValue === selectedValue || (!optionValue && !selectedValue);
          }
        );

        const updateSelection = userResponseContainsSelection
          ? (userResponse as MultiOptions).filter((option: Option) => {
              const optionValue = extractOptionValue(option);

              return optionValue !== selectedValue;
            })
          : [...userResponse, optionSelected];

        const filteredSelection = updateSelection.filter((option: Option) => {
          const optionValue = extractOptionValue(option);
          const isConstrainedValue = optionsRestrictedToOne.includes(optionValue.toLowerCase());

          return !isConstrainedValue;
        });

        const updateSelectionValues = (filteredSelection as MultiOptions).map((option: Option) => {
          return extractOptionValue(option);
        });

        return {
          ...state,
          userResponseToDisplay: updateSelectionValues,
          userResponse: filteredSelection
        };
      }
      return state;
    });
  }

  submit(): void {
    const { userResponse } = this.state;
    this.props.fetchNextMessage({
      lastReceivedMessageId: null,
      userResponse,
      userResponseType: this.props.allowMulti
        ? ChatCardTypes.MULTI_SELECT
        : ChatCardTypes.SINGLE_SELECT,
      chatFlowId: null,
      showUserResponse: true,
      createdAt: moment().toDate()
    });
  }

  render(): JSX.Element {
    const { msg, index, isCurrentMessage, allowMulti, editMode, save } = this.props;
    const { userResponse, userResponseToDisplay } = this.state;
    // TODO: Make custom card PayloadOptions type
    const payloadOptions = msg?.payloadOptions as BasePayloadOptions;
    const skippable = payloadOptions?.skippable || false;

    const answers: Array<string | { text: string; value: string }> =
      payloadOptions && payloadOptions.answers ? payloadOptions.answers : [];

    const style = css`
      margin-bottom: 32px;
    `;

    return (
      <StyledComponent {...this.props} css={style}>
        <TextCard
          msg={msg}
          index={index}
          avatar={<Icon name="logo" iconColor="reset" size="18px" />}
          mb="16px"
        />
        {isCurrentMessage &&
          answers.map((option) => {
            const isActive = allowMulti
              ? !!(userResponse as Array<Option>).find(
                  (selectedOption) =>
                    extractOptionValue(selectedOption) === extractOptionValue(option)
                )
              : extractOptionValue(userResponse as Option) === extractOptionValue(option);
            const id =
              typeof option === "string"
                ? `${option}--SelectChoice`
                : `${option.text.replace(" ", "-")}--SelectChoice`;
            return (
              <Choice
                id={id}
                key={id}
                option={option}
                mb="8px"
                selectOption={this.selectOption}
                active={isActive}
                multiple={allowMulti}
              />
            );
          })}
        {isCurrentMessage && !editMode && (
          <Button
            id={`submitBtn-${index}`}
            mt="8px"
            disabled={!userResponseToDisplay.length}
            variant="primary"
            onClick={this.submit}
            disableOnClick
          >
            Send
          </Button>
        )}
        {isCurrentMessage && save && (
          <Button
            id={`saveBtn-${index}`}
            onClick={() => save(JSON.stringify(userResponseToDisplay))}
            mb="40px"
            disabled={!userResponse}
            disableOnClick
          >
            Send
          </Button>
        )}
        {isCurrentMessage && !editMode && skippable && <SkipButton index={index} message={msg} />}
      </StyledComponent>
    );
  }
}
