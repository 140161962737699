/** @jsxImportSource @emotion/react */
import React from "react";
import { connect } from "react-redux";

import { jsx, css } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

import { Box } from "../../../layout";
import { Card, Text, Button } from "../..";
import JsonContentRenderEngine from "../../../JsonContentRenderEngine";

import { fetchNextMessage as fetchNextMessageAction } from "../../../../actions";

import { ChatFlowTags } from "../../../../constants";
import getChatType from "../../../../utils/getChatType";
import { getDefaultLandingMessage } from "./defaultLandingMessages";

const { APPOINTMENT_DETAILS, SECURE_DIRECT_MESSAGE } = ChatFlowTags;

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

const style = css`
  .ConfirmRescheduleGroup {
    display: flex;
    flex-flow: row nowrap;
  }

  .ButtonWrapper {
    flex-grow: 1;
    width: 1fr;
    margin-left: 8px;
    margin-right: 8px;
  }

  .ButtonWrapper:first-of-type {
    margin-left: 0px;
  }

  .ButtonWrapper:last-of-type {
    margin-right: 0px;
  }
`;

interface Props {
  chatInfo?: ChatInfo;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
  // toggleDisplayLanding: (displayLanding: boolean) => void;
}
const getStartChatLabel = (isConversationComplete: boolean, chatType?: string) => {
  if (isConversationComplete) {
    return "Completed";
  }

  return chatType === SECURE_DIRECT_MESSAGE ? "View Message" : "Get started";
};

const InfoTasksCardDeprecated: React.FC<Props & StyledProps> = ({
  chatInfo,
  fetchNextMessage
}: Props) => {
  const {
    isConversationComplete,
    organizationName,
    landingMessage: chatInfoLandingMessage,
    chatFlowTags
  } = chatInfo as ChatInfo;

  const chatType = getChatType(chatFlowTags) || undefined;

  const landingMessage =
    chatInfoLandingMessage ||
    (chatType && (getDefaultLandingMessage(organizationName, chatType) as Message));
  // TODO: Make custom card PayloadOptions type
  const contentJSON =
    landingMessage &&
    landingMessage.payloadOptions &&
    (landingMessage.payloadOptions as BasePayloadOptions).contentJson
      ? (landingMessage.payloadOptions as BasePayloadOptions).contentJson
      : null;

  let header;
  let paragraphs: string[] = [];

  if (!contentJSON) {
    const content =
      landingMessage && typeof landingMessage.payloadContent === "string"
        ? landingMessage.payloadContent
        : "";

    if (content) {
      try {
        const parsedContent = JSON.parse(content);
        header = parsedContent.header || header;
        paragraphs = parsedContent.paragraphs || paragraphs;
      } catch (error) {
        console.error("Failed to parse landing card content", error);
      }
    }
  }

  const showDeprecatedContent = Boolean(header || (paragraphs && paragraphs.length));
  const showContentJSON = Boolean(contentJSON);
  const showCallToAction = !chatFlowTags.includes(APPOINTMENT_DETAILS);

  if (!showDeprecatedContent && !showContentJSON && !showCallToAction) return null;

  return (
    <StyledComponent css={style}>
      <Card p="16px 16px 4px" mb="24px" boxShadow>
        {showDeprecatedContent && (
          <>
            {/* DEPRECATED Heading */}
            {header && (
              <Text variant="titleSmall" bold pb="16px">
                {header}
              </Text>
            )}
            {/* DEPRECATED Paragraphs */}
            {paragraphs && paragraphs.length > 0 && (
              <Box width="100%" pb="16px">
                <Text variant="small">
                  {paragraphs.map((paragraph, i) => {
                    // eslint-disable-next-line react/no-array-index-key
                    return <p key={i}>{paragraph}</p>;
                  })}
                </Text>
              </Box>
            )}
          </>
        )}

        {/* Landing Card Content JSON */}
        {showContentJSON && (
          <JsonContentRenderEngine
            content={contentJSON}
            contentBoxShadow={false}
            contentPadding="0px"
            contentMargin="0px"
          />
        )}

        {/* Start Chat Call-To-Action */}
        {showCallToAction && (
          <Box width="100%" p="16px 0px">
            <Button
              id="landingPageBtn"
              variant={isConversationComplete ? "success" : undefined}
              disabled={isConversationComplete}
              onClick={() => {
                if (chatInfo?.conversationId && chatInfo?.landingMessage?.id) {
                  fetchNextMessage({
                    messageTemplateId: chatInfo?.landingMessage?.id,
                    userResponse: null,
                    sessionId: chatInfo?.sessionId,

                    lastReceivedMessageId: null, // TODO: can we remove this from required FetchNextMessageParams?
                    chatFlowId: null // TODO: can we remove this from required FetchNextMessageParams?
                  });
                }
              }}
            >
              {getStartChatLabel(isConversationComplete, chatType)}
            </Button>
          </Box>
        )}
      </Card>
    </StyledComponent>
  );
};

export default connect(null, { fetchNextMessage: fetchNextMessageAction })(InfoTasksCardDeprecated);
