/** @jsxImportSource @emotion/react */
import React from "react";

import { css, jsx } from "@emotion/react";
import { SpaceProps, LayoutProps } from "styled-system";

import { Box, Scroll } from "../../../layout";
import { Button, Drawer, Text, Link } from "../..";
import SelectInput from "../../Fields/SelectInput";

type StyledProps = SpaceProps & LayoutProps;

interface PropsType {
  open: boolean;
  hideLocationOptions: boolean;
  hidePractitionerOptions: boolean;
  bookingReasonLabel: string;
  locationOptions: Array<any>;
  practitionerOptions: Array<any>;
  selectedPractitioner: Option | null;
  selectedLocation: Option | null;
  toggleModal: () => void;
  toggleSearchModal: () => void;
  setSelectedLocation: (selection: Option | null) => void;
  setSelectedPractitioner: (selection: Option | null) => void;
}

const style = css`
  z-index: 100;
  position: fixed;
  display: flex;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  max-width: 600px;
  margin: 0 auto;
  overflow: hidden;

  .contentWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    min-height: 100vh;
  }

  .modalHeader {
    height: 72px;
    display: flex;
    align-items: center;
  }

  .footer {
    display: flex;
    align-items: end;
    width: 100%;
    max-width: 600px;
    align-self: end;
    flex-grow: 1;
  }

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices - Fix for hidden content under message header */
    .infoModalHeader {
      margin-top: 72px;
    }
  }
`;

const FiltersModal = ({
  open,
  hidePractitionerOptions,
  practitionerOptions,
  setSelectedPractitioner,
  selectedPractitioner,
  hideLocationOptions,
  locationOptions,
  setSelectedLocation,
  selectedLocation,
  bookingReasonLabel,
  toggleModal,
  toggleSearchModal
}: PropsType) => {
  const checkAvailabilityReady =
    (hideLocationOptions || (!hideLocationOptions && selectedLocation !== null)) &&
    (hidePractitionerOptions || (!hidePractitionerOptions && selectedPractitioner !== null));

  if (!open) return null;

  return (
    <Drawer css={style}>
      <Scroll>
        <Box p="0px 24px 80px 24px" className="contentWrapper">
          <header className="modalHeader">
            <Link variant="back" onClick={toggleModal}>
              Back to chat
            </Link>
          </header>
          <Text variant="titleMedium" mb="24px">
            Book your appointment
          </Text>
          <Text variant="meta" mb="12px">
            Reason
          </Text>
          <Text mb="16px">{bookingReasonLabel}</Text>
          {!hideLocationOptions && (
            <SelectInput
              label="Location"
              options={locationOptions}
              value={selectedLocation}
              onChange={(selection) => {
                setSelectedLocation(selection);
              }}
            />
          )}
          {!hidePractitionerOptions && (
            <SelectInput
              label="Provider"
              options={practitionerOptions}
              value={selectedPractitioner}
              onChange={setSelectedPractitioner}
            />
          )}

          <div className="footer">
            <Button onClick={toggleSearchModal} disabled={!checkAvailabilityReady}>
              Check Availability
            </Button>
          </div>
        </Box>
      </Scroll>
    </Drawer>
  );
};

export default FiltersModal;
