/* Main
======================================= */

import React from "react";
import styled from "@emotion/styled";
import {
  compose,
  space,
  layout,
  flexbox,
  color,
  SpaceProps,
  LayoutProps,
  FlexboxProps,
  BackgroundColorProps
} from "styled-system";
import filterProps from "../../../utils/filterProps";

type StyledProps = SpaceProps & LayoutProps & FlexboxProps & BackgroundColorProps;

const StyledContainer = styled("main", {
  shouldForwardProp: (prop) => filterProps(prop as string)
})<StyledProps>(compose(space, layout, flexbox, color));

const Main: React.FC<StyledProps> = ({
  display = "flex",
  alignItems = "center",
  justifyContent = "center",
  flexDirection = "column",
  width = "100%",
  height = "100%",
  minHeight = "100vh",
  overflow = "hidden",
  bg = "grey.3",
  ...otherProps
}) => {
  const props = {
    display,
    alignItems,
    justifyContent,
    flexDirection,
    width,
    height,
    minHeight,
    overflow,
    bg,
    ...otherProps
  };
  return <StyledContainer {...props}>{props.children}</StyledContainer>;
};

Main.displayName = "Main";

export default Main;
