import React from "react";
import styled from "@emotion/styled";

import { Button, Card, Modal, Text } from "../../../ui";
import PaperclipIcon from "../../Icons/PaperclipIcon";
import DownloadIcon from "../../Icons/DownloadIcon";
import DeleteIcon from "../../Icons/DeleteIcon";
import IconButton from "../../Icons/IconButton";

const Wrapper = styled(Card)`
  display: flex;
  padding: 0.5rem;
  border-radius: 10px;
  margin: 1rem 0;
  align-items: center;
  justify-content: space-between;
  box-shadow: none;
  background-color: white;
  &:hover {
    background-color: lightgray;
  }
`;

const Item = styled.div`
  display: flex;
  padding: 0.1rem;
  white-space: pre-wrap;
`;
const IconWrapper = styled.div`
  display: flex;
  padding: 0.2rem;
`;

const ModalContent = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  .button-wrapper {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

type Props = {
  item: SecureMessageItem;
  onDelete: (id: number) => void;
};

const SecureMessageFileItem: React.FC<Props> = ({ item, onDelete }) => {
  const [downloadModalOpen, setDownloadModalOpen] = React.useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);

  const handleDownloadRecordingFile = () => {
    window.open(item.signedS3Url, "_blank")?.focus();
    setDownloadModalOpen(false);
  };

  const handleDeleteRecordingFile = () => {
    onDelete(item.id);
    setDeleteModalOpen(false);
  };

  return (
    <Wrapper>
      {item.signedS3Url && (
        <Item>
          <Item>
            <PaperclipIcon />
          </Item>
        </Item>
      )}

      <Item>
        <Text variant={!item.payloadContent ? "titleSmall" : undefined} bold={!item.payloadContent}>
          {item.payloadContent || item.clinicFileName}
        </Text>
      </Item>
      <Item>
        <IconWrapper>
          {item.signedS3Url && (
            <IconButton
              icon={DownloadIcon}
              onClick={() => setDownloadModalOpen(true)}
              target="_blank"
            />
          )}
          <IconButton icon={DeleteIcon} onClick={() => setDeleteModalOpen(true)} />
        </IconWrapper>
      </Item>
      <Modal showing={deleteModalOpen} setShowing={setDeleteModalOpen}>
        <ModalContent>
          <Text variant="titleSmall" bold>
            Delete your file?
          </Text>
          <Text>Are you sure you want to delete your file? You cannot undo this action.</Text>
          <div className="button-wrapper">
            <Button variant="small" onClick={() => handleDeleteRecordingFile()}>
              Yes, delete file
            </Button>
            <Button variant="text" onClick={() => setDeleteModalOpen(false)}>
              Cancel
            </Button>
          </div>
        </ModalContent>
      </Modal>

      <Modal showing={downloadModalOpen} setShowing={setDownloadModalOpen}>
        <ModalContent>
          <Text variant="titleSmall" bold>
            Caution! Your download may contain sensitive information
          </Text>
          <Text>Only provide access to your downloaded file(s) to people you trust. </Text>
          <Text>Posting this information on websites or social media is not recommended.</Text>
          <div className="button-wrapper">
            <Button variant="small" onClick={() => handleDownloadRecordingFile()}>
              I understand
            </Button>
            <Button variant="text" onClick={() => setDownloadModalOpen(false)}>
              Cancel
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </Wrapper>
  );
};

export default SecureMessageFileItem;
