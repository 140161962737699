/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

import { Box } from "../../../components/layout";
import { Card, Text } from "../../../components/ui";
import JsonContentRenderEngine from "../../../components/JsonContentRenderEngine";
import { JsonContent } from "../../../types";

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

const style = css``;

interface Props {
  location: {
    id: number;
    locationName: string | null;
    locationInformation: JsonContent | null;
    phoneNumber: string | null;
    address: string | null;
  };
}

const MoreDetailsCard: React.FC<Props & StyledProps> = ({ location }: Props) => {
  const address = location?.address?.replace(/,/gim, "").trim() || null; // remove commas
  const phoneNumber = location?.phoneNumber || null; // Remove all non-numeric characters

  if (!address && !phoneNumber && !location?.locationInformation) {
    return null;
  }

  return (
    <StyledComponent css={style}>
      <Card p="16px 16px 4px" mb="24px" boxShadow>
        <Box width="100%" mb="16px">
          <Text variant="titleSmall" bold mb="8px">
            More Details
          </Text>
        </Box>
        {/* For clinics that have no location number do not show the contact box. */}
        {phoneNumber && (
          <Box width="100%" mb="16px">
            <Text variant="meta">Contact</Text>
            <Text>{phoneNumber}</Text>
          </Box>
        )}
        {/* For clinics that have no address do not show the address box. Cases exist where mikata is not provided the appointments location data */}
        {address && (
          <Box width="100%" mb="16px">
            <Text variant="meta">Address</Text>
            <Text>
              {location.locationName}
              <br />
              {address}
            </Text>
          </Box>
        )}
        {location.locationInformation && (
          <Box width="100%" mb="16px">
            <JsonContentRenderEngine
              content={location.locationInformation}
              placeholderContext={{ locationNumber: phoneNumber || "" }}
              contentBoxShadow={false}
              contentPadding="0px"
              showInfoModalButton={false}
            />
          </Box>
        )}
      </Card>
    </StyledComponent>
  );
};

export default MoreDetailsCard;
