import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  clearNotificationOverlay,
  showNotificationOverlay
} from "../actions/notifications-overlay";

import { NotificationTypes } from "../constants";
import { NotificationType } from "../types/notifications";

const useNotificationOverlay = () => {
  const dispatch = useDispatch();
  const notification = useSelector<ReduxState>(
    (state) => state.notificationOverlay
  ) as NotificationOverlayStore;

  const clear = React.useCallback(() => {
    dispatch(clearNotificationOverlay);
  }, []);

  const show = React.useCallback(
    (type: NotificationType = NotificationTypes.GENERIC_APPLICATION_ERROR) => {
      dispatch(showNotificationOverlay(type));
    },
    []
  );

  return { clear, notification, show };
};

export default useNotificationOverlay;
