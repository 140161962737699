/* eslint-disable no-shadow */
export enum JsonContentNodeTypes {
  content = "content",
  heading = "heading",
  text = "text",
  list = "list",
  listItem = "listItem",
  important = "important",
  duration = "duration",
  link = "link",
  infoModal = "infoModal"
}

export interface TextNode {
  id: string;
  type: JsonContentNodeTypes.text;
  properties: { value: string };
  children: undefined;
}

export interface HeadingNode {
  id: string;
  type: JsonContentNodeTypes.heading;
  properties: { value: string; size: "s" | "m" | "l" };
  children: undefined;
}

export interface ImportantNode {
  id: string;
  type: JsonContentNodeTypes.important;
  properties: { title: string; details?: string };
  children: undefined;
}

export interface DurationNode {
  id: string;
  type: JsonContentNodeTypes.duration;
  properties: { duration: string; discomfort: string };
  children: undefined;
}

export interface LinkNode {
  id: string;
  type: JsonContentNodeTypes.link;
  properties: { label: string; url: string };
  children: undefined;
}

export interface InfoModalNode {
  id: string;
  type: JsonContentNodeTypes.infoModal;
  properties: { label: string };
  children: [TextNode | HeadingNode | ListNode] | [];
}

export interface ListItemNode {
  id: string;
  type: JsonContentNodeTypes.listItem;
  properties: undefined;
  children: [TextNode | HeadingNode] | [];
}

export interface ListNode {
  id: string;
  type: JsonContentNodeTypes.list;
  properties: { title: string };
  children: [ListItemNode] | [];
}

export interface ContentNode {
  id: string;
  type: JsonContentNodeTypes.content;
  properties: undefined;
  children: [TextNode | HeadingNode | ListNode] | [];
}

export type JsonContentNode =
  | ContentNode
  | TextNode
  | HeadingNode
  | ListNode
  | ListItemNode
  | ImportantNode
  | DurationNode
  | LinkNode
  | InfoModalNode;

export type JsonContent = [ContentNode] | [];
