/* Viewport
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css, useTheme } from "@emotion/react";

const Viewport: React.FC = (props) => {
  const theme: any = useTheme();

  // styles
  const wrapperStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    background-color: ${theme.colors.grey[3]};
  `;

  const contentStyle = css`
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    height: 100%;
    max-width: 600px;

    overflow: hidden;
    position: relative;

    background-color: ${theme.colors.grey[1]};
  `;

  // markup

  return (
    <div css={wrapperStyle}>
      <div css={contentStyle}>{props.children}</div>
    </div>
  );
};

Viewport.displayName = "Viewport";

export default Viewport;
