/* Scale Input Card
======================================= */

/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { jsx, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

import TextCard from "./TextCard";
import { Button, Icon, ScaleChoice, SkipButton } from "..";
import moment from "moment";
import { ChatCardTypes } from "../../../constants";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));
const StyledAnswers = styled("div")<StyledProps>(compose(space, layout));

/* Style
--------------------------------------- */

const style = (theme: any) => css`
  display: flex;
  justify-content: space-between;

  background: #fff;
  box-shadow: ${theme.shadows.small};
  border-radius: ${theme.radii.normal};
  padding: 6px;
`;

/* Props
--------------------------------------- */

interface ScaleInputProps {
  msg: Message;
  index: number;
  isCurrentMessage: boolean;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
  editMode?: boolean;
}

/* Component
--------------------------------------- */

const ScaleInputCard: React.FC<ScaleInputProps & StyledProps> = ({
  msg,
  index,
  isCurrentMessage,
  editMode = false,
  fetchNextMessage,
  ...props
}: ScaleInputProps) => {
  const theme = useTheme();
  const [selectedValue, updateSelectedValue] = useState("");

  const payloadOptions = msg?.payloadOptions as ScaleInputPayloadOptions;
  const skippable = payloadOptions?.skippable || false;

  const answers = payloadOptions.answers || [];

  const submit = () => {
    const userResponse = selectedValue
      ? (answers.find((answer) => answer.value === selectedValue) as ScaleInputOption)
      : null;

    fetchNextMessage({
      lastReceivedMessageId: null,
      userResponse,
      userResponseType: ChatCardTypes.SCALE_INPUT,
      chatFlowId: null,
      showUserResponse: true,
      createdAt: moment().toDate()
    });
  };

  const selectOption = (option: ScaleInputOption) => {
    updateSelectedValue(option.value);
  };

  return (
    <StyledComponent>
      <TextCard
        msg={msg}
        index={index}
        avatar={<Icon name="logo" iconColor="reset" size="18px" />}
        mb="16px"
        editMode={editMode}
      />
      {isCurrentMessage && (
        <StyledAnswers css={style(theme)} {...props} mb="20px">
          {answers.map((option) => (
            <ScaleChoice
              key={option.value}
              option={option}
              onClick={() => selectOption(option)}
              isActive={option.value === selectedValue}
            />
          ))}
        </StyledAnswers>
      )}
      {isCurrentMessage && !editMode && (
        <Button onClick={submit} disabled={selectedValue === ""} disableOnClick>
          Send
        </Button>
      )}
      {isCurrentMessage && !editMode && skippable && <SkipButton index={index} message={msg} />}
    </StyledComponent>
  );
};

ScaleInputCard.displayName = "ScaleInputCard";

export default ScaleInputCard;
