/* Map
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css, useTheme, SerializedStyles } from "@emotion/react";
import { motion, useReducedMotion } from "framer-motion";

interface Props {
  className?: string;
  backgroundColor?: string;
  centerContent?: boolean;
  innerStyleCss?: SerializedStyles;
  children: React.ReactNode;
}

const Drawer = ({ className, children, ...props }: Props) => {
  const theme: any = useTheme();
  const shouldReduceMotion = useReducedMotion();

  let panelAnimation = {
    initial: { opacity: 0, x: "100%" },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: "100%" },
    transition: { duration: 0.2 }
  };

  let insideAnimation = {
    initial: { opacity: 0, x: "100%" },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: "100%" },
    transition: { type: "spring", stiffness: 300, damping: 25, delay: 0.1 }
  };

  if (shouldReduceMotion) {
    panelAnimation = theme.animation.reduced;
    insideAnimation = theme.animation.reduced;
  }

  const style = css`
    background-color: ${props.backgroundColor ? props.backgroundColor : "#fff"};

    ${props.centerContent && "justify-content: center"}
  `;

  const innerStyle = css`
    display: flex;
    width: 100%;
    ${props.innerStyleCss}
  `;

  return (
    <motion.section className={className} css={style} {...props} {...panelAnimation}>
      <motion.div css={innerStyle} {...insideAnimation}>
        {children}
      </motion.div>
    </motion.section>
  );
};

Drawer.displayName = "Drawer";

export default Drawer;
