/* Header
======================================= */
/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css, useTheme } from "@emotion/react";
import { Icon, Text } from "../../components/ui";
import { ChatFlowTags } from "../../constants";

interface Props {
  chatInfo: ChatInfo;
  endChat: () => void;
}
const Header: React.FC<Props> = (props) => {
  const theme: any = useTheme();

  // styles
  const style = css`
    background-color: #fff;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.04);
    width: 100%;
    height: 72px;
    padding: 0px 12px 0 24px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    user-select: none;
    .clinic {
      display: flex;
      align-items: center;
    }
    .inside {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .backIcon {
      width: 40px;
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .backIcon:focus {
      outline: none;
    }
  `;

  const { chatInfo, endChat } = props;
  const { locations, chatFlowTags, date, time } = chatInfo;
  const apptRequestChat = chatFlowTags.includes(ChatFlowTags.APPOINTMENT_REQUEST);
  const previsitChat = chatFlowTags.includes(ChatFlowTags.PREVISIT);
  const isAppointmentTimeHidden = chatFlowTags.includes(ChatFlowTags.HIDE_APPOINTMENT_TIMES);

  const hasTime = !!time;
  const hasDate = !!date;

  const locationName = locations?.length === 1 ? locations[0].locationName : "";

  const { appointmentRequestHeaderTitle } = chatInfo?.appearanceSettings?.settingValue || {};

  return (
    <div css={style}>
      <div className="inside">
        <div className="clinic">
          <Icon name="headerIcon" mr="16px" iconColor="icon.primary" />
          <div className="info">
            {apptRequestChat && (
              <>
                {appointmentRequestHeaderTitle !== undefined && (
                  <Text variant="small" mb="1px" bold>
                    {appointmentRequestHeaderTitle}
                  </Text>
                )}
                {appointmentRequestHeaderTitle === undefined && (
                  <>
                    <Text variant="small" mb="1px" bold>
                      {theme.apptReqHeaderText}
                    </Text>
                    <Text variant="small" mr="24px">
                      {theme.apptReqHeaderSubtext}
                    </Text>
                  </>
                )}
              </>
            )}
            {previsitChat && (
              <>
                <Text variant="small" mb="1px" bold>
                  {locationName}
                </Text>
                {!isAppointmentTimeHidden && hasTime && hasDate && (
                  <Text variant="small">{`${date} at ${time}`}</Text>
                )}
                {!isAppointmentTimeHidden && hasTime && !hasDate && (
                  <Text variant="small">{`${time}`}</Text>
                )}
                {!isAppointmentTimeHidden && !hasTime && hasDate && (
                  <Text variant="small">{`${date}`}</Text>
                )}
              </>
            )}
          </div>
        </div>
        <button type="button" className="backIcon" onClick={endChat}>
          <Text id="endChat" variant="xsmall">
            End Chat
          </Text>
        </button>
      </div>
    </div>
  );
};
Header.displayName = "Header";
export default Header;
