import axios, { AxiosError } from "axios";
import { ActionStatus } from "../types";

import {
  ROOT_URL,
  FETCH_INSTRUCTIONS_INFO,
  FETCH_CHAT_MESSAGE_INFO,
  FETCH_CHAT_INFO,
  NotificationTypes,
  UPDATE_APPT_INFO,
  AppointmentStates,
  INIT_CONVERSATION_HUB,
  SEND_REFRESH_LINK
} from "../constants";

import { clearChatMessages, startChatSession } from "./chatMessages";
import { showNotificationOverlay } from "./notifications-overlay";
import { Dispatch, InitConversationHubAction } from "../types/actions";
import { NotificationType } from "../types/notifications";

export const fetchChatInfo =
  (token: string, conversationId: number) => async (dispatch: Dispatch) => {
    // TODO:remove "token" function argument
    const sessionToken = sessionStorage.getItem("token");

    const config = {
      headers: { Authorization: sessionToken, "Content-Type": "application/json" }
    };

    try {
      const payload = await axios.get(
        `${ROOT_URL}/conversations/${conversationId}/get-first-message`,
        config
      );
      clearChatMessages()(dispatch);

      startChatSession({ conversationId, sessionId: payload?.data?.sessionId })(dispatch);

      return dispatch({
        type: FETCH_CHAT_INFO,
        payload
      });
    } catch (e) {
      return dispatch(showNotificationOverlay());
    }
  };

export const fetchChatMessageInfo =
  (
    token: string,
    conversationId: number,
    params: ChatMessageInfoActionData,
    onResponse?: (response: any) => void
  ) =>
  async (dispatch: Dispatch) => {
    // TODO:remove "token" function argument
    const sessionToken = sessionStorage.getItem("token");

    const config = {
      headers: { Authorization: sessionToken, "Content-Type": "application/json" }
    };

    try {
      const payload = await axios.post(
        `${ROOT_URL}/conversations/${conversationId}/get-next-message`,
        params,
        config
      );

      if (onResponse) {
        onResponse(payload);
      }
      return dispatch({
        type: FETCH_CHAT_MESSAGE_INFO,
        payload
      });
    } catch (err) {
      const error = err as AxiosError;
      let errorType: NotificationType = NotificationTypes.GENERIC_APPLICATION_ERROR;

      if (error.response?.data.type === "PATIENT_VERIFICATION_MAX_AUTH_ATTEMPTS") {
        errorType = NotificationTypes.ACCESS_BLOCKED;
      }

      return dispatch(showNotificationOverlay(errorType));
    }
  };

export interface UpdateAppointmentStatusActionData {
  id: number;
  status?: typeof AppointmentStates.RESCHEDULE | typeof AppointmentStates.CONFIRMED;
  checkedIn?: boolean;
}

export type UpdateAppointmentStatusActionOptions = {
  onSuccess?: () => void;
};

export const updateAppointmentStatus =
  (
    token: string,
    data: UpdateAppointmentStatusActionData,
    options: UpdateAppointmentStatusActionOptions = {}
  ) =>
  async (dispatch: Dispatch) => {
    // TODO:remove "token" function argument
    const sessionToken = sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: sessionToken, "Content-Type": "application/json" }
    };

    const { status: appointmentStatus, checkedIn } = data;
    const { onSuccess } = options;
    dispatch({
      type: UPDATE_APPT_INFO,
      status: ActionStatus.loading,
      payload: { appointmentStatus, checkedIn }
    });

    try {
      const payload = await axios.patch(
        `${ROOT_URL}/conversations/appointments/${data.id}`,
        { ...data, id: undefined },
        config
      );

      if (onSuccess) {
        onSuccess();
      }

      return dispatch({
        type: UPDATE_APPT_INFO,
        status: ActionStatus.success,
        payload: { ...payload, appointmentStatus }
      });
    } catch (e) {
      dispatch({
        type: UPDATE_APPT_INFO,
        status: ActionStatus.error,
        payload: { appointmentStatus, checkedIn }
      });
      return dispatch(showNotificationOverlay());
    }
  };

export const fetchApptInstructions =
  (token: string, params: ChecklistInfoActionData) =>
  async (dispatch: Dispatch, getState: () => ReduxState) => {
    // TODO:remove "token" function argument
    const sessionToken = sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: sessionToken, "Content-Type": "application/json" }
    };
    const { chatMessages } = getState();
    const { conversationId } = chatMessages;

    const payload = await axios.get(
      `${ROOT_URL}/conversations/${conversationId}/checklists/${params.checklistId}`,
      config
    );
    return dispatch({
      type: FETCH_INSTRUCTIONS_INFO,
      payload
    });
  };

export interface InitConversationHubActionData {
  shortCode: string;
  draftChat?: boolean;
}

export const initConversationHub =
  (params: InitConversationHubActionData) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: INIT_CONVERSATION_HUB,
        payload: { data: { initialized: false } }
      } as InitConversationHubAction);

      const shortCode = params?.shortCode?.replaceAll(".", "");

      const payload = await axios.post(
        `${ROOT_URL}/conversations/init`,
        { ...params, shortCode },
        {
          headers: { "Content-Type": "application/json" }
        }
      );

      clearChatMessages()(dispatch);

      sessionStorage.setItem("token", payload.data.sessionToken || "");

      return dispatch({
        type: INIT_CONVERSATION_HUB,
        payload
      });
    } catch (error: any) {
      const errorStatusCode = (error as any)?.response?.status;
      const data = (error as any)?.response?.data || {};

      if (errorStatusCode === 401) {
        const { isEligibleForRefresh, linkResent } = data;
        if (isEligibleForRefresh) {
          return dispatch(showNotificationOverlay(NotificationTypes.LINK_EXPIRED_CAN_REFRESH));
        }
        if (linkResent) {
          return dispatch(showNotificationOverlay(NotificationTypes.LINK_RESENT));
        }
        return dispatch(showNotificationOverlay(NotificationTypes.LINK_EXPIRED));
      }

      return dispatch(showNotificationOverlay());
    }
  };
