/* SelectionEntry
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps, ResponsiveValue } from "styled-system";

import CSS from "csstype";

import { Icon, Text } from "../../../ui";
import { AllThemeIconTypes } from "../../../../utils/getThemeIcons";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

/* Style
--------------------------------------- */

const style = (theme: any) => css`
  display: flex;
`;

/* Props
--------------------------------------- */

interface Props {
  icon?: AllThemeIconTypes;
  iconColor?: ResponsiveValue<CSS.Property.Color>;
  bold?: boolean;
}

/* Component
--------------------------------------- */

const SelectionEntry: React.FC<Props & StyledProps> = (props) => {
  const theme = useTheme();

  const icon = props.icon ? <Icon name={props.icon} iconColor={props.iconColor} mr="9px" /> : null;

  return (
    <StyledComponent css={style(theme)} {...props} className="selection-entry">
      {icon}
      <Text bold={props.bold}>{props.children}</Text>
    </StyledComponent>
  );
};

SelectionEntry.displayName = "SelectionEntry";

export default SelectionEntry;
