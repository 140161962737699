/* eslint-disable react/destructuring-assignment */
import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import jwtDecode from "jwt-decode";
import { withTheme } from "@emotion/react";
import { AnimatePresence } from "framer-motion";

import { NotificationTypes } from "../../constants";

import { ChatSessionManagerContext } from "../../components/providers/ChatSessionManager";
import Messages from "./Messages";
import ChatWrapper from "./ChatWrapper";
import Header from "./Header";
import ConversationHub from "./ConversationHub";
import { NotificationOverlay } from "../../components/ui";
import { NotificationType } from "../../types/notifications";

import {
  fetchChatInfo,
  fetchChatMessageInfo,
  showNotificationOverlay,
  fetchNextMessage as fetchNextMessageAction,
  undoUserResponse as undoUserResponseAction,
  UndoUserResponseData
} from "../../actions";

interface MatchParams {
  shortCode?: string;
}

interface ChatContainerProps extends RouteComponentProps<MatchParams> {
  fetchNextMessageAction: (params: FetchNextMessageParams) => void;
  undoUserResponse: (data: UndoUserResponseData) => void;
  showNotificationOverlay: (type: NotificationType) => void;
  chatInfo: ChatInfo;
  chatMessages: Message[];
  chatMessageLoading: boolean;
  conversationId: number;
}

const canUndo = (msg: Message, messages: Message[]) => {
  const userMessages = messages.filter((msg) => msg.sender !== "mikata");
  return userMessages[userMessages.length - 1]
    ? userMessages[userMessages.length - 1].messageTemplateId === msg.messageTemplateId
    : false;
};

const ChatContainer = (props: ChatContainerProps) => {
  const { sessionToken, endChatSession } = useContext(ChatSessionManagerContext);
  useEffect(() => {
    if (sessionToken) {
      const decodedToken: DecodedToken = jwtDecode(sessionToken);

      const numericDate: number = +new Date() / 1000;
      if (decodedToken.exp - numericDate < 0) {
        props.showNotificationOverlay(NotificationTypes.SESSION_EXPIRED);
      }
    }
  }, [sessionToken]);

  const endChat = () => {
    endChatSession();
  };

  const fetchNextMessage = (params: FetchNextMessageParams) => {
    if (props.fetchNextMessageAction) {
      props.fetchNextMessageAction(params);
    }
  };

  const undoLastAnswer = async (messageTemplateId: number) => {
    const { undoUserResponse } = props;

    return undoUserResponse({ messageTemplateId });
  };

  const renderConversationHub = () => {
    return <ConversationHub key="landingPage" token={sessionToken} />;
  };

  const renderMessages = () => {
    const { chatInfo, chatMessages, chatMessageLoading, conversationId } = props;
    return (
      <ChatWrapper header={<Header chatInfo={chatInfo} endChat={endChat} />}>
        <Messages
          key="messagesPage"
          messages={chatMessages}
          token={sessionToken}
          fetchNextMessage={fetchNextMessage}
          undoLastAnswer={undoLastAnswer}
          canUndo={canUndo}
          fetchingMessage={chatMessageLoading}
          chatInfo={chatInfo}
          conversationId={conversationId}
          endChat={endChat}
        />
      </ChatWrapper>
    );
  };

  const { chatMessages, chatMessageLoading } = props;
  const showMessages = chatMessageLoading || chatMessages?.length > 0;

  return (
    <AnimatePresence>{showMessages ? renderMessages() : renderConversationHub()}</AnimatePresence>
  );
};

function mapStateToProps(state: ReduxState) {
  return {
    chatInfo: state.chatInfo,
    chatMessageInfo: state.chatMessageInfo,
    checklistInfo: state.checklistInfo,
    chatMessages: state.chatMessages.messages,
    chatMessageLoading: state.chatMessages.loading,
    conversationId: state.chatMessages.conversationId
  };
}

export default withTheme(
  connect(mapStateToProps, {
    fetchChatInfo,
    fetchChatMessageInfo,
    showNotificationOverlay,
    fetchNextMessageAction,
    undoUserResponse: undoUserResponseAction
  })(ChatContainer)
);
