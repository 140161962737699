/* externalScripts
======================================= */

export const loadScript = (url: string, callback: () => void) => {
  const script: any = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.defer = true;

  if (script.readyState) {
    script.onreadystatechange = () => {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};
