/* Units Input Card
======================================= */

/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";
import TextCard from "./TextCard";
import { Button, Icon, UnitsInput, SkipButton } from "..";
import moment from "moment";
import { ChatCardTypes } from "../../../constants";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("form")<StyledProps>(compose(space, layout));

/* Props
--------------------------------------- */

interface UnitsInputProps {
  msg: Message;
  index: number;
  isCurrentMessage: boolean;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
  editMode?: boolean;
  save?: (userResponse: string) => Promise<void>;
}

/* Component
--------------------------------------- */
const UnitsInputCard: React.FC<UnitsInputProps> = (props: UnitsInputProps) => {
  const { msg, index, isCurrentMessage, editMode = false, fetchNextMessage, save } = props;

  // TODO: Make custom card PayloadOptions type
  const payloadOptions = msg.payloadOptions as BasePayloadOptions;

  const skippable = payloadOptions?.skippable || false;
  const units = payloadOptions?.units || [];
  const maxDigits = payloadOptions?.maxDigits || undefined;

  const [numberResponse, setNumberResponse] = useState("");
  const [selectedUnit, setSelectedUnit] = useState(units.length > 0 ? units[0] : "");

  const submit = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    const userResponse = `${numberResponse} ${selectedUnit}`;

    if (save) {
      return save(userResponse);
    }

    return fetchNextMessage({
      lastReceivedMessageId: null,
      userResponse,
      userResponseType: ChatCardTypes.UNITS_INPUT,
      chatFlowId: null,
      showUserResponse: true,
      createdAt: moment().toDate()
    });
  };

  const submitDisabled = !numberResponse || !selectedUnit;

  return (
    <StyledComponent>
      <TextCard
        msg={msg}
        index={index}
        avatar={<Icon name="logo" iconColor="reset" size="18px" />}
        mb="16px"
        editMode={editMode}
      />
      {isCurrentMessage && (
        <UnitsInput
          numberResponse={numberResponse}
          units={units}
          setNumberResponse={setNumberResponse}
          setSelectedUnit={setSelectedUnit}
          maxDigits={maxDigits}
          mb="20px"
        />
      )}
      {isCurrentMessage && (
        <Button type="submit" onClick={submit} disabled={submitDisabled} disableOnClick>
          Send
        </Button>
      )}
      {isCurrentMessage && !editMode && skippable && <SkipButton index={index} message={msg} />}
    </StyledComponent>
  );
};

export default UnitsInputCard;
