/* Text Card
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";
import TextCard from "./TextCard";
import { Input, Button, Icon, SkipButton } from "..";
import moment from "moment";

import { ChatCardTypes } from "../../../constants";
import { NumberInputFormat } from "../../../types";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("form")<StyledProps>(compose(space, layout));

/* Props
--------------------------------------- */

interface NumberInputProps {
  msg: Message;
  index: number;
  isCurrentMessage: boolean;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
  editMode?: boolean;
  save?: (userResponse: string) => Promise<void>;
}

interface NumberInputState {
  userResponse: string;
}

const getInputConfiguration = (
  inputFormat?: NumberInputFormat
): { type?: "text" | "number"; inputMode?: "numeric"; pattern?: string } => {
  switch (inputFormat) {
    case NumberInputFormat.PERSONAL_HEALTH_NUMBER: {
      return { type: "text", inputMode: "numeric", pattern: "[0-9]*" };
    }
    case NumberInputFormat.NUMBER: {
      return { type: "number", inputMode: undefined, pattern: "[0-9]*" };
    }
    default: {
      return { type: "number", inputMode: undefined, pattern: "[0-9]*" };
    }
  }
};

/* Component
--------------------------------------- */

class NumberInputCard extends React.Component<NumberInputProps, NumberInputState> {
  constructor(props: NumberInputProps) {
    super(props);

    this.state = {
      userResponse: ""
    };
  }

  updateResponse = (newUserResponse: string) => {
    const { msg } = this.props;
    // TODO: Make custom card PayloadOptions type
    const payloadOptions = msg?.payloadOptions as BasePayloadOptions;
    const maxDigits = payloadOptions && payloadOptions.maxDigits ? payloadOptions.maxDigits : null;
    if (maxDigits && newUserResponse.length <= maxDigits) {
      this.setState({ userResponse: newUserResponse });
    } else if (!maxDigits) {
      this.setState({ userResponse: newUserResponse });
    }
  };

  submit = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    const { userResponse } = this.state;
    const { fetchNextMessage, save } = this.props;

    if (save) {
      return save(userResponse);
    }

    return fetchNextMessage({
      lastReceivedMessageId: null,
      userResponse,
      userResponseType: ChatCardTypes.NUMBER_INPUT,
      chatFlowId: null,
      showUserResponse: true,
      createdAt: moment().toDate()
    });
  };

  render() {
    const { msg, index, isCurrentMessage, editMode } = this.props;
    const { userResponse } = this.state;
    // TODO: Make custom card PayloadOptions type
    const payloadOptions = msg?.payloadOptions as BasePayloadOptions;
    const skippable = payloadOptions?.skippable || false;
    const { type, inputMode, pattern } = getInputConfiguration(
      payloadOptions?.inputFormat as NumberInputFormat
    );

    return (
      <StyledComponent {...this.props}>
        <TextCard
          msg={msg}
          index={index}
          avatar={<Icon name="logo" iconColor="reset" size="18px" />}
          mb="16px"
          editMode={editMode}
        />
        {isCurrentMessage && (
          <Input
            id={`input-${index}`}
            placeholder="Type response"
            maxRows={1}
            name="message"
            mb="20px"
            type={type}
            inputMode={inputMode}
            pattern={pattern}
            updateResponse={this.updateResponse}
            value={userResponse}
          />
        )}
        {isCurrentMessage && (
          <Button
            id={`sendBtn-${index}`}
            type="submit"
            onClick={this.submit}
            disabled={!userResponse}
            disableOnClick
          >
            Send
          </Button>
        )}
        {isCurrentMessage && !editMode && skippable && <SkipButton index={index} message={msg} />}
      </StyledComponent>
    );
  }
}

export default NumberInputCard;
