/* Middle
======================================= */

import React from "react";
import styled from "@emotion/styled";
import {
  compose,
  space,
  layout,
  flexbox,
  SpaceProps,
  LayoutProps,
  FlexboxProps
} from "styled-system";

type StyledProps = SpaceProps & LayoutProps & FlexboxProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout, flexbox));

const Middle: React.FC<StyledProps> = ({
  display = "flex",
  alignItems = "center",
  justifyContent = "center",
  flexDirection = "column",
  ...otherProps
}) => {
  const props = { display, alignItems, justifyContent, flexDirection, ...otherProps };

  return <StyledComponent {...props}>{props.children}</StyledComponent>;
};

Middle.displayName = "Middle";

export default Middle;
