/* ScaleChoice
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

import { Text } from "..";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("button")<StyledProps>(compose(space, layout));

/* Style
--------------------------------------- */

const style = (theme: any) => css`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;

  text-align: center;
  flex-grow: 1;
  padding: 10px 10px 10px 10px;

  .text {
    color: ${theme.colors.grey[3]};
  }

  .subText {
    color: ${theme.colors.grey[3]};
  }

  &:focus,
  &.isActive {
    outline: none;
    .text {
      color: ${theme.colors.grey[5]};
    }

    .subText {
      color: ${theme.colors.grey[5]};
      font-family: ${theme.fonts.bold};
    }
  }

  &.isStatic {
    cursor: default;
    padding: 0 10px;

    .text {
      color: ${theme.colors.grey[4]};
    }

    .subText {
      color: ${theme.colors.grey[4]};
      font-family: ${theme.fonts.bold};
    }
  }
`;

/* Props
--------------------------------------- */

interface Props {
  option?: ScaleInputOption;
  onClick?: (event: React.MouseEvent) => void;
  isStatic?: boolean;
  isActive?: boolean;
}

/* Component
--------------------------------------- */
const ScaleChoice: React.FC<Props & StyledProps> = ({
  option,
  isStatic,
  isActive,
  ...props
}: Props) => {
  const theme = useTheme();
  const classNames = [isStatic ? "isStatic" : "", isActive ? "isActive" : ""].join(" ");
  const { text, subText } = option || {};

  return (
    <StyledComponent css={style(theme)} {...props} className={classNames}>
      <Text variant="titleXLarge" className="text">
        {text}
      </Text>
      <Text variant="xsmall" className="subText">
        {subText}
      </Text>
    </StyledComponent>
  );
};

ScaleChoice.displayName = "ScaleChoice";

export default ScaleChoice;
