import React, { useMemo, useState, useEffect, useContext } from "react";
import moment from "moment";
import styled from "@emotion/styled";

import { Text, Button, Modal } from "../../..";
import AnimationPlayer, { AnimationVariant } from "../../../AnimationPlayer";

import { ChatSessionManagerContext } from "../../../../providers/ChatSessionManager";

import { PatientConsentStatus, RecordingSessionStatus, RecordingState } from "../../../../../types";

import styles from "./index.module.scss";

type StreamingStatusProps = {
  connected: boolean;
  recording: RecordingState | null;
  endScribeSession: () => void;
};

const ModalContent = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  & > * {
    margin-bottom: 1rem;
  }

  .button-wrapper {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  .text-wrapper {
    justify-content: center;
    display: flex;
    align-items: start;
    flex-direction: column;
  }

  .margin-bottom {
    margin-bottom: 12px;
  }
`;

const getStreamingStatusHeading = (recordingSessionStatus?: RecordingSessionStatus) => {
  switch (recordingSessionStatus) {
    case RecordingSessionStatus.RECORDING: {
      return "Mika is active";
    }
    case RecordingSessionStatus.PAUSED: {
      return "Session paused";
    }
    case RecordingSessionStatus.COMPLETE: {
      return "Session complete";
    }
    default: {
      return "You're all set!";
    }
  }
};
const getFooterMessage = (connected: boolean, recording: RecordingState | null) => {
  if (!connected) return "Reconnecting...";

  if (recording?.sessionCompleted) {
    return "Thank you!";
  }

  if (
    recording?.patientConsentStatus === PatientConsentStatus.ACCEPTED &&
    recording.status !== RecordingSessionStatus.RECORDING
  ) {
    return "You can end this chat and return anytime";
  }

  return "";
};

const StreamingStatus = ({ connected, recording, endScribeSession }: StreamingStatusProps) => {
  const [time, setTime] = useState<string>("00:00");
  const [showConfirmEndModal, setShowConfirmEndModal] = React.useState<boolean>(false);
  const { endChatSession } = useContext(ChatSessionManagerContext);

  useEffect(() => {
    if (!recording?.startedAt) {
      return;
    }

    const startTime = moment(recording?.startedAt);

    const updateTimeInterval = setInterval(() => {
      const duration = moment.duration(moment().diff(startTime));
      const minutes = duration.minutes() < 10 ? `0${duration.minutes()}` : duration.minutes();
      const seconds = duration.seconds() < 10 ? `0${duration.seconds()}` : duration.seconds();
      setTime(`${minutes}:${seconds}`);
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(updateTimeInterval);
    };
  }, [recording]);

  const { variant: scribeAnimationVariant, configuration: scribeAnimationConfiguration } =
    useMemo(() => {
      if (!recording) {
        return { variant: undefined, configuration: {} };
      }

      if (recording?.sessionCompleted) {
        return {
          variant: AnimationVariant.SCRIBE_COMPLETED,
          configuration: { loop: false }
        };
      }

      if (!connected) {
        return { variant: AnimationVariant.SCRIBE_RECONNECTING, configuration: { loop: true } };
      }

      if (recording?.status === RecordingSessionStatus.RECORDING) {
        return { variant: AnimationVariant.SCRIBE_STREAMING, configuration: { loop: true } };
      }

      if (recording?.patientConsentStatus === PatientConsentStatus.ACCEPTED) {
        return { variant: AnimationVariant.SCRIBE_WAITING, configuration: { loop: false } };
      }

      return { variant: undefined, configuration: {} };
    }, [connected, recording]);

  if (recording?.patientConsentStatus !== PatientConsentStatus.ACCEPTED) return null;

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Card}>
        <div className={styles.HeaderRow}>
          <Text variant="large" bold>
            {getStreamingStatusHeading(recording?.status)}
          </Text>
          {recording?.status === RecordingSessionStatus.RECORDING && (
            <Text variant="large">{time}</Text>
          )}
        </div>
        <AnimationPlayer
          className={styles.AnimationContainer}
          variant={scribeAnimationVariant}
          configurationOptions={scribeAnimationConfiguration}
        />
        <div className={styles.FooterRow}>
          <Text variant="large" textAlign="center">
            {getFooterMessage(connected, recording)}
          </Text>
          {connected && recording && recording.status === RecordingSessionStatus.RECORDING && (
            <Button onClick={() => setShowConfirmEndModal(true)} variant="secondary" width="100%">
              End Streaming
            </Button>
          )}
        </div>
        <Modal showing={showConfirmEndModal} setShowing={setShowConfirmEndModal}>
          <ModalContent>
            <div className="text-wrapper">
              <Text variant="titleLarge" className="margin-bottom">
                Are you sure you want to end the streaming session?
              </Text>
              <Text className="margin-bottom">
                You will not be able to stream audio any more audio once the session is ended.
              </Text>
              <Text> Click Yes to end the session, or Cancel to continue streaming.</Text>
            </div>

            <div className="button-wrapper">
              <Button
                onClick={() => {
                  setShowConfirmEndModal(false);
                  endScribeSession();
                }}
              >
                Yes, end session
              </Button>

              <Button variant="text" onClick={() => setShowConfirmEndModal(false)}>
                Cancel
              </Button>
            </div>
          </ModalContent>
        </Modal>
      </div>
      {!recording?.sessionCompleted && (
        <Button variant="inline" onClick={endChatSession}>
          Back to hub
        </Button>
      )}
    </div>
  );
};

export default StreamingStatus;
