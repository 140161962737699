/* Text Card
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import styled from "@emotion/styled";
import moment from "moment";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

import { Input, Button, DateSelector } from "..";
import Text from "../Text/Text";
import Card from "./Card";

import { ChatFlowResponseType } from "../../../types";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("form")<StyledProps>(compose(space, layout));

/* Props
--------------------------------------- */

interface PatientVerificationProps {
  msg: Message;
  index: number;
  chatInfo: ChatInfo;
  isCurrentMessage: boolean;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
  editMode?: boolean;
  save?: (userResponse: string) => Promise<void>;
}

interface PatientVerificationState {
  userResponse: PatientVerificationResponse;
}

/* Component
--------------------------------------- */

class PatientVerificationCard extends React.Component<
  PatientVerificationProps,
  PatientVerificationState
> {
  constructor(props: PatientVerificationProps) {
    super(props);

    this.state = {
      userResponse: {
        dateOfBirth: "",
        personalHealthNumber: ""
      }
    };
  }

  updateResponse = (field: "dateOfBirth" | "personalHealthNumber", userResponse: string) => {
    this.setState((state) => ({
      ...state,
      userResponse: {
        ...state.userResponse,
        [field]: userResponse
      }
    }));
  };

  submit = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault(); // Needed to prevent browser redirect onSubmit

    const { fetchNextMessage } = this.props;
    const { userResponse } = this.state;
    userResponse.responseType = ChatFlowResponseType.PATIENT_VERIFICATION_ATTEMPT;

    fetchNextMessage({
      lastReceivedMessageId: null,
      userResponse,
      userResponseType: ChatFlowResponseType.PATIENT_VERIFICATION_ATTEMPT,
      chatFlowId: null,
      showUserResponse: true,
      createdAt: moment().toDate()
    });
  };

  render() {
    const { msg, index, isCurrentMessage, editMode, chatInfo } = this.props;
    const { userResponse } = this.state;
    const payloadOptions = msg?.payloadOptions as PatientVerificationCardPayloadOptions;
    const {
      personalHealthNumberRequired,
      dateOfBirthRequired,
      insufficientPatientDataMessage,
      hasSufficientPatientData = false
    } = payloadOptions || {};

    const placeHolder = "YYYY-MM-DD";
    const mask = placeHolder.replace(/[YMD]/g, "9");

    const lastAttemptFailed = msg.failed;
    const failedAuthAttempts = msg.failedAuthAttempts || 0;
    const attemptsRemaining = failedAuthAttempts ? 5 - failedAuthAttempts : 5;

    if (!hasSufficientPatientData) {
      const insufficientDataMessage = (
        insufficientPatientDataMessage ||
        "We don't have enough information in the clinic's records to verify your identity. Please call the clinic at [number]."
      ).replace("[number]", chatInfo?.locations?.[0].phoneNumber || "");

      return (
        <StyledComponent {...this.props}>
          <Card key={`${msg.id}-${failedAuthAttempts}`}>
            <Text variant="titleMedium" mb="16px">
              Unable to verify
            </Text>
            <Text>{insufficientDataMessage}</Text>
          </Card>
        </StyledComponent>
      );
    }

    return (
      <StyledComponent {...this.props}>
        {isCurrentMessage && !editMode && (
          <Card key={`${msg.id}-${failedAuthAttempts}`}>
            <Text variant="titleMedium" mb="16px">
              Verify your identity
            </Text>

            {personalHealthNumberRequired && (
              <Input
                id="patientVerification-phnInput"
                placeholder="Type response"
                name="message"
                mb="20px"
                label="Enter your Personal Health Number (PHN)"
                maxRows={1}
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                updateResponse={(value) => this.updateResponse("personalHealthNumber", value)}
                value={userResponse.personalHealthNumber}
                autoFocus
                maxLength={12}
              />
            )}
            {dateOfBirthRequired && (
              <>
                <Text variant="meta" pl="4px">
                  Enter your date of birth
                </Text>
                <DateSelector
                  id="patientVerification-dobInput"
                  name="name"
                  updateResponse={(value) => {
                    this.updateResponse("dateOfBirth", value);
                  }}
                  value={userResponse?.dateOfBirth}
                />
              </>
            )}

            {lastAttemptFailed && (
              <Text textColor="#A31D18" mb="16px">
                Verification failed. Check your information and try again. You have{" "}
                {attemptsRemaining} attempt{`${attemptsRemaining > 1 ? "s" : ""}`} remaining.
              </Text>
            )}
            {!editMode && (
              <Button
                id={`sendBtn-${index}`}
                type="submit"
                onClick={this.submit}
                disabled={
                  (dateOfBirthRequired && !userResponse.dateOfBirth) ||
                  (personalHealthNumberRequired && !userResponse.personalHealthNumber)
                }
              >
                Send
              </Button>
            )}
          </Card>
        )}
      </StyledComponent>
    );
  }
}

export default PatientVerificationCard;
