import React, { useMemo } from "react";
import { ThemeProvider } from "@emotion/react";
import { connect } from "react-redux";
import queryString from "query-string";

import defaultTheme from "./default";
import cigTheme from "./cig";

type ThemeContextProps = { primaryColour?: string; secondaryColour?: string };

export type ThemeTypes = typeof defaultTheme;

const themeMap = {
  default: defaultTheme,
  cig: cigTheme
};

type RootThemeVariables = { primaryColour?: string; secondaryColour?: string };

const generateRootCSSVariables = ({
  primaryColour = "#4072DE",
  secondaryColour = "#1A4B74"
}: RootThemeVariables) => {
  return `
    :root {
      --primary-color: ${primaryColour}; 
      --secondary-color: ${secondaryColour}; 
    }
  `;
};

const getTheme = () => {
  const parsed = queryString.parse(window.location.search);
  const themeName = parsed.themeName as ThemeName;
  return themeMap[themeName] ? themeMap[themeName] : themeMap.default;
};

const ThemeContextProvider: React.FC<ThemeContextProps> = ({
  primaryColour,
  secondaryColour,
  ...props
}) => {
  const theme = useMemo(getTheme, []);

  const rootCSS = useMemo(
    () => generateRootCSSVariables({ primaryColour, secondaryColour }),
    [primaryColour, secondaryColour]
  );

  return (
    <ThemeProvider theme={theme}>
      <style>{rootCSS}</style>
      {props.children}
    </ThemeProvider>
  );
};

const mapStateToProps = ({ chatInfo }: ReduxState) => {
  return {
    primaryColour: chatInfo?.primaryColour,
    secondaryColour: chatInfo?.secondaryColour
  };
};

export default connect(mapStateToProps, null)(ThemeContextProvider);
