/* Date Input
======================================= */

/** @jsxImportSource @emotion/react */
import React, { useImperativeHandle } from "react";
import { useMask } from "@react-input/mask";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

import { Input } from "../../../ui";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

/* Props
--------------------------------------- */

interface Props {
  id?: string;
  name?: string;
  mask: string;
  placeholder?: string;
  label?: string;
  inputRef?: any; // React.RefObject<HTMLInputElement | HTMLTextAreaElement>
  updateResponse?: (userResponse: string) => void;
}

/* Component
--------------------------------------- */

const DateInput: React.FC<Props & StyledProps> = ({
  name = "",
  inputRef = null,
  ...otherProps
}) => {
  const props = { name, inputRef, ...otherProps };
  const maskRef = useMask({
    mask: props.placeholder,
    replacement: { Y: /\d/, M: /\d/, D: /\d/ },
    showMask: true
  });

  useImperativeHandle(inputRef, () => maskRef.current);
  return (
    <StyledComponent {...props}>
      <Input
        inputRef={maskRef}
        name={props.name}
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
        updateResponse={props.updateResponse}
        maxRows={1}
        icon="calendar"
        placeholder={props.placeholder}
        label={props.label}
      />
    </StyledComponent>
  );
};

DateInput.displayName = "DateInput";

export default DateInput;
