/* SelectionContainer
======================================= */

/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { jsx, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

import { Text } from "../../../ui";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

/* Style
--------------------------------------- */

const style = (theme: any) => css`
  display: flex;
  flex-direction: column;

  .selection {
    display: flex;
    justify-content: flex-end;

    .box {
      flex-shrink: 1;
      border: 2px solid #d7d8db;
      border-radius: 10px 2px 10px 10px;
      padding: 16px;
    }
  }

  .meta {
    padding-top: 4px;
    padding-right: 2px;

    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .editButton {
    color: ${theme.colors.primary.blue};

    &:disabled {
      color: ${theme.colors.grey[3]};
    }
  }

  .selection-entry {
    &:last-child {
      margin: 0;
    }
  }

  .image-ui {
    max-width: 230px;
    border-radius: 10px 2px 10px 10px;
  }
`;

/* Props
--------------------------------------- */

interface Props {
  time?: string;
  editClick: (event: React.MouseEvent) => void;
  canUndo?: boolean;
  isDisabled?: boolean;
}

/* Component
--------------------------------------- */

const SelectionContainer: React.FC<Props & StyledProps> = ({
  editClick,
  canUndo,
  isDisabled,
  ...props
}) => {
  const theme = useTheme();

  return (
    <StyledComponent css={style(theme)} {...props}>
      <div className="selection">
        <div className="box">{props.children}</div>
      </div>
      <Text variant="medium" className="meta">
        {canUndo && (
          <button
            type="button"
            className="editButton"
            disabled={isDisabled}
            onClick={(e) => editClick(e)}
          >
            Edit
          </button>
        )}
      </Text>
    </StyledComponent>
  );
};

SelectionContainer.displayName = "SelectionContainer";

export default SelectionContainer;
