/* Link
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  compose,
  style,
  ResponsiveValue,
  space,
  layout,
  typography,
  flexbox,
  SpaceProps,
  LayoutProps,
  TypographyProps,
  FlexboxProps
} from "styled-system";
import CSS from "csstype";

import Icon from "../Icon/Icon";
import Text, { VariantTypes } from "../Text/Text";

import { AllThemeIconTypes } from "../../../utils/getThemeIcons";
import filterProps from "../../../utils/filterProps";

type StyledProps = SpaceProps & LayoutProps & TypographyProps & FlexboxProps;

interface Props {
  id?: string;
  icon?: AllThemeIconTypes | null;
  iconPosition?: "left" | "right";
  iconColor?: ResponsiveValue<CSS.Property.Color>;
  textColor?: ResponsiveValue<CSS.Property.Color>;
  variant?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const textColor = style({
  prop: "textColor",
  cssProperty: "color",
  key: "colors"
});

const iconColor = style({
  prop: "iconColor",
  cssProperty: "color",
  key: "colors"
});

const StyledComponent = styled("button", {
  shouldForwardProp: (prop) => filterProps(prop as string)
})<Props & StyledProps>(compose(space, layout, typography, flexbox, textColor, iconColor));

const Link: React.FC<Props & StyledProps> = ({
  textColor = "primary.blue",
  variant = "medium",
  ...otherProps
}) => {
  const props = {
    textColor,
    variant,
    ...otherProps
  };
  let { icon = "chevronRight", iconPosition = "right" } = props;

  let textProps = {
    variant: "medium" as VariantTypes,
    textColor: props.textColor,
    bold: true
  };

  if (props.variant === "back") {
    icon = "chevronLeft";
    iconPosition = "left";

    textProps = {
      variant: "small" as VariantTypes,
      textColor: "grey.4",
      bold: true
    };
  }

  if (props.variant === "defaultUnbold") {
    textProps = {
      variant: "medium" as VariantTypes,
      textColor: props.textColor,
      bold: false
    };
  }

  const iconLeft =
    icon && iconPosition === "left" ? (
      <Icon name={icon} iconColor={props.iconColor || "grey.4"} mr="10px" mt="1px" />
    ) : null;
  const iconRight =
    icon && iconPosition === "right" ? (
      <Icon name={icon} iconColor={props.iconColor || "grey.4"} ml="10px" mt="1px" />
    ) : null;

  const style = css`
    display: flex;
    align-items: center;
  `;

  return (
    <StyledComponent {...props} css={style}>
      {iconLeft}
      <Text {...textProps} textAlign="left">
        {props.children}
      </Text>
      {iconRight}
    </StyledComponent>
  );
};

Link.displayName = "Link";

export default Link;
