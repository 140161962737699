/* Input Field
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";
import { Text } from "..";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

/* Style
--------------------------------------- */

const style = (theme: any) => css`
  text-align: center;

  padding: 32px;
  border-bottom: 2px solid ${theme.colors.grey[2]};
`;

/* Props
--------------------------------------- */

interface Props {
  title: string;
}

/* Component
--------------------------------------- */

const Intro: React.FC<Props & StyledProps> = (props) => {
  const theme = useTheme();

  return (
    <StyledComponent css={style(theme)} {...props}>
      <Text variant="titleSmall" bold mb="8px">
        {props.title}
      </Text>
      <Text variant="small" maxWidth="311px" m="auto">
        {props.children}
      </Text>
    </StyledComponent>
  );
};

Intro.displayName = "Intro";

export default Intro;
