/* EndCard
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

import { Text, Icon, TextCard, Button } from "..";
import { Box } from "../../layout";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

/* Style
--------------------------------------- */

const style = (theme: any) => css`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;

  background-color: ${theme.colors.grey[1]};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  height: 100vh;
  max-width: 600px;
  margin: auto;
  padding: 0px 16px;

  .logo {
    border: 1px solid #f1f3f3;
    border-radius: 100px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

/* Props
---------------------------------------*/

interface Props {
  msg: Message;
  endChat?: () => void;
}

/* Component
--------------------------------------- */

const EndCard: React.FC<Props & StyledProps> = (props) => {
  const theme: any = useTheme();

  const { endChat } = props;
  let content;
  let headerContent;
  let bodyContent;
  // Sometimes we'll send JSON if there are different components to display
  try {
    content = typeof props.msg.payloadContent === "string" ? props.msg.payloadContent : "";
    content = JSON.parse(content);
    headerContent = content.headerContent;
    bodyContent = content.bodyContent;
  } catch (err) {
    headerContent = props.msg.payloadContent;
  }

  return (
    <StyledComponent id="endCard" css={style(theme)} {...props}>
      <Box mb="20px" className="icon">
        <Icon name="checked" size="72px" iconColor="icon.primary" />
      </Box>
      <Text variant="large" bold mb="16px">
        {headerContent}
      </Text>
      <TextCard
        mb="20px"
        index={1}
        variant="small"
        centerLink
        msg={{
          cardType: "text",
          payloadContent: bodyContent,
          payloadOptions: props.msg.payloadOptions,
          sender: "mikata",
          chatFlowMessageId: 1
        }}
      />
      {endChat && (
        <Button
          id="endBtn"
          onClick={() => {
            endChat();
          }}
          disableOnClick
        >
          Done
        </Button>
      )}
    </StyledComponent>
  );
};

EndCard.displayName = "EndCard";

export default EndCard;
