/* Image
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

import spacerlarge from "../../../../assets/img/spacer_295x180.png";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

/* Style
--------------------------------------- */

const style = (theme: any, src: string, size: string) => css`
  background-image: url(${src});
  background-size: ${size};
  background-color: ${theme.colors.grey[1]};
  background-position: center;
  background-repeat: no-repeat;

  overflow: hidden;
  border-radius: 3px;

  img {
    display: block;
    width: 100%;
    border-radius: 3px;
  }
`;

/* Props
--------------------------------------- */

interface Props {
  src: string;
  size: string;
}

/* Component
--------------------------------------- */

const ImageContain: React.FC<Props & StyledProps> = ({ src, size = "contain", ...props }) => {
  const theme = useTheme();

  return (
    <StyledComponent css={style(theme, src, size)} className="image-ui" {...props}>
      <img src={spacerlarge} alt="" />
    </StyledComponent>
  );
};

ImageContain.displayName = "ImageContain";

export default ImageContain;
