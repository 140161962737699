/* Choice
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

import { Icon } from "../../ui";
import Text from "../Text/Text";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("button")<StyledProps>(compose(space, layout));

/* Style
--------------------------------------- */

const style = (theme: any) => css`
  background-color: #fff;
  border: 2px solid #fff;
  width: 100%;

  border-radius: 10px;
  padding: 14px 12px;

  min-height: 56px;

  display: flex;
  text-align: left;

  &:last-child {
    margin: 0;
  }

  &:focus {
    outline: none;
  }
`;

/* Props
--------------------------------------- */

interface Props {
  // text: string;
  option: string | { text: string; value: string };
  text: string;
  active: boolean;
  selectOption: (
    optionSelectedToDisplay: string,
    optionSelected: string | { text: string; value: string }
  ) => void;
  multiple?: boolean;
  disabled?: boolean;
  detailed?: boolean;
  value?: string;
}

/* Component
--------------------------------------- */

const DetailedChoice: React.FC<Props & StyledProps> = (props) => {
  const theme = useTheme();

  let iconName: "checked" | "circle" | "multipleOn" | "multipleOff" = props.active
    ? "checked"
    : "circle";
  const iconColor = props.active ? "primary.blue" : "grey.2";

  if (props.multiple) {
    iconName = props.active ? "multipleOn" : "multipleOff";
  }

  const text = typeof props.option === "object" ? props.option.text : props.option;

  return (
    <StyledComponent
      css={style(theme)}
      {...props}
      onClick={() => props.selectOption(text, props.option)}
    >
      <Icon name={iconName} as="span" iconColor={iconColor} mr="10px" />
      <div>
        {text && (
          <Text as="span" bold={props.detailed}>
            {props.text}
          </Text>
        )}
        {props.children && props.children}
      </div>
    </StyledComponent>
  );
};

DetailedChoice.displayName = "DetailedChoice";

export default DetailedChoice;
