/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* Image Select
======================================= */

/** @jsxImportSource @emotion/react */
import React, { useRef } from "react";
import { jsx, css } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

/* Style
--------------------------------------- */

const style = () => css`
  position: relative;
  margin: 10px;
  img {
    display: block;
    width: 100%;
    z-index: 0;
    position: relative;
  }

  .zones {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    .zone {
      position: absolute;
      z-index: 2;
    }
  }

  .selections {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    user-select: none;
    pointer-events: none;

    .selection {
      position: absolute;
      z-index: 2;
      width: 10.09%;
      height: 9.52%;
      background-color: rgba(1, 129, 65, 0.5);
      border-radius: 100px;
    }
  }

  &.isDebug {
    .zones {
      outline: 1px dotted #4072de;

      .zone {
        outline: 1px solid #1af215;
        transition: all 200ms ease;

        &:hover {
          background-color: rgba(26, 242, 21, 0.2);
        }
      }
    }
  }

  &.isStatic {
    max-width: 230px;
  }
`;

/* Props
--------------------------------------- */
interface Props {
  background: string;
  debug?: boolean;
  isStatic?: boolean;
  zones?: Zone[];
  selections?: {
    x: string;
    y: string;
  }[];
  handleTouch?: (touch: ImageTouch) => void;
}

/* Component
--------------------------------------- */

const ImageSelect: React.FC<Props & StyledProps> = ({
  debug = false,
  isStatic = false,
  zones = [],
  selections = [],
  handleTouch = undefined,
  background,
  ...otherProps
}: Props) => {
  const props = {
    debug,
    isStatic,
    zones,
    selections,
    handleTouch,
    ...otherProps
  };
  const boundingRef = useRef<HTMLDivElement>(null);

  const handleClick = (event: React.MouseEvent, zone: Zone) => {
    if (props.handleTouch) {
      const parent = boundingRef.current;
      const bounds = parent?.getBoundingClientRect();
      const circleOffsetX = 10.09 / 2;
      const circleOffsetY = 9.52 / 2;

      if (parent && bounds) {
        const posX = event.clientX - bounds.left;
        const posY = event.clientY - bounds.top;

        const percentX = (100 * posX) / bounds.width;
        const percentY = (100 * posY) / bounds.height;

        const x = `${percentX - circleOffsetX}%`;
        const y = `${percentY - circleOffsetY}%`;

        props.handleTouch({ x, y, zone });
      }
    }
  };

  const zoneItems = zones?.map((zone) => (
    <div
      className="zone"
      key={zone.id + zone.x + zone.y}
      style={{ width: zone.w, height: zone.h, top: zone.y, left: zone.x }}
      onClick={(event) => handleClick(event, zone)}
    />
  ));

  const selectionItems = selections?.map((entry, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <div className="selection" key={index} style={{ top: entry.y, left: entry.x }} />
  ));

  const classNames = [debug ? "isDebug" : "", isStatic ? "isStatic" : ""].join(" ");

  return (
    <StyledComponent ref={boundingRef} css={style()} className={classNames}>
      <div className="zones">{zoneItems}</div>
      <div className="selections">{selectionItems}</div>
      <img src={background} alt="" />
    </StyledComponent>
  );
};

ImageSelect.displayName = "ImageSelect";

export default ImageSelect;
