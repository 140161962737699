/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import moment from "moment-timezone";

import { css, jsx, useTheme } from "@emotion/react";
import { SpaceProps, LayoutProps } from "styled-system";

import { Box, Scroll } from "../../../layout";
import { Button, Drawer, Icon, Link, Loader, Text, Checkbox } from "../..";
import { SelectFieldOption } from "../../Fields/SelectInput";

import {
  getAvailableAppointments as getAvailableAppointmentsAction,
  resetAvailableAppointments as resetAvailableAppointmentsAction,
  addAvailableSlotSelection as addAvailableSlotSelectionAction,
  removeAvailableSlotSelection as removeAvailableSlotSelectionAction,
  setSlotSelection as setSlotSelectionAction,
  AutoBookingData,
  autoBookAppointment as autoBookAppointmentAction
} from "../../../../actions";

import DayPicker, { generateCalendarDays, CalendarDays, WeekData } from "./DayPicker";
import ReviewDrawer from "./ReviewDrawer";

import {
  ThemeTypes,
  AvailabilitySearchFilters,
  BookingMode,
  ChatFlowResponseType
} from "../../../../types";

type StyledProps = SpaceProps & LayoutProps;

interface PropsType {
  open: boolean;
  bookingMode: BookingMode;
  toggleModal: () => void;
  bookingReason: BookingReason;
  organizationTimezone: string;
  availableAppointments: AvailableAppointments;
  addAvailableSlotSelection: (slot: AvailableSlot) => void;
  removeAvailableSlotSelection: (slot: AvailableSlot) => void;
  setSlotSelection: (slots: AvailableSlot[]) => void;
  selectedPractitioner: SelectFieldOption | null;
  practitionerOptions: Array<SelectFieldOption>;
  locationOptions: Array<any>;
  selectedLocation: SelectFieldOption | null;
  getAvailableAppointments: (token: string, filters: AvailabilitySearchFilters) => void;
  resetAvailableAppointments: () => void;
  submitSelectedSlots: (submitAsBookingMode?: BookingMode) => void;
  selectedAppointmentSlots?: Array<AvailableSlot>;
  patient: { id: number } | null;
  autoBookAppointment: (token: string, data: AutoBookingData, onSuccess: () => void) => void;
}

const style = (theme: ThemeTypes) => css`
  z-index: 110;
  position: fixed;
  display: flex;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  max-width: 600px;
  margin: 0 auto;
  overflow: hidden;

  .modalHeader {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
  }

  .locationGroup {
    padding-bottom: 8px;
  }

  .appointments {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    padding-bottom: 16px;
    padding-left: 8px;
  }

  .loadingWrapper {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices - Fix for hidden content under message header */
    .infoModalHeader {
      margin-top: 72px;
    }
  }
`;

const SearchModal = ({
  open,
  bookingMode,
  toggleModal,
  availableAppointments,
  selectedLocation,
  selectedPractitioner,
  practitionerOptions,
  locationOptions,
  getAvailableAppointments,
  resetAvailableAppointments,
  addAvailableSlotSelection,
  removeAvailableSlotSelection,
  setSlotSelection,
  submitSelectedSlots,
  bookingReason,
  organizationTimezone,
  selectedAppointmentSlots,
  patient,
  autoBookAppointment
}: PropsType) => {
  const {
    params: { token = "" }
  } = useRouteMatch<{ token?: string }>();
  const theme = useTheme() as ThemeTypes;

  const calendarDays: CalendarDays = useMemo(
    () => generateCalendarDays(organizationTimezone, bookingReason.availabilityRangeEndWeeks || 4),
    []
  );

  const [focusedDay, setFocusedDay] = useState<string>(calendarDays?.[0]?.datesLocal?.[0].id || "");
  const [reviewOpen, setReviewOpen] = useState(false);

  const searchFilters = {
    locationIds: selectedLocation
      ? [selectedLocation.value]
      : locationOptions.map((locationOption) => locationOption.value),
    practitionerIds: selectedPractitioner
      ? [selectedPractitioner.value]
      : practitionerOptions.map((practitionerOption) => practitionerOption.value),
    duration: bookingReason.duration,
    includedScheduleTags: bookingReason.includedScheduleTags || []
  };

  // When filters change reset focusedDay and pre-fetch first weeks available appointments
  useEffect(() => {
    setSlotSelection([]);
    setFocusedDay(calendarDays?.[0]?.datesLocal?.[0].id || "");
    resetAvailableAppointments();
    getAvailableAppointments(token, {
      ...searchFilters,
      start: calendarDays?.[0]?.startTimeUTC?.toISOString() || "",
      end: calendarDays?.[0]?.endTimeUTC?.toISOString() || "",
      dateIds: calendarDays?.[0]?.datesLocal.map((dateInfo) => dateInfo.id)
    });
  }, [
    selectedLocation,
    selectedPractitioner,
    bookingReason.duration,
    bookingReason.includedScheduleTags
  ]);

  const getAvailableAppointmentsForWeek = (weekData: WeekData) => {
    getAvailableAppointments(token, {
      ...searchFilters,
      start: weekData.startTimeUTC?.toISOString() || "",
      end: weekData.endTimeUTC?.toISOString() || "",
      dateIds: weekData.datesLocal.map((dateInfo) => dateInfo.id)
    });
  };

  const addSlotSelection = (slot: AvailableSlot) => {
    addAvailableSlotSelection(slot);
  };

  const removeSlotSelection = (slot: AvailableSlot) => {
    removeAvailableSlotSelection(slot);
  };

  const autoBook = () => {
    autoBookAppointment(
      token,
      {
        patient: { id: patient?.id as number },
        bookingData: {
          responseType: ChatFlowResponseType.BOOKING,
          bookingReason,
          selectedAppointmentSlots: selectedAppointmentSlots as AvailableSlot[]
        }
      },
      () => {
        submitSelectedSlots(BookingMode.AUTO_BOOK);
      }
    );
  };

  if (!open) return null;

  const focusedDayLoading = focusedDay && !availableAppointments[focusedDay];
  const groupedFocusDayAppointments =
    focusedDay && availableAppointments[focusedDay]
      ? availableAppointments[focusedDay].reduce((grouped: any, available: AvailableSlot) => {
          if (!grouped?.[available.locationId.toString()]) {
            grouped[available.locationId.toString()] = {};
          }
          if (!grouped?.[available.locationId.toString()]?.[available.practitionerId.toString()]) {
            grouped[available.locationId.toString()][available.practitionerId.toString()] = [];
          }
          grouped[available.locationId.toString()][available.practitionerId.toString()].push(
            available
          );
          return grouped;
        }, {})
      : {};

  const selectedBySlotIds = selectedAppointmentSlots?.reduce<{ [slotIds: string]: AvailableSlot }>(
    (bySlotIds, slot) => {
      const slotIdsString: string = slot.slotIds.join(",");
      return { ...bySlotIds, [slotIdsString]: slot };
    },
    {}
  );
  const selectedDayDisplay = moment(focusedDay).format("MMMM Do, YYYY");

  return (
    <Drawer css={style(theme)}>
      <Scroll>
        <header className="modalHeader">
          <Link variant="back" onClick={toggleModal}>
            Back
          </Link>
          {selectedAppointmentSlots && selectedAppointmentSlots.length === 0 && (
            <Button variant="inline" onClick={() => setReviewOpen(true)}>
              No times work
            </Button>
          )}
        </header>
        <Box p="12px 24px 12px 24px">
          {bookingMode === BookingMode.AUTO_BOOK ? (
            <Text variant="titleMedium" mb="16px">
              Select a day and time
            </Text>
          ) : (
            <>
              <Text variant="titleMedium" mb="16px">
                Select appointment times
              </Text>
              <Text variant="small">Pick 3 to 5 times that work best for you.</Text>
            </>
          )}
        </Box>
        <DayPicker
          focusedDay={focusedDay}
          calendarDays={calendarDays}
          onDaySelection={setFocusedDay}
          getAvailableAppointmentsForWeek={getAvailableAppointmentsForWeek}
          availableAppointments={availableAppointments}
        />
        <Box p="0px 24px 80px 24px">
          <>
            <Text variant="large" mb="12px">
              {selectedDayDisplay}
            </Text>
            {/* Focused Day Loading */}
            {focusedDayLoading && (
              <div className="loadingWrapper">
                <Text mb="16px">Loading available times!</Text>
                <Loader center />
              </div>
            )}
            {/* Focused Day No Available Appointments */}
            {!focusedDayLoading && Object.keys(groupedFocusDayAppointments).length === 0 && (
              <div className="loadingWrapper">
                <Text mb="12px">No available appointments!</Text>
                <Text mb="12px">Try another day!</Text>
              </div>
            )}
            {/* Focused Day With Available Appointments */}
            {!focusedDayLoading &&
              groupedFocusDayAppointments &&
              Object.keys(groupedFocusDayAppointments).map((locationId) => {
                const locationAvailableAppointments: AvailableSlot[][] = Object.values(
                  groupedFocusDayAppointments[locationId]
                );
                const locationName = locationAvailableAppointments?.[0]?.[0]?.locationName || "-";
                return (
                  <div key={locationId} className="locationGroup">
                    <Text variant="meta" mb="8px">
                      {locationName}
                    </Text>
                    {Object.keys(groupedFocusDayAppointments[locationId]).map((practitionerId) => {
                      const locationProviderAppointments =
                        groupedFocusDayAppointments[locationId][practitionerId];
                      const providerName =
                        locationProviderAppointments?.[0]?.practitionerName || "-";

                      return (
                        <React.Fragment key={`${locationId}-${practitionerId}`}>
                          <Text key={`${locationId}-${practitionerId}`} variant="small" mb="8px">
                            {providerName}
                          </Text>
                          <div className="appointments">
                            {locationProviderAppointments.map((availableSlot: AvailableSlot) => {
                              const hour = moment
                                .tz(availableSlot.startTime, availableSlot.locationTimezone)
                                .format("h:mm A");

                              const slotIdsString: string = availableSlot.slotIds.join(",");
                              const isSelected = selectedBySlotIds?.[slotIdsString];

                              return (
                                <Checkbox
                                  key={`${availableSlot.startTime}${isSelected}`}
                                  isSelected={Boolean(isSelected)}
                                  value={slotIdsString}
                                  onClick={() => {
                                    if (isSelected) {
                                      removeSlotSelection(availableSlot);
                                    } else if (bookingMode === BookingMode.AUTO_BOOK) {
                                      setSlotSelection([availableSlot]);
                                      setReviewOpen(true);
                                    } else addSlotSelection(availableSlot);
                                  }}
                                  label={hour}
                                />
                              );
                            })}
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
          </>
        </Box>
        <ReviewDrawer
          bookingMode={bookingMode}
          bookingReasonLabel={bookingReason.label}
          removeAvailableSlotSelection={removeSlotSelection}
          submitSelectedSlots={(submitAsBookingMode?) => {
            submitSelectedSlots(submitAsBookingMode);
            setReviewOpen(false);
          }}
          selectedAppointmentSlots={selectedAppointmentSlots}
          reviewOpen={reviewOpen}
          setReviewOpen={setReviewOpen}
          autoBook={autoBook}
          setSlotSelection={setSlotSelection}
        />
      </Scroll>
    </Drawer>
  );
};

function mapStateToProps({ booking }: ReduxState) {
  return {
    availableAppointments: booking.availableAppointments,
    selectedAppointmentSlots: booking.selectedAppointmentSlots
  };
}

export default connect(mapStateToProps, {
  getAvailableAppointments: getAvailableAppointmentsAction,
  resetAvailableAppointments: resetAvailableAppointmentsAction,
  addAvailableSlotSelection: addAvailableSlotSelectionAction,
  removeAvailableSlotSelection: removeAvailableSlotSelectionAction,
  setSlotSelection: setSlotSelectionAction,
  autoBookAppointment: autoBookAppointmentAction
})(SearchModal);
