/* Units Input Field
======================================= */

/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { jsx, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

const style = (theme: any) => css`
  .field {
    background: #fff;
    box-shadow: ${theme.shadows.small};
    border: 2px solid #fff;
    transition: all 200ms ease;
    cursor: text;
    min-height: 56px;

    border-radius: ${theme.radii.normal};
    padding: 6px;
    position: relative;
    z-index: 2;
    display: flex;
  }

  input,
  select,
  span {
    font-family: ${theme.fonts.heading};
    color: ${theme.colors.grey[4]};

    box-sizing: border-box;
    line-height: 20px;

    &:focus {
      outline: none;
    }
  }

  input {
    flex: 1;
    min-width: 100px;
    padding: 10px 5px 10px 12px;

    -webkit-appearance: none;
    ::-webkit-input-placeholder {
      color: ${theme.colors.grey[4]};
    }
  }

  select,
  span {
    background-color: #fff;
    padding: 10px 5px 10px 5px;
    border-left-width: 1.25px;
    border-color: ${theme.colors.grey[4]};
  }

  // Disabling dotted border around the dropdown
  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  &.isFocused {
    .field {
      border: 2px solid ${theme.colors.primary.blue};
      box-shadow: none;
    }
  }
`;

/* Props
--------------------------------------- */
interface Props {
  numberResponse: string;
  units: string[];
  setNumberResponse: (numberResponse: string) => void;
  setSelectedUnit: (unit: string) => void;
  maxDigits: number | undefined;
}

/* Component
--------------------------------------- */

const UnitsInput: React.FC<Props & StyledProps> = (props: Props & StyledProps) => {
  const theme = useTheme();

  const [isFocused, setFocus] = useState(false);
  const onFocus = () => setFocus(true);
  const onBlur = () => setFocus(false);

  const classNames = isFocused ? "isFocused" : "";

  const { numberResponse, units, setNumberResponse, setSelectedUnit, maxDigits, mb } = props;

  return (
    <StyledComponent css={style(theme)} className={classNames} mb={mb}>
      <div className="field" onFocus={onFocus} onBlur={onBlur}>
        <input
          type="number"
          placeholder="Type response"
          value={numberResponse}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={({ target }) => {
            if (!maxDigits || target.value.length <= maxDigits) setNumberResponse(target.value);
          }}
        />

        {units.length === 1 && <span>{units[0]}</span>}

        {units.length > 1 && (
          <select
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={({ target }) => setSelectedUnit(target.value)}
          >
            {units.map((unit) => (
              <option value={unit} key={unit}>
                {unit}
              </option>
            ))}
          </select>
        )}
      </div>
    </StyledComponent>
  );
};

UnitsInput.displayName = "UnitsInput";

export default UnitsInput;
