import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import useAppAnalytics, { AppMetric, QueueMetricOptions } from "../../hooks/useAppAnalytics";
import useInterval from "../../hooks/useInterval";

import jwtDecode from "jwt-decode";

type PropsType = {
  children: React.ReactNode;
};

type AnalyticsContextType = {
  queueMetric: (appMetric: AppMetric, options?: QueueMetricOptions) => void;
} | null;

const initialAnalyticsContext: AnalyticsContextType = null;

export const AnalyticsContext = React.createContext<AnalyticsContextType>(initialAnalyticsContext);
const PROCESS_METRICS_INTERVAL_MS = 60000; // 60s

const AnalyticsProvider = ({ children }: PropsType) => {
  let organizationId;
  let userId;

  try {
    const sessionToken = sessionStorage.getItem("token");
    const token: DecodedToken = jwtDecode(sessionToken || "");

    organizationId = token.organizationId;
    userId = token.userId;
  } catch (e) {
    console.log("Token error in AnalyticsProvider - ", e);
  }

  const { queueMetric, processMetricsQueue } = useAppAnalytics({
    organizationId,
    userId
  });

  // Periodically process metrics queue
  useInterval(processMetricsQueue, [processMetricsQueue], PROCESS_METRICS_INTERVAL_MS);

  // Process metrics queue on un-mount
  useEffect(() => {
    return () => processMetricsQueue();
  }, [processMetricsQueue]);

  const value = {
    queueMetric
  };

  return (
    <AnalyticsContext.Provider value={value as AnalyticsContextType}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
