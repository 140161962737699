import theme from "../default";

/* Theming for Canada.ca website
========================================= */

const newTheme = JSON.parse(JSON.stringify(theme));

newTheme.themeName = "cig";

newTheme.colors.primary.blue = "#26374A";
newTheme.colors.icon.primary = "#ff0000";
newTheme.buttons.primary.bg = newTheme.colors.primary.blue;
newTheme.buttons.primary.hover = "#3f4b59";

newTheme.apptReqHeaderText = "Have a question?";
newTheme.apptReqHeaderSubtext = "Ask me anything about immunizations and vaccinations!";

export default newTheme;
