import React from "react";
import { Text } from "../../ui";

import { HeadingNode } from "../../../types";

type PropsType = {
  node: HeadingNode;
};

const Heading = ({ node }: PropsType) => {
  const { size = "m" } = node.properties;
  const textProps: {
    variant: "medium" | "titleSmall" | "titleMedium";
    bold: boolean;
    mb: string;
  } = {
    variant: "titleSmall",
    bold: true,
    mb: "8px"
  };

  if (size === "s") {
    textProps.variant = "medium";
    textProps.bold = true;
    textProps.mb = "8px";
  }

  if (size === "l") {
    textProps.variant = "titleMedium";
    textProps.bold = true;
    textProps.mb = "8px";
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Text {...textProps}>{node.properties.value}</Text>;
};

export default Heading;
