/* eslint-disable no-underscore-dangle */
/** @jsxImportSource @emotion/react */
import * as React from "react";

import { ChatCardTypes } from "../../constants";
import { Box } from "../../components/layout";

import {
  AddressInputCard,
  BookingCard,
  Button,
  ChatLoader,
  CheckInCard,
  DateInputCard,
  EmailInputCard,
  EndCard,
  Icon,
  ImageSelectCard,
  InstructionChecklist,
  InstructionsCard,
  InstructionReasonsCard,
  NumberInputCard,
  PatientVerificationCard,
  PhoneInputCard,
  PhotoUploadCard,
  ScaleInputCard,
  SelectCard,
  SummaryCard,
  TextCard,
  TextInputCard,
  UnitsInputCard,
  UserResponseCard,
  SecureMessageCard,
  ScribeVisitCard
} from "../../components/ui";

export interface MessagesProps {
  messages: Message[];
  conversationId: number;
  token: string;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
  undoLastAnswer: (messageTemplateId: number, chatFlowMessageId?: number) => void;
  canUndo: (msg: Message, messages: Message[]) => boolean;
  fetchingMessage: boolean;
  chatInfo: ChatInfo;
  endChat?: () => void;
}

export default class Messages extends React.Component<MessagesProps> {
  componentDidMount(): void {
    this.scrollToCurrent();
  }

  componentDidUpdate(prevProps: MessagesProps): void {
    const { messages } = this.props;
    const prevMessagesLength = prevProps.messages.length;
    const newMessagesLength = messages.length;

    if (prevMessagesLength >= 1 && newMessagesLength >= 1) {
      if (
        prevProps.messages[prevMessagesLength - 1].id !== messages[newMessagesLength - 1].id &&
        messages[newMessagesLength - 1].payloadContent !== "Next" && // prevent a scroll over instruction content
        messages[newMessagesLength - 1].cardType !== ChatCardTypes.PROCEDURE_INFO &&
        messages[newMessagesLength - 1].cardType !== ChatCardTypes.PROCEDURE_INFO_NEW
      ) {
        this.scrollToCurrent();
      }
    }
  }

  scrollToCurrent = (): void => {
    const { messages } = this.props;
    if (messages[0]) {
      const currentMessage = document.getElementById("currentMessage");
      const scrollContainer = document.getElementById("scrollContainer");

      if (scrollContainer && currentMessage) {
        /**
         * scrollIntoView is not supported properly in iOS hence had to move this out of main thread by using timeout
         * smooth behaviour is not supported on iOS
         * Ref - https://stackoverflow.com/questions/61018133/scrollintoview-not-working-on-ios-safari-and-chrome
         */
        setTimeout(() => {
          currentMessage.scrollIntoView({ behavior: "smooth", block: "start" });
        });
      } else if (scrollContainer) {
        scrollContainer.scrollTo({
          top: scrollContainer.scrollHeight,
          behavior: "smooth"
        });
      }
    }
  };

  _renderChatCard(msg: any, index: number): JSX.Element | false {
    const {
      canUndo,
      chatInfo,
      fetchingMessage,
      fetchNextMessage,
      messages,
      conversationId,
      token,
      undoLastAnswer,
      endChat
    } = this.props;

    const isCurrentMessage = index === messages.length - 1;
    switch (msg.cardType) {
      case ChatCardTypes.USER_RESPONSE:
        return (
          <UserResponseCard
            msg={msg}
            undoLastAnswer={undoLastAnswer}
            canUndo={canUndo(msg, messages)}
          />
        );
      case ChatCardTypes.TEXT:
        return (
          <TextCard
            msg={msg}
            index={index}
            avatar={<Icon name="logo" iconColor="reset" size="18px" />}
          />
        );
      case ChatCardTypes.BOOKING:
        return (
          <BookingCard
            msg={msg}
            fetchNextMessage={fetchNextMessage}
            isCurrentMessage={isCurrentMessage}
          />
        );
      case ChatCardTypes.PROCEDURE_INFO_NEW: // Deprecated
        return <InstructionChecklist token={token} fetchingMessage={fetchingMessage} />;
      case ChatCardTypes.INSTRUCTION_REASONS:
        return (
          <InstructionReasonsCard
            msg={msg}
            index={index}
            fetchNextMessage={fetchNextMessage}
            isCurrentMessage={isCurrentMessage}
          />
        );
      case ChatCardTypes.INSTRUCTIONS_FROM_REASONS:
      case ChatCardTypes.INSTRUCTIONS:
        return (
          <InstructionsCard
            msg={msg}
            index={index}
            avatar={<Icon name="logo" iconColor="reset" size="18px" />}
          />
        );
      case ChatCardTypes.MULTI_SELECT:
        return (
          <SelectCard
            msg={msg}
            index={index}
            fetchNextMessage={fetchNextMessage}
            allowMulti
            isCurrentMessage={isCurrentMessage}
          />
        );
      case ChatCardTypes.SINGLE_SELECT:
        return (
          <SelectCard
            msg={msg}
            index={index}
            fetchNextMessage={fetchNextMessage}
            allowMulti={false}
            isCurrentMessage={isCurrentMessage}
          />
        );
      case ChatCardTypes.NEXT_BUTTON:
        return (
          isCurrentMessage && (
            <Button
              onClick={() => {
                fetchNextMessage({
                  lastReceivedMessageId: null,
                  userResponse: null,
                  chatFlowId: null
                });
              }}
              disableOnClick
            >
              {msg.payloadContent}
            </Button>
          )
        );
      case ChatCardTypes.TEXT_INPUT:
        return (
          <TextInputCard
            msg={msg}
            index={index}
            fetchNextMessage={fetchNextMessage}
            isCurrentMessage={isCurrentMessage}
          />
        );
      case ChatCardTypes.PHONE_INPUT:
        return (
          <PhoneInputCard
            msg={msg}
            index={index}
            fetchNextMessage={fetchNextMessage}
            isCurrentMessage={isCurrentMessage}
          />
        );
      case ChatCardTypes.DATE_INPUT:
        return (
          <DateInputCard
            msg={msg}
            index={index}
            fetchNextMessage={fetchNextMessage}
            isCurrentMessage={isCurrentMessage}
          />
        );
      case ChatCardTypes.NUMBER_INPUT:
        return (
          <NumberInputCard
            msg={msg}
            index={index}
            fetchNextMessage={fetchNextMessage}
            isCurrentMessage={isCurrentMessage}
          />
        );
      case ChatCardTypes.ADDRESS_INPUT:
        return (
          <AddressInputCard
            msg={msg}
            index={index}
            fetchNextMessage={fetchNextMessage}
            isCurrentMessage={isCurrentMessage}
          />
        );
      case ChatCardTypes.EMAIL_INPUT:
        return (
          <EmailInputCard
            msg={msg}
            index={index}
            fetchNextMessage={fetchNextMessage}
            isCurrentMessage={isCurrentMessage}
          />
        );
      case ChatCardTypes.SCALE_INPUT:
        return (
          <ScaleInputCard
            msg={msg}
            index={index}
            fetchNextMessage={fetchNextMessage}
            isCurrentMessage={isCurrentMessage}
          />
        );
      case ChatCardTypes.IMAGE_SELECT:
        return (
          <ImageSelectCard
            msg={msg}
            index={index}
            fetchNextMessage={fetchNextMessage}
            isCurrentMessage={isCurrentMessage}
          />
        );
      case ChatCardTypes.UNITS_INPUT:
        return (
          <UnitsInputCard
            msg={msg}
            index={index}
            fetchNextMessage={fetchNextMessage}
            isCurrentMessage={isCurrentMessage}
          />
        );
      case ChatCardTypes.PHOTO_UPLOAD:
        return (
          <PhotoUploadCard
            msg={msg}
            index={index}
            token={token}
            fetchNextMessage={fetchNextMessage}
            isCurrentMessage={isCurrentMessage}
            conversationId={conversationId}
          />
        );
      case ChatCardTypes.SUMMARY:
        return (
          <SummaryCard
            msg={msg}
            fetchNextMessage={fetchNextMessage}
            isCurrentMessage={isCurrentMessage}
            chatInfo={chatInfo}
            token={token}
          />
        );
      case ChatCardTypes.DONE_BUTTON:
        return (
          isCurrentMessage && (
            <Button
              id="doneBtn"
              onClick={() => {
                fetchNextMessage({
                  lastReceivedMessageId: null,
                  userResponse: null,
                  chatFlowId: null
                });
              }}
              disableOnClick
            >
              {msg.payloadContent}
            </Button>
          )
        );
      case ChatCardTypes.PATIENT_VERIFICATION:
        return (
          <PatientVerificationCard
            msg={msg}
            index={index}
            chatInfo={chatInfo}
            fetchNextMessage={fetchNextMessage}
            isCurrentMessage={isCurrentMessage}
          />
        );
      case ChatCardTypes.SECURE_MESSAGE:
        return <SecureMessageCard msg={msg} />;
      case ChatCardTypes.SCRIBE_VISIT:
        return <ScribeVisitCard msg={msg} isCurrentMessage={isCurrentMessage} />;
      case ChatCardTypes.DEAD_END:
        return (
          <>
            <TextCard
              mb="12px"
              msg={msg}
              index={index}
              avatar={<Icon name="logo" iconColor="reset" size="18px" />}
            />
            {endChat && (
              <Button
                id="endBtn"
                onClick={() => {
                  endChat();
                }}
                disableOnClick
              >
                Done
              </Button>
            )}
          </>
        );
      case ChatCardTypes.CHECK_IN:
        return <CheckInCard msg={msg} token={token} fetchNextMessage={fetchNextMessage} />;
      case ChatCardTypes.END:
      case ChatCardTypes.FLAGGED_END:
        return <EndCard msg={msg} endChat={endChat} />;
      default:
        return <div />;
    }
  }

  render(): JSX.Element {
    const { messages, fetchingMessage } = this.props;

    return (
      <Box p="32px 24px 100px 24px">
        {messages.map((msg, index) => {
          const isCurrentMessage = index === messages.length - 1;
          return (
            <Box
              id={isCurrentMessage ? "currentMessage" : undefined}
              className={msg?.id ? `messageTemplateId-${msg.id}` : undefined}
              mb="32px"
              // eslint-disable-next-line react/no-array-index-key
              key={`chatcard-${msg.id}-${index}`}
            >
              {this._renderChatCard(msg, index)}
            </Box>
          );
        })}

        {fetchingMessage && <ChatLoader />}
      </Box>
    );
  }
}
