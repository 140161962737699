/* Steps
======================================= */

import React, { createContext } from "react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

export type VariantTypes = "vertical" | "horizontal" | "bullet" | "numbered" | undefined;
export const VariantContext = createContext<VariantTypes>(undefined);

type StyledProps = SpaceProps & LayoutProps;

const StyledContainer = styled("div")<StyledProps>(compose(space, layout));

interface Props {
  variant?: VariantTypes;
}

const Steps: React.FC<Props & StyledProps> = ({ variant = "vertical", ...props }) => {
  return (
    <VariantContext.Provider value={variant}>
      <StyledContainer {...props}>{props.children}</StyledContainer>
    </VariantContext.Provider>
  );
};

Steps.displayName = "Step";

export default Steps;
