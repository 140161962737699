import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";

import { Text } from "../..";
import AppointmentSummaryCardDeprecated from "./AppointmentSummaryCardDeprecated";
import InfoTasksCardDeprecated from "./InfoTasksCardDeprecated"; // IMPORTANT! deprecated LANDING CARD should use DEPRECATED InfoTasksCard
import MoreDetailsCardDeprecated from "./MoreDetailsCardDeprecated";

import { Scroll } from "../../../layout";
import logos from "../../../../assets/img";

import {
  updateAppointmentStatus as updateAppointmentStatusAction,
  UpdateAppointmentStatusActionData
} from "../../../../actions/chat-actions";

import styles from "./index.module.scss";

interface LandingCardProps {
  chatInfo: ChatInfo;
  token: string;
  updateAppointmentStatus: (token: string, data: UpdateAppointmentStatusActionData) => void;
}

const LandingCardDeprecated = ({ token, chatInfo, updateAppointmentStatus }: LandingCardProps) => {
  const { organizationName, logo } = chatInfo;

  const multiLocations = chatInfo.locations.length > 1;

  const animation = {
    initial: { y: "-100%" },
    animate: { y: 0 },
    exit: { y: "-100%" },
    transition: { type: "spring", stiffness: 200, damping: 25 }
  };

  const getLogo = (logoToCheck: string) => {
    return logos[logoToCheck]
      ? logos[logoToCheck]
      : `${process.env.REACT_APP_AWS_LOGO_BUCKET_URL}${logoToCheck}`;
  };

  if (chatInfo && !chatInfo.chatFlowTags.length) return <div />;

  return (
    <motion.div {...animation} className={styles.Motion}>
      <Scroll>
        <section className={styles.Section}>
          <header className={styles.Header}>
            {logo && (
              <img
                className={styles.Logo}
                src={getLogo(logo)}
                alt={`${organizationName} logo`}
                onError={(event) => {
                  // Hide image if fails to load
                  const newEvent = event;
                  newEvent.currentTarget.style.display = "none";
                }}
                onLoad={(event) => {
                  // Show image when loaded
                  const newEvent = event;
                  newEvent.currentTarget.style.display = "flex";
                }}
              />
            )}
          </header>
          <main className={styles.Main}>
            {/* Appointment Summary Card */}
            <AppointmentSummaryCardDeprecated
              chatInfo={chatInfo}
              token={token}
              updateAppointmentStatus={updateAppointmentStatus}
            />
            {/* Tasks/general Chat Content Card (ie start chat) */}
            <InfoTasksCardDeprecated chatInfo={chatInfo} />
            {/* More Detail Card */}
            {!multiLocations && (
              <MoreDetailsCardDeprecated
                location={chatInfo.locations[0]}
                chatFlowTags={chatInfo.chatFlowTags}
              />
            )}
          </main>
          <footer className={styles.Footer}>
            <Text variant="xsmall" textColor="grey.3">
              <a href="https://mikatahealth.com/" rel="noopener noreferrer" target="_blank">
                Powered by Mikata
              </a>
            </Text>
          </footer>
        </section>
      </Scroll>
    </motion.div>
  );
};

function mapStateToProps(state: ReduxState) {
  return {
    chatInfo: state.chatInfo
  };
}

export default connect(mapStateToProps, {
  updateAppointmentStatus: updateAppointmentStatusAction
})(LandingCardDeprecated);
