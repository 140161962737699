/* Selection
======================================= */

/*
  DEPRICATED
  Let's delete this component once the new SelectionContainer is implemented
*/

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";
import { Icon, Text } from "../../../components/ui";
import { AllThemeIconTypes } from "../../../utils/getThemeIcons";

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

interface Props {
  icon?: AllThemeIconTypes;
  as?: string;
  text: string;
}

const Selection: React.FC<Props & StyledProps> = (props) => {
  const style = css`
    display: flex;
    flex-direction: column;

    button.target,
    div.target {
      background-color: #fff;
      border: 2px solid #d7d8db;
      border-radius: 10px 0px 10px 10px;

      display: flex;
      align-items: center;
      align-self: flex-end;

      height: 56px;
      padding: 0px 24px;

      .text-ui {
        flex-grow: 1;
        text-align: left;
      }

      .helptext {
        display: none;
        font-size: 12px;
        color: #4072de;
        margin-left: 30px;
      }
    }

    button.target {
      min-width: 270px;
      padding: 0px 14px 0 24px;

      .text-ui {
        color: #4072de;
      }

      .helptext {
        display: block;
      }
    }
  `;

  const icon = props.icon ? <Icon name={props.icon} iconColor="#4072DE" mr="10px" /> : null;

  return (
    <div css={style}>
      <StyledComponent className="target" {...(props as any)}>
        {icon}
        <Text as="span">{props.text}</Text>
        <span className="helptext">Change</span>
      </StyledComponent>
    </div>
  );
};

Selection.displayName = "Selection";

export default Selection;

/*
import React from 'react';
import { jsx, css } from '@emotion/core';
import { Icon } from '../../../components/ui';
import { IconTypes } from '../../../theme/icons';

interface Props {
	icon?: IconTypes;
	as?: string;
	text: string;
};

const Selection: React.FC<Props> = ( props ) => 
{
	// styles



	// props logic

	interface Props {
		as?: string;
		className?: string;
	};

	const Tag: React.FC<Props> = ({ children, ...props }) =>
	{   
		console.log( props.as );
		const type = ( props.as )? props.as : 'div';
		return React.createElement( type, props, children );
	}

	const icon = ( props.icon )? <Icon name={props.icon} iconColor="#4072DE" mr="10px" /> : null;

	// markup

	return(
		<div css={style}>
			<Tag {...props} className="target">
				{icon}
				<span className="text">{ props.text }</span>
				<span className="helptext">Change</span>
			</Tag>
		</div>
	);
};

Selection.displayName = 'Selection';

export default Selection;
*/
