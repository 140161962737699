/** @jsxImportSource @emotion/react */
import React, { useState, useMemo } from "react";
import { jsx, css } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";
import moment from "moment-timezone";

import { Box } from "../../../layout";
import { Card, Text, Button, Modal, AddToCalendarButton, InfoModal } from "../..";
import MoreDetailsCard from "./MoreDetailsCardDeprecated";
import { ChatFlowTags, AppointmentStates } from "../../../../constants";
import { Calendar, MapPin } from "../../Icons";

import getChatType from "../../../../utils/getChatType";
import { JsonContent, AutomationSettingAction } from "../../../../types";

import {
  UpdateAppointmentStatusActionData,
  UpdateAppointmentStatusActionOptions
} from "../../../../actions/chat-actions";

const { PREVISIT, HIDE_APPOINTMENT_TIMES, SECURE_DIRECT_MESSAGE } = ChatFlowTags;

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

const ModalContent = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;

  & > * {
    margin-top: 1rem;
  }

  .ButtonWrapper {
    padding: 0;
    padding-top: 16px;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

const style = css`
  .ConfirmRescheduleGroup {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .ButtonWrapper {
    flex-grow: 1;
    min-width: calc(50% - 8px);
    width: 1fr;
    margin-left: 8px;
    margin-right: 8px;
  }

  .ButtonWrapper:first-of-type {
    margin-left: 0px;
  }

  .ButtonWrapper:last-of-type {
    margin-right: 0px;
  }

  .AppointmentBox {
    padding-bottom: 16px;
    border-bottom: solid 1px #f2f2f3;
  }

  .AppointmentBoxNoLine {
    padding-top: 6px;
    border-top: solid 1px #f2f2f3;
  }

  .Row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: start;
    margin-top: 8px;
  }

  .RowIcon {
    margin-right: 20px;
  }

  .AddToCalendarButton {
    margin-left: 32px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .MoreDetailsButton {
    margin-left: 38px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`;

// Appointments grouped by unique location
type AppointmentGroups = [Appointment][];
interface Props {
  chatInfo?: ChatInfo;
  token: string;
  updateAppointmentStatus: (
    token: string,
    data: UpdateAppointmentStatusActionData,
    options?: UpdateAppointmentStatusActionOptions
  ) => void;
}

const AppointmentSummaryCardDeprecated: React.FC<Props & StyledProps> = ({
  chatInfo,
  token,
  updateAppointmentStatus
}: Props) => {
  const {
    appointments,
    locations,
    chatFlowTags,
    date,
    time,
    patients,
    rescheduleLoading,
    confirmLoading,
    availableActions
  } = chatInfo as ChatInfo;

  const [rescheduleModalOpen, setRescheduleModalOpen] = useState<boolean>(false);
  const multiLocations = locations && locations.length > 1;
  const chatType = getChatType(chatFlowTags) || undefined;
  const isPrevisitChat = chatType === PREVISIT;
  const isSecureDirectChat = chatFlowTags.includes(SECURE_DIRECT_MESSAGE);
  const isAppointmentTimeHidden = chatFlowTags.includes(HIDE_APPOINTMENT_TIMES); // TODO: is this still used
  const firstAppointment = appointments ? appointments[0] : undefined;
  const isCancelled = firstAppointment && firstAppointment.cancelled;
  const isRescheduled =
    firstAppointment && firstAppointment.status === AppointmentStates.RESCHEDULE;
  const isConfirmed = firstAppointment && firstAppointment.status === AppointmentStates.CONFIRMED;
  const isOnHold = firstAppointment && firstAppointment.status === AppointmentStates.ON_HOLD;
  const rescheduleOnly =
    !availableActions.includes(AutomationSettingAction.CONFIRM) &&
    availableActions.includes(AutomationSettingAction.RESCHEDULE);
  const hasTime = !!time;
  const hasDate = !!date;

  const onClickRescheduleAppt = () => {
    if (token && firstAppointment && updateAppointmentStatus)
      updateAppointmentStatus(
        token,
        {
          id: firstAppointment.id,
          status: AppointmentStates.RESCHEDULE
        },
        {
          onSuccess: () => {
            setRescheduleModalOpen(false);
          }
        }
      );
  };

  const onClickConfirmAppt = async () => {
    if (token && firstAppointment && updateAppointmentStatus) {
      updateAppointmentStatus(token, {
        id: firstAppointment.id,
        status: AppointmentStates.CONFIRMED
      });
    }
  };

  const showAppointmentDateTime = Boolean(!isAppointmentTimeHidden && (hasDate || hasTime));

  const showRescheduleConfirm = Boolean(
    showAppointmentDateTime &&
      !isCancelled &&
      !isOnHold &&
      chatType &&
      chatType === ChatFlowTags.PREVISIT &&
      firstAppointment
  );

  const hideAppointmentSummary =
    isSecureDirectChat || (!showRescheduleConfirm && !showAppointmentDateTime);

  const findPatientForAppointment = (
    apptPatients: { id: number; firstName: string }[],
    patientId: number
  ) => {
    const patient = apptPatients.find((apptPatient) => apptPatient.id === patientId);
    if (patient) {
      return patient;
    }
    return null;
  };

  const findLocationDetailsForAppointment = (
    locations: {
      id: number;
      locationName: string | null;
      locationInformation: JsonContent | null;
      phoneNumber: string | null;
      address: string | null;
    }[],
    locationId: number
  ) => {
    const location = locations.find((apptLocation) => {
      return apptLocation.id === locationId;
    });
    if (location) {
      return { ...location, address: location.address?.replace(/,/gim, "").trim() || "" }; // remove commas
    }
    return null;
  };

  const convertUtcToTimezone = (date: Date, timezone: string) => moment(date).tz(timezone);

  if (hideAppointmentSummary) return null;

  const getButtonText = (buttonType: string) => {
    switch (buttonType) {
      case AutomationSettingAction.RESCHEDULE:
        if (isRescheduled) {
          return "Requested for reschedule/cancel";
        }
        if (multiLocations) {
          return "Request to reschedule/cancel all";
        }
        return "Request to reschedule/cancel";
      case AutomationSettingAction.CONFIRM:
        if (isConfirmed) {
          return "Confirmed";
        }
        if (multiLocations) {
          return "Confirm all appointments";
        }
        return "Confirm appointment";
      default:
        return "";
    }
  };

  const locationGroupedAppointments: AppointmentGroups = useMemo(() => {
    return appointments.reduce<AppointmentGroups>((appointmentGroups, appointment) => {
      const lastGroupedAppointment =
        appointmentGroups.length > 0 ? appointmentGroups[appointmentGroups.length - 1][0] : null;

      if (!lastGroupedAppointment) {
        appointmentGroups.push([appointment]);
      } else if (lastGroupedAppointment.locationId !== appointment.locationId) {
        appointmentGroups.push([appointment]);
      } else {
        appointmentGroups[appointmentGroups.length - 1].push(appointment);
      }
      return appointmentGroups;
    }, []);
  }, [appointments]);

  return (
    <StyledComponent css={style}>
      <Card p="16px 16px 4px" mb="24px" boxShadow>
        <>
          <Box width="100%" mb="16px">
            <Text variant="titleSmall" bold mb="8px">
              {multiLocations ? "Appointments" : "Appointment"}
            </Text>
            {isCancelled && (
              <Text variant="medium" error mb="8px" mt="-6px">
                Cancelled
              </Text>
            )}
            {isOnHold && (
              <Text variant="medium" error mb="8px" mt="-6px">
                Clinic placed on hold
              </Text>
            )}
          </Box>

          {locationGroupedAppointments.map((appts: Appointment[]) => {
            const firstAppointmentInGroup = appts[0];

            const patient =
              patients && patients.length > 0
                ? findPatientForAppointment(patients, firstAppointmentInGroup.patientId)
                : undefined;

            const location =
              locations && locations.length > 0
                ? findLocationDetailsForAppointment(locations, firstAppointmentInGroup.locationId)
                : undefined;

            const date = firstAppointmentInGroup.timezone
              ? convertUtcToTimezone(
                  firstAppointmentInGroup.start,
                  firstAppointmentInGroup.timezone
                ).format("ddd MMM D")
              : "";

            const time = firstAppointmentInGroup.timezone
              ? convertUtcToTimezone(
                  firstAppointmentInGroup.start,
                  firstAppointmentInGroup.timezone
                ).format("h:mma")
              : "";

            const startTimes = appts
              ? appts.map((appt) => moment.tz(appt.start, appt.timezone || "Canada/Mountain"))
              : [];
            const endTimes = appts
              ? appts.map((appt) => moment.tz(appt.end, appt.timezone || "Canada/Mountain"))
              : [];
            const minStartTime = moment.min(startTimes);
            const maxEndTime = moment.max(endTimes);

            const showAddToCalendar = Boolean(
              chatType &&
                !isRescheduled &&
                !isCancelled &&
                !isOnHold &&
                isPrevisitChat &&
                minStartTime &&
                maxEndTime &&
                maxEndTime.isValid() &&
                maxEndTime.isAfter(minStartTime) &&
                hasTime &&
                hasDate
            );

            return (
              <Box
                width="100%"
                mb={!showRescheduleConfirm ? "16px" : ""}
                key={firstAppointmentInGroup.id}
                className={!showRescheduleConfirm ? "AppointmentBoxNoLine" : "AppointmentBox"}
              >
                {patient?.firstName && (
                  <Text variant="medium" mb="8px" mt="-6px">
                    {patient?.firstName}
                  </Text>
                )}
                <Box>
                  <div className="Row">
                    <div className="RowIcon">
                      <Calendar />
                    </div>

                    {showAppointmentDateTime && (
                      <>
                        {hasTime && hasDate && <Text>{`${date} at ${time}`}</Text>}
                        {hasTime && !hasDate && <Text>{`${time}`}</Text>}
                        {!hasTime && hasDate && <Text>{`${date}`}</Text>}
                      </>
                    )}
                  </div>
                  {showAddToCalendar && (
                    <div className="AddToCalendarButton">
                      <AddToCalendarButton
                        title={`Appointment at ${location?.locationName}`}
                        description=""
                        location={location?.address}
                        startTime={minStartTime.toISOString()}
                        endTime={maxEndTime.toISOString()}
                      />
                    </div>
                  )}
                </Box>
                <Box>
                  {location?.locationName && (
                    <>
                      <div className="Row">
                        <div className="RowIcon">
                          <MapPin />
                        </div>
                        <Text>{location?.locationName}</Text>
                      </div>
                      {multiLocations && (
                        <div className="MoreDetailsButton">
                          <InfoModal showInfoModalButton={false} label="View important details">
                            <MoreDetailsCard
                              location={location}
                              chatFlowTags={chatInfo?.chatFlowTags || []}
                            />
                          </InfoModal>
                        </div>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            );
          })}
        </>

        {showRescheduleConfirm && (
          <div className={rescheduleOnly && !isRescheduled ? "" : "ConfirmRescheduleGroup"}>
            {!isRescheduled &&
              (availableActions.includes(AutomationSettingAction.CONFIRM) || isConfirmed) && (
                <Button
                  variant={isConfirmed ? "success" : "primary"}
                  disabled={!token || confirmLoading || isConfirmed}
                  loading={confirmLoading}
                  onClick={onClickConfirmAppt}
                >
                  {getButtonText(AutomationSettingAction.CONFIRM)}
                </Button>
              )}
            {availableActions.includes(AutomationSettingAction.RESCHEDULE) && !isRescheduled && (
              <Button
                variant="text"
                disabled={!token || rescheduleLoading || isRescheduled}
                onClick={() => setRescheduleModalOpen(true)}
              >
                {getButtonText(AutomationSettingAction.RESCHEDULE)}
              </Button>
            )}
            {isRescheduled && !isCancelled && (
              <div>
                <Text bold>You&apos;ve requested to reschedule/cancel</Text>
              </div>
            )}
          </div>
        )}
      </Card>

      <Modal
        showing={rescheduleModalOpen}
        setShowing={setRescheduleModalOpen}
        disableCloseButton={rescheduleLoading}
      >
        <ModalContent>
          <Text variant="titleSmall" bold marginTop={0}>
            Are you sure you want to reschedule or cancel your appointment?
          </Text>
          <Text variant="small">
            You can make a reschedule/cancel request. You will receive a text or email from the
            clinic with instructions on how to reschedule or cancel.
          </Text>
          <div className="ButtonWrapper">
            <Button
              variant="primary"
              disabled={rescheduleLoading}
              loading={rescheduleLoading}
              onClick={onClickRescheduleAppt}
            >
              {!rescheduleLoading && "Request to reschedule/cancel"}
            </Button>
            <Button
              variant="text"
              disabled={rescheduleLoading}
              onClick={() => setRescheduleModalOpen(false)}
            >
              No, I want to keep my appointment
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </StyledComponent>
  );
};

export default AppointmentSummaryCardDeprecated;
