/* filterProps
======================================= */

import isPropValid from "@emotion/is-prop-valid";

const filterProps = (prop: string) => {
  return (
    isPropValid(prop) &&
    prop !== "display" &&
    prop !== "overflow" &&
    prop !== "width" &&
    prop !== "fontWeight" &&
    prop !== "fontFamily" &&
    prop !== "name"
  );
};

export default filterProps;
