/* Date Input Card
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";
import TextCard from "./TextCard";
import { DateInput, Button, Icon, SkipButton } from "..";
import moment from "moment";
import { ChatCardTypes } from "../../../constants";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("form")<StyledProps>(compose(space, layout));

/* Props
--------------------------------------- */

interface DateInputProps {
  msg: Message;
  index: number;
  isCurrentMessage: boolean;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
  editMode?: boolean;
  save?: (userResponse: string) => Promise<void>;
}

interface TextInputState {
  userResponse: string;
}

/* Component
--------------------------------------- */

class DateInputCard extends React.Component<DateInputProps, TextInputState> {
  constructor(props: DateInputProps) {
    super(props);

    this.state = {
      userResponse: ""
    };
  }

  updateResponse = (userResponse: string, finishedInputLength: number) => {
    // react-mask-input allows an extra character to be input after the mask is full!

    if (userResponse.replace(/\D/g, "").length < finishedInputLength + 1) {
      userResponse = userResponse.substring(0, userResponse.length);
      this.setState({ userResponse });
    }
  };

  getDatePlaceholder = () => {
    const { msg } = this.props;
    // TODO: Make custom card PayloadOptions type
    const payloadOptions = msg?.payloadOptions as BasePayloadOptions;
    return payloadOptions?.format || "YYYY-MM-DD";
  };

  submit = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    const { userResponse } = this.state;
    const { fetchNextMessage, save } = this.props;

    if (save) {
      return save(userResponse);
    }

    return fetchNextMessage({
      lastReceivedMessageId: null,
      userResponse: userResponse.replace(/YMD/g, ""),
      userResponseType: ChatCardTypes.DATE_INPUT,
      chatFlowId: null,
      showUserResponse: true,
      createdAt: moment().toDate()
    });
  };

  render() {
    const { msg, index, isCurrentMessage, editMode } = this.props;
    const { userResponse } = this.state;
    const { payloadContent } = msg;

    // TODO: Make custom card PayloadOptions type
    const payloadOptions = msg?.payloadOptions as BasePayloadOptions;

    const skippable = payloadOptions?.skippable || false;
    const placeHolder: "YYYY-MM-DD" | "YYYY-MM" | "YYYY" | "DD-MM-YYYY" = this.getDatePlaceholder();
    const mask = placeHolder.replace(/[YMD]/g, "9");

    const dateName =
      placeHolder?.length === 10 // eslint-disable-line no-nested-ternary
        ? "date_full"
        : placeHolder?.length === 7
          ? "date_month"
          : "date_year";
    const finishedInputLength = placeHolder?.length === 10 ? 8 : placeHolder?.length === 7 ? 6 : 4; // eslint-disable-line no-nested-ternary

    return (
      <StyledComponent {...this.props}>
        <TextCard
          msg={msg}
          index={index}
          avatar={<Icon name="logo" iconColor="reset" size="18px" />}
          mb="16px"
          editMode={editMode}
        >
          {payloadContent}
        </TextCard>
        {isCurrentMessage && (
          <DateInput
            id={`dateInput-${index}`}
            placeholder={placeHolder}
            name={dateName}
            mask={mask}
            label="Enter a Date"
            mb="30px"
            updateResponse={(response) => this.updateResponse(response, finishedInputLength)}
          />
        )}
        {isCurrentMessage && (
          <Button
            id={`sendBtn-${index}`}
            type="submit"
            onClick={this.submit}
            disabled={!userResponse}
            disableOnClick
          >
            Send
          </Button>
        )}
        {isCurrentMessage && !editMode && skippable && <SkipButton index={index} message={msg} />}
      </StyledComponent>
    );
  }
}

export default DateInputCard;
