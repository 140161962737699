import styled from "@emotion/styled";
import React from "react";

type IconButtonProps = {
  icon: React.FC;
  onClick?: (...props: any) => void;
  href?: string;
  target?: "_blank";
};

const Link = styled.a`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

const IconButton: React.FC<IconButtonProps> = ({ href, target, icon: Icon, onClick }) => {
  return (
    <Link href={href} target={target} onClick={onClick}>
      <Icon />
    </Link>
  );
};

export default IconButton;
