/* Scroll
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

export const scrollbarStyles = css`
  overflow: hidden;
  overflow-y: scroll;
  max-height: 100vh;

  ::-webkit-scrollbar {
    width: 0px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

interface Props {
  id?: string;
  className?: string;
}

const Scroll: React.FC<Props & StyledProps> = (props) => {
  const style = css`
    flex-grow: 1;
    width: 100%;

    ${scrollbarStyles};
  `;

  return (
    <StyledComponent css={style} className={props.className} {...props}>
      {props.children}
    </StyledComponent>
  );
};

Scroll.displayName = "Scroll";

export default Scroll;
