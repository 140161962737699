/** @jsxImportSource @emotion/react */
import React, { useImperativeHandle } from "react";
import { useMask } from "@react-input/mask";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

import { Input } from "../../../ui";

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

interface Props {
  id?: string;
  name?: string;
  inputRef?: any; // React.RefObject<HTMLInputElement | HTMLTextAreaElement>
  updateResponse?: (userResponse: string) => void;
}

const PhoneInput: React.FC<Props & StyledProps> = (props) => {
  const { name = "", inputRef = null } = props;
  const maskRef = useMask({ mask: "(xxx) xxx-xxxx", replacement: { x: /\d/ }, showMask: true });
  useImperativeHandle(inputRef, () => maskRef.current);
  return (
    <StyledComponent {...props}>
      <Input
        inputRef={maskRef}
        name={name}
        updateResponse={props.updateResponse}
        maxRows={1}
        icon="phone"
        type="tel"
        label="Enter Phone Number"
      />
    </StyledComponent>
  );
};

PhoneInput.displayName = "PhoneInput";

export default PhoneInput;
