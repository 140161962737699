import React, { useState } from "react";

import { PatientConsentStatus } from "../../../../../types";

import { Text, Input, Button, Checkbox } from "../../../../ui";

import useWebSocketConnection from "../useWebSocketConnection";

import styles from "./consentForm.module.scss";

type ConsentFormProps = {
  disabled?: boolean;
};

const ConsentForm = ({ disabled }: ConsentFormProps) => {
  const {
    recording,
    connected = false,
    acceptPatientConsent,
    denyPatientConsent
  } = useWebSocketConnection();
  const [patientConsentStatus, setPatientConsentStatus] = useState<PatientConsentStatus | null>(
    recording?.patientConsentStatus || null
  );
  const [signature, setSignature] = useState("");

  const submitDisabled =
    disabled ||
    !connected ||
    !recording ||
    (patientConsentStatus &&
      patientConsentStatus !== PatientConsentStatus.ACCEPTED &&
      patientConsentStatus !== PatientConsentStatus.DENIED) ||
    (patientConsentStatus === PatientConsentStatus.ACCEPTED && !signature);

  const submitConsent = () => {
    if (patientConsentStatus === PatientConsentStatus.ACCEPTED && signature) {
      acceptPatientConsent({ patientSignature: signature });
    }
    if (patientConsentStatus === PatientConsentStatus.DENIED) {
      denyPatientConsent();
    }
  };

  if (!recording) return null;

  return (
    <div>
      <Text variant="medium" mb="16px">
        I confirm that I have read and understood the information provided, that it is satisfactory,
        and that I consent to using Mika during my appointment.
      </Text>
      <div className={styles.ConsentButtonGroup}>
        <Checkbox
          key={`accepted-${patientConsentStatus === PatientConsentStatus.ACCEPTED}`}
          isSelected={patientConsentStatus === PatientConsentStatus.ACCEPTED}
          onClick={() => setPatientConsentStatus(PatientConsentStatus.ACCEPTED)}
          label="Accept"
        />

        <Checkbox
          key={`denied-${patientConsentStatus === PatientConsentStatus.DENIED}`}
          isSelected={patientConsentStatus === PatientConsentStatus.DENIED}
          onClick={() => setPatientConsentStatus(PatientConsentStatus.DENIED)}
          label="Decline"
        />
      </div>
      {patientConsentStatus && patientConsentStatus === PatientConsentStatus.ACCEPTED && (
        <>
          <Text variant="meta" mb="8px">
            Signature
          </Text>
          <Input
            id="scribePatientConsent-signature"
            placeholder="Enter full name"
            name="signature"
            isOnCard
            maxRows={1}
            updateResponse={(signatureValue) => setSignature(signatureValue)}
            mb="16px"
          />
        </>
      )}
      <div className={styles.SubmitButtonGroup}>
        <Button type="button" onClick={submitConsent} disabled={!!submitDisabled}>
          {connected ? "Save" : "Reconnecting..."}
        </Button>
      </div>
    </div>
  );
};

export default ConsentForm;
