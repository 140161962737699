/* Upload
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

import { Icon, Text } from "../../../ui";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

/* Style
--------------------------------------- */

const style = (theme: any) => css`
  label {
    display: flex;
    justify-content: center;
    align-items: center;

    background: #fff;
    box-shadow: ${theme.shadows.small};
    border-radius: ${theme.radii.normal};
    padding: 6px;

    min-height: 75px;
    position: relative;

    cursor: pointer;

    input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
  }
`;

/* Props
--------------------------------------- */

interface Props {
  id?: string;
  inpuRef?: any;
  promptText?: string;
  disabled?: boolean;
  onChange: (event: React.FormEvent) => void;
}

/* Component
--------------------------------------- */

const UploadInput: React.FC<Props & StyledProps> = (props) => {
  const theme = useTheme();
  const { promptText, disabled } = props;

  return (
    <StyledComponent css={style(theme)} {...props}>
      <label>
        {!disabled && <Icon name="out" mr="8px" />}
        <Text textColor={disabled ? "error.text" : "primary.blue"}>
          {promptText || "Choose File"}
        </Text>
        <input
          type="file"
          accept="image/jpeg, image/png, application/pdf, capture=camera"
          disabled={disabled !== undefined ? disabled : false}
          onChange={(e) => props.onChange(e)}
        />
      </label>
    </StyledComponent>
  );
};

UploadInput.displayName = "UploadInput";

export default UploadInput;
