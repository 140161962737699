/* Flex
======================================= */

import React from "react";
import styled from "@emotion/styled";
import {
  compose,
  space,
  layout,
  flexbox,
  SpaceProps,
  LayoutProps,
  FlexboxProps
} from "styled-system";

type StyledProps = SpaceProps & LayoutProps & FlexboxProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout, flexbox));

interface Props {
  className?: string;
}

const Flex: React.FC<Props & StyledProps> = ({
  display = "flex",
  alignItems = "center",
  justifyContent = "center",
  ...otherProps
}) => {
  const props = { display, alignItems, justifyContent, ...otherProps };
  return <StyledComponent {...props}>{props.children}</StyledComponent>;
};

Flex.displayName = "Flex";

export default Flex;
