/* Map
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

import mapImage from "../../../assets/img/map.png";

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

const Map: React.FC<StyledProps> = (props) => {
  const style = css`
    height: 184px;
    width: 100%;
    background-size: cover;
    background-image: url("${mapImage}");
    background-position: center center;
  `;

  return <StyledComponent {...props} css={style} />;
};

Map.displayName = "Map";

export default Map;
