/* Grid
======================================= */

import React from "react";
import styled from "@emotion/styled";
import {
  compose,
  space,
  layout,
  flexbox,
  SpaceProps,
  LayoutProps,
  FlexboxProps
} from "styled-system";

type StyledProps = SpaceProps & LayoutProps & FlexboxProps;

const StyledContainer = styled("div")<StyledProps>(compose(space, layout, flexbox));

interface Props {
  className?: string;
}

const Grid: React.FC<Props & StyledProps> = ({
  display = "flex",
  flexWrap = "wrap",
  ...otherProps
}) => {
  const props = {
    display,
    flexWrap,
    ...otherProps
  };
  return <StyledContainer {...props}>{props.children}</StyledContainer>;
};

Grid.displayName = "Grid";

export default Grid;
