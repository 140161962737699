/* Select Button
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

import { Text, Icon } from "../../ui";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("button")<StyledProps>(compose(space, layout));

/* Style
--------------------------------------- */

const style = (theme: any) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #fff;
  box-shadow: ${theme.shadows.small};
  border-radius: ${theme.radii.normal};
  border: 2px solid #fff;

  padding: 16px 10px 16px 16px;
  height: 56px;
  width: 100%;

  .text-ui {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    text-align: left;
  }

  &:focus,
  &.isActive {
    outline: none;
    border: 2px solid ${theme.colors.primary.blue};
  }
`;

/* Props
--------------------------------------- */

interface Props {
  text: string;
  active?: boolean;
  onClick?: (event: React.MouseEvent) => void;
}

/* Component
--------------------------------------- */

const SelectButton: React.FC<Props & StyledProps> = (props: Props & StyledProps) => {
  const onClick = (e: React.MouseEvent) => {
    if (props.onClick) props.onClick(e);
  };

  const theme = useTheme();
  const { active = false, text, mb } = props;
  const isActive = active ? "isActive" : undefined;

  return (
    <StyledComponent css={style(theme)} className={isActive} onClick={(e) => onClick(e)} mb={mb}>
      <Text as="span" mr="10px">
        {text}
      </Text>
      <Icon name="chevronRight" />
    </StyledComponent>
  );
};

SelectButton.displayName = "SelectButton";

export default SelectButton;
