/* eslint-disable react/destructuring-assignment */
import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import jwtDecode from "jwt-decode";
import { withTheme } from "@emotion/react";
import { AnimatePresence } from "framer-motion";

import { ChatFlowTags, NotificationTypes } from "../../constants";

import { DeprecatedChatSessionManagerContext } from "../../components/providers/DeprecatedChatSessionManager";
import Messages from "./Messages";
import ChatWrapper from "./ChatWrapper";
import Header from "./Header";
import { NotificationOverlay, LandingCardDeprecated } from "../../components/ui";
import { NotificationType } from "../../types/notifications";

import {
  fetchChatInfo,
  fetchChatMessageInfo,
  showNotificationOverlay,
  fetchNextMessage as fetchNextMessageAction,
  undoUserResponse as undoUserResponseAction,
  UndoUserResponseData
} from "../../actions";

interface MatchParams {
  token?: string;
}

interface ChatContainerProps extends RouteComponentProps<MatchParams> {
  fetchChatInfo(token: string, conversationId: number): void;
  fetchNextMessageAction: (params: FetchNextMessageParams) => void;
  undoUserResponse: (data: UndoUserResponseData) => void;
  showNotificationOverlay: (type: NotificationType) => void;
  chatInfo: ChatInfo;
  chatMessages: Message[];
  chatMessageLoading: boolean;
  conversationId: number;
  theme: any;
}

const canUndo = (msg: Message, messages: Message[]) => {
  const userMessages = messages.filter((msg) => msg.sender !== "mikata");
  return userMessages[userMessages.length - 1]
    ? userMessages[userMessages.length - 1].messageTemplateId === msg.messageTemplateId
    : false;
};

const DeprecatedChatContainer = (props: ChatContainerProps) => {
  const { sessionToken } = useContext(DeprecatedChatSessionManagerContext);

  useEffect(() => {
    if (sessionToken) {
      const decodedToken: DecodedToken = jwtDecode(sessionToken);

      const numericDate: number = +new Date() / 1000;
      if (decodedToken.exp - numericDate < 0) {
        props.showNotificationOverlay(NotificationTypes.SESSION_EXPIRED);
        return;
      }

      props.fetchChatInfo(sessionToken, decodedToken.conversationId);
    }
  }, [sessionToken]);

  // could perhaps remove this and just use endSession, but for now this remains as it handles the full page refresh
  const endChat = () => {
    const { chatInfo, theme } = props;
    const { chatFlowTags, orgToken } = chatInfo;
    if (
      chatFlowTags.includes(ChatFlowTags.PREVISIT) ||
      chatFlowTags.includes(ChatFlowTags.SECURE_DIRECT_MESSAGE)
    ) {
      window.location.reload();
    } else if (chatFlowTags.includes(ChatFlowTags.APPOINTMENT_REQUEST)) {
      const domain = window.location.origin.replace("chat.", "app.").replace("3000", "3002");
      const url = `${domain}/book-appointment?token=${orgToken}&themeName=${theme.themeName}`;
      window.location.href = url;
    }
  };

  const fetchNextMessage = (params: FetchNextMessageParams) => {
    if (props.fetchNextMessageAction) {
      props.fetchNextMessageAction(params);
    }
  };

  const undoLastAnswer = async (messageTemplateId: number) => {
    const { undoUserResponse } = props;

    return undoUserResponse({ messageTemplateId });
  };

  const renderLanding = () => {
    return <LandingCardDeprecated key="landingPage" token={sessionToken} />;
  };

  const renderMessages = () => {
    const { chatInfo, chatMessages, chatMessageLoading, conversationId } = props;
    return (
      <ChatWrapper header={<Header chatInfo={chatInfo} endChat={endChat} />}>
        <Messages
          key="messagesPage"
          messages={chatMessages}
          token={sessionToken}
          fetchNextMessage={fetchNextMessage}
          undoLastAnswer={undoLastAnswer}
          canUndo={canUndo}
          fetchingMessage={chatMessageLoading}
          chatInfo={chatInfo}
          conversationId={conversationId}
        />
      </ChatWrapper>
    );
  };

  const { chatMessages, chatMessageLoading } = props;
  const showMessages = chatMessageLoading || chatMessages?.length > 0;

  return (
    <>
      <AnimatePresence>{showMessages ? renderMessages() : renderLanding()}</AnimatePresence>
      <NotificationOverlay />
    </>
  );
};

function mapStateToProps(state: ReduxState) {
  return {
    chatInfo: state.chatInfo,
    chatMessageInfo: state.chatMessageInfo,
    checklistInfo: state.checklistInfo,
    chatMessages: state.chatMessages.messages,
    chatMessageLoading: state.chatMessages.loading,
    conversationId: state.chatMessages.conversationId
  };
}

export default withTheme(
  connect(mapStateToProps, {
    fetchChatInfo,
    fetchChatMessageInfo,
    showNotificationOverlay,
    fetchNextMessageAction,
    undoUserResponse: undoUserResponseAction
  })(DeprecatedChatContainer)
);
