/* Field Error
======================================= */

/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

import { Icon, Text } from "../..";

/* Styled Component
--------------------------------------- */

type StyledProps = SpaceProps & LayoutProps;

const StyledComponent = styled("div")<StyledProps>(compose(space, layout));

/* Style
--------------------------------------- */

const style = (theme: any) => css`
  display: flex;
  padding: 0 10px;
`;

/* Component
--------------------------------------- */

const FieldError: React.FC<StyledProps> = (props) => {
  const theme = useTheme();

  return (
    <StyledComponent css={style(theme)} {...props}>
      <Icon name="error" size="18px" iconColor="error.text" mr="8px" />
      <Text variant="small" textColor="error.text">
        {props.children}
      </Text>
    </StyledComponent>
  );
};

FieldError.displayName = "FieldError";

export default FieldError;
