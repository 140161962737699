/* eslint-disable no-console */
import React, { useRef, useCallback, useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { io, Socket } from "socket.io-client";

import { PatientConsentStatus, RecordingState } from "../../types";

export enum SocketMessageType {
  endSession = "endSession",
  invalidRequest = "invalid-request",
  recordingState = "recording-state",
  updatePatientConsentStatus = "update-patient-consent-status"
}

export type MessagePayload = {
  patientConsentStatus?: PatientConsentStatus;
  patientSignature?: string;
};

type WebSocketContextType = {
  connected?: boolean;
  failedConnectionAttempts: number;
  isAuthenticated: boolean;
  recording: RecordingState | null;
  sendMessage: (messageType: SocketMessageType, messagePayload?: MessagePayload) => void;
};

type PropsType = {
  firstAppointmentId?: number;
  children: React.ReactNode;
};

export const initialWebSocketContext: WebSocketContextType = {
  connected: false,
  isAuthenticated: false,
  failedConnectionAttempts: 0,
  recording: null,
  sendMessage: () => console.error("WebSocket not initialized")
};

export const WebSocketContext = React.createContext(initialWebSocketContext);

const RECORDING_WEBSOCKET_URL = process.env.REACT_APP_RECORDING_WEBSOCKET_URL;

const WebSocketProvider = ({ firstAppointmentId, children }: PropsType) => {
  const sessionToken = sessionStorage.getItem("token");
  const appointmentId = firstAppointmentId || null;

  const socket = useRef<Socket | null>(null);
  const [recording, setRecording] = useState<RecordingState | null>(null);
  const [lastConnectionTime, setLastConnectionTime] = useState<number | null>(null);
  const [failedConnectionAttempts, setFailedConnectionAttempts] = React.useState<number>(0);
  const isAuthenticated = Boolean(sessionToken);

  const isConnected = useMemo(() => {
    return Boolean(socket.current?.connected);
  }, [lastConnectionTime, socket.current?.connected]);

  const sendMessage = useCallback(
    (messageType: SocketMessageType, messagePayload?: MessagePayload) => {
      if (socket?.current?.connected && appointmentId) {
        const payload = { ...(messagePayload || {}), appointmentId };
        const token = sessionStorage.getItem("token");
        socket.current.auth = { token };
        socket.current.emit(messageType, payload);
      }
    },
    [socket.current?.connected, appointmentId]
  );

  const registerPatientSocketListeners = (socketConnection: Socket) => {
    console.log("REGISTERING PATIENT LISTENERS");

    socketConnection.on("connect", () => {
      if (appointmentId) {
        sendMessage(SocketMessageType.recordingState);
        setLastConnectionTime(Date.now());
      }
    });

    socketConnection.on(SocketMessageType.recordingState, (recordingState: RecordingState) => {
      setRecording(recordingState);
    });
  };

  // Register new socket listeners
  useEffect(() => {
    if (sessionToken) {
      console.log("Establish patient connection");

      socket.current = io(`${RECORDING_WEBSOCKET_URL}/scribePatient`, {
        auth: {
          token: sessionToken
        },
        transports: ["websocket"]
      });
      // setLoginError(undefined);
      registerPatientSocketListeners(socket.current);
    }

    // Close connection when unmounting
    return () => {
      if (socket.current) {
        // close socket, even if not connected
        console.log("disconnecting connection with socket.id now: ", socket.current.id);
        socket.current.disconnect();
      }
    };
  }, [sessionToken, isAuthenticated]);

  // sync recording state periodically
  useEffect(() => {
    const sessionStatusInterval = setInterval(() => {
      if (isAuthenticated) {
        console.log("sessionStatusInterval");
        sendMessage(SocketMessageType.recordingState);
      }
    }, 10 * 1000);

    return () => {
      clearInterval(sessionStatusInterval);
    };
  }, [isAuthenticated]);

  //  Track failed connection attempts
  useEffect(() => {
    socket.current?.io.on("reconnect", () => {
      setFailedConnectionAttempts(0);
    });
    socket.current?.io.on("open", () => {
      setFailedConnectionAttempts(0);
    });

    socket.current?.io.on("reconnect_error", () => {
      setFailedConnectionAttempts((count) => count + 1);
    });
  }, [socket.current]);

  const value = {
    connected: isConnected,
    failedConnectionAttempts,
    isAuthenticated,
    sendMessage,
    recording
  };

  return <WebSocketContext.Provider value={value}>{children}</WebSocketContext.Provider>;
};

const mapStateToProps = ({ chatInfo }: ReduxState) => {
  return {
    firstAppointmentId: chatInfo?.appointments?.[0]?.id
  };
};

export default connect(mapStateToProps, null)(WebSocketProvider);
