/* ChatLoader
======================================= */

import React from "react";
import { Avatar, Loader, Icon } from "..";

import styles from "./index.module.scss";

const ChatLoader = () => {
  return (
    <div className={styles.Loader}>
      <Avatar>
        <Icon name="logo" iconColor="reset" size="18px" />
      </Avatar>
      <Loader size={32} />
    </div>
  );
};

export default ChatLoader;
