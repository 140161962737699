/* eslint-disable react/jsx-props-no-spreading */
/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { motion } from "framer-motion";
import { Scroll } from "../../components/layout";

type Props = {
  header?: React.ReactNode;
};

const style = css`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;

  .scroll {
    max-height: calc(100vh - 72px);
  }
`;

const animation = {
  initial: { y: "100%" },
  animate: { y: 0 },
  exit: { y: "100%" },
  transition: { type: "spring", stiffness: 200, damping: 25 }
};

const ChatWrapper: React.FC<Props> = ({ children, header }) => {
  return (
    <motion.div {...animation} css={style}>
      {header}
      <Scroll id="scrollContainer" className="scroll">
        {children}
      </Scroll>
    </motion.div>
  );
};

export default ChatWrapper;
