/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

import Node, { JsonContentPlaceholderContext } from "../../Node";
import { InfoModal } from "../../../ui";
import { InfoModalNode, JsonContentNodeTypes, JsonContentNode } from "../../../../types";

type PropsType = {
  node: InfoModalNode;
  typePath: JsonContentNodeTypes[];
  placeholderContext: JsonContentPlaceholderContext;
  showInfoModalButton?: boolean;
};

const InfoModalNodeWrapper = ({
  node,
  typePath,
  placeholderContext,
  showInfoModalButton = true
}: PropsType) => {
  const childTypePath: JsonContentNodeTypes[] = [...typePath, node.type];

  return (
    <InfoModal label={node.properties.label} showInfoModalButton={showInfoModalButton}>
      {node.children &&
        node.children.map((childNode: JsonContentNode, idx: number) => {
          return (
            <Node
              key={childNode.id}
              index={idx}
              node={childNode}
              typePath={childTypePath}
              placeholderContext={placeholderContext}
            />
          );
        })}
    </InfoModal>
  );
};

export default InfoModalNodeWrapper;
